// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, setDoc, doc, query, where, getDoc } from 'firebase/firestore/lite';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from "firebase/auth";

import { getAuth, onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC4ydfXab7TbfqS8JqJ2MhB5y-_yX2CSgY",
    authDomain: "laundrolift-67de5.firebaseapp.com",
    projectId: "laundrolift-67de5",
    storageBucket: "laundrolift-67de5.appspot.com",
    messagingSenderId: "898194854892",
    appId: "1:898194854892:web:71bcc9f1e3145f0bffa6c9",
    measurementId: "G-TY58402S4S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);

const createUser = async (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed up 
            const user = userCredential.user;
            return { status: true, data: user, msg: "" };
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            return { status: false, data: null, msg: errorMessage };
            // ..
        });
}

const login = async (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            return { status: true, data: user, msg: "" };
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            return { status: false, data: null, msg: errorMessage };
        });
}

const signout = async () => {
    return signOut(auth).then(() => {
        // Sign-out successful.
        return { status: true, data: null, msg: "" };
    }).catch((error) => {
        return { status: false, data: null, msg: "Unable to logout" };
        // An error happened.
    });
}

const resetPwd = async (email) => {
    return sendPasswordResetEmail(auth, email)
        .then(() => {
            // Password reset email sent!
            // ..
            return { status: true, data: null, msg: "" };
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            return { status: false, data: null, msg: errorMessage };
            // ..
        });
}

const addOrUpdateDoc = async (col, docID, data) => {
    const cityRef = doc(db, col, docID);
    return setDoc(cityRef, data, { merge: true })
        .then(() => {
            console.log("Successful");
            return { status: true, data: null, msg: "Success!" };
        })
        .catch((error) => {
            console.log(`Unsuccessful returned error ${error}`);

            return { status: false, data: null, msg: "Unable to complete your request. Please try again!" };
        });
}

const getAllDocuments = async (col) => {
    const querySnapshot = await getDocs(collection(db, col));
    const data = []
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        var d = doc.data();
        d.id = doc.id;
        data.push(d)
    });

    return { status: true, data: data, msg: "Success!" };
}

const getDocument = async (col, docID) => {
    const docRef = doc(db, col, docID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        return { status: true, data: docSnap.data(), msg: "Success!" };
    } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
        return { status: false, data: null, msg: "Unable to complete your request. Please try again!" };
    }
}

const getDocumentWhere = async (col, filterKey, filterValue) => {
    const q = query(collection(db, col), where(filterKey, "==", filterValue));
    const data = []
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        var d = doc.data();
        d.id = doc.id;
        data.push(d)
    });

    return { status: true, data: data, msg: "Success!" };

}


export {
    db,
    auth,
    createUser,
    login,
    signout,
    resetPwd,
    onAuthStateChanged,
    addOrUpdateDoc,
    getDocument,
    getDocumentWhere,
    getAllDocuments
};