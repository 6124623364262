import React, { Component } from 'react'
import Menu from './Menu';
import Mobile_menu from './Mobile_menu';
import Logo from './Logo'
import Header_search from './Header_search'


    export class Header3 extends Component {
    
        componentDidMount() {
            window.addEventListener('scroll', this.isSticky);
        }
    
        componentWillUnmount() {
            window.removeEventListener('scroll', this.isSticky);
        }
    
        isSticky = (e)=>{
            const header = document.querySelector('header');
            const scrollTop = window.scrollY;
            scrollTop >= 250 ? header.classList.add('is-Sticky') : header.classList.remove('is-Sticky');
        };
    
    render() {
        return (
            // <header id="masthead" className="header ttm-header-style-03">
            //     {/* site-header-menu */}
            //     <div id="site-header-menu" className="site-header-menu ttm-wrap-cell">
            //         <div className="site-header-menu-inner ttm-stickable-header">
            //             <div className="container-fluid">
            //                 <div className="row">
            //                     <div className="col-lg-12">
            //                         {/* site-navigation */}
            //                         <div className="site-navigation d-flex flex-row align-items-center justify-content-between">
            //                             {/* site-branding */}
            //                             <div className="site-branding">
            //                                 <a className="home-link" href={'/'} title="Laundrolift" rel="home">
            //                                     <img width="185" height="50" id="logo-img" className="img-fluid" alt="logo" src="images/logo-img-white.svg" />
            //                                 </a>
            //                             </div>
            //                             {/* site-branding end */}
            //                             <div className="border-box-block">
            //                                 <div className=" d-flex align-items-center">
            //                                     {/* menu */}
            //                                     <Menu/>
            //                                     <div className="mobile-menu"><Mobile_menu/></div>
            //                                     {/* menu end */}
            //                                     <div className="ttm-widget_header d-flex flex-row">
            //                                         <div className="widget_info d-flex flex-row align-items-center justify-content-end">
            //                                         </div>
            //                                         <div className="header_btn">
            //                                             <a className="ttm-btn ttm-btn-style-border text-theme-WhiteColor ml-30"> Contact Us </a>
            //                                         </div>
            //                                     </div>
            //                                 </div>
            //                             </div>
            //                         </div>{/* site-navigation end */}
            //                     </div>
            //                 </div>
            //             </div>
            //         </div>
            //     </div>
            //     {/* site-header-menu end */}
            // </header> 

            <header id="masthead" className="header ttm-header-style-02">
                <div id="site-header-menu" className="site-header-menu bg-theme-DarkColor">
                    <div className="site-header-menu-inner ttm-stickable-header">
                        <div className="container-fliud">
                            <div className="row">
                                <div className="col-lg-12 ">
                                    <div className="site-navigation d-flex flex-row  justify-content-between align-items-center">
                                        <div className="site-branding">
                                            <a className="home-link" href={'/'} title="Laundrolift" rel="home">
                                                <img width="185" height="50" id="logo-img" className="img-fluid" alt="logo" src="images/logo-light-1.svg" />
                                            </a>
                                        </div>
                                        <div className=" d-flex flex-row">
                                            <div className="btn-show-menu-mobile menubar menubar--squeeze">
                                                <span className="menubar-box">
                                                    <span className="menubar-inner"></span>
                                                </span>
                                            </div>
                                            <Menu/>
                                            <div className="mobile-menu"><Mobile_menu/></div>
                                                <div className="header_extra d-flex flex-row align-items-center justify-content-end">
                                                <Header_search/>
                                                <div className="widget_info d-flex flex-row align-items-center justify-content-end">
                                                    <div className="widget_icon text-base-white">
                                                        <i className="flaticon-phone-call"></i>
                                                    </div>
                                                    <div className="widget_content">
                                                        <p><a href="tel:9085296733">+0 123 888 555</a></p>
                                                        <h3><a href="mailto:info@laundrolift.com">info@laundrolift.com</a></h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            
        )
    }
}

export default Header3;