import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Slider from 'react-slick';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import ProgressBar from 'react-animated-progress-bar';
import CountUp from 'react-countup';


export class Case_study_details extends Component {

    constructor() {
        super();
        this.state = {
          name: "React"
        };
        this.onChangeValue = this.onChangeValue.bind(this);

    }
    
    onChangeValue(event) {
        console.log(event.target.value);
    }

    render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [{
      
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        var slick_slider_ltr = {
            infinite: true,
            arrows: false,
            dots: false,                   
            autoplay: true,
            centerMode : false,
            autoplaySpeed: 0,
            speed: 9000,
            cssEase: "linear",
            pauseOnHover: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            }
    
            var slick_slider_rtl = {
            infinite: true,
            arrows: false,
            dots: false,                   
            autoplay: true,
            centerMode : false,
            rtl: true,
            autoplaySpeed: 0,
            speed: 9000,
            cssEase: "linear",
            pauseOnHover: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            };
        return (

            <div className="site-main">
                <Header/>
            
                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Steam iron</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;/ Services</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Steam iron</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>

                <section className="ttm-row project-single-section clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-8 ttm-sticky-column">
                                {/* ttm_single_image-wrapper */}
                                <div className="ttm_single_image-wrapper mb-35">
                                    <img className="border-radius-8" src="./images/portfolio/portfolio-2-1200x800.jpg" />
                                </div>{/* ttm_single_image-wrapper end */}
                                <div className="row">
                                    <div className="col-lg-3">
                                        <h2 className="fs-24 fw-700">The challenge of project</h2>
                                    </div>
                                    <div className="col-lg-9">
                                        <p>Our exclusive cleanup method removes dirt, smoke, pollen, odors and soil, nonetheless assures no shrinkage, no damage,
                                        or any loss of filler. We tend to clean any sort of window covering material, in any configuration, right wherever they
                                        suspend. Our specialists can handle your draperies with the very best level of skilled care.</p>
                                        <div className="featured-icon-box icon-align-before-content style8 pt-20">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-sm ttm-icon_element-color-darkcolor">
                                                    <i className="flaticon-read"></i>
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3>Clean without taking down?</h3>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>We can clean every type of drapery in your home, no damage on drapery.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="featured-icon-box icon-align-before-content style8">
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-sm ttm-icon_element-color-darkcolor">
                                                    <i className="flaticon-read"></i>
                                                </div>
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3>What type of drapery do you clean?</h3>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>We clean every type of drapery , safely clean in your home or in shop!</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <h2 className="fs-24 fw-700">The result of project</h2>
                                    </div>
                                    <div className="col-lg-9">
                                        <p>It’s tough to wash material and keep them trying new and wrinkle free. Over the years, your drapes tend to
                                        accumulate loads of mud, pet hair, and different unwanted matter. Bring them to our store and your drapes can 
                                        look new in no time! We offer material services for any and each reasonably material with comforters, quilts,
                                        leather.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="ttm-pf-single-detail-box mb-35 mb-lg-0 mt-lg-30">
                                    <div className="ttm-pf-single-title">
                                    <h2>Case information</h2>
                                    </div>
                                    <ul className="ttm-pf-detailbox-list">
                                        <li>
                                            <span>Clients : </span> Devid smith
                                        </li>
                                        <li>
                                            <span>Category : </span> Cleaning, Washing
                                        </li>
                                        <li>
                                            <span>Date : </span> May 20, 2019
                                        </li>
                                        </ul>
                                        <div className="ttm-social-links-wrapper mt-20">
                                            <ul className="social-icons">
                                                <li className="social-facebook">
                                                    <a target="_blank" href="https://www.facebook.com/preyantechnosys19"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                                </li>
                                                <li className="social-twitter">  
                                                    <a target="_blank" href="https://twitter.com/PreyanTechnosys"><i className="fab fa-twitter" aria-hidden="true"></i></a>
                                                </li>
                                                <li className="social-pinterest">
                                                    <a target="_blank" href="https://dribbble.com/PreyanTechnosys"><i className="fab fa-dribbble" aria-hidden="true"></i></a>
                                                </li>
                                                <li className="social-instagram">
                                                    <a target="_blank" href="https://www.instagram.com/preyan_technosys/"><i className="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            <Footer/>
                        
            </div>
          )
      }
   }

export default Case_study_details;