import React, { Component } from 'react';
import Slider from 'react-slick';
import ProgressBar from 'react-animated-progress-bar';
import Header from '../components/layout/Header';
import Header2 from '../components/layout/Header2';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import Video from '../components/layout/Video';


export class About_us extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    render() {
      var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [ {

            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },{
    
          breakpoint: 778,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 575,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }]
      };
        
        return (       
          <div className="site-main">

            <Header2/>
            
            <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h2 className="title">About Us 2</h2>
                            </div>{/* /.page-title-captions */}
                            <div className="breadcrumb-wrapper">
                            <span>
                                <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                            <span>About Us 02</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                </div>{/* /.container */}                      
            </div>

            <section className="ttm-row clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6" data-aos="fade-right" data-aos-duration="2000">
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>What we’re offering</h3>
                                    <h2 className="title">You want it clean we dry <span className="webkit-content">[Clean]</span></h2>
                                </div>
                            </div>
                            <div className="ttm-fid-view-righticon style4">
                                <div className="ttm-fid-icon-wrapper">
                                    <i className="flaticon-washing-clothes"></i>
                                </div>
                                <div className="verical-content">
                                    <h3>Founded in 1995</h3>
                                </div>
                                <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                    <h4 className="ttm-fid-inner">
                                        <CountUp start={0} end={25} duration={5} />
                                    </h4>
                                    <h3 className="ttm-fid-title">Years of cleaning experience</h3>
                                </div>
                            </div>
                            <ul className="ttm-list style2">
                                <li>
                                    <i className="flaticon-read"></i>
                                    <span className="ttm-list-li-content">100% Guaranteed</span>
                                </li>
                                <li>
                                    <i className="flaticon-read"></i>
                                    <span className="ttm-list-li-content">We protect our entire</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-6" data-aos="fade-left" data-aos-duration="2000">
                            <div className="ttm-single-img-1 pl-50 pl-lg-0">
                                <div className="ttm_single_image-wrapper">
                                    <img src="./images/single-img-523x443.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row padding_zero-section clearfix">
                <div className="container-fluid">
                    <div className="row g-0">
                        <div className="col-xl-3">
                            <div className="col-bg-img-one ttm-col-bgimage-yes ttm-bg ttm-left-span bg-theme-SkinColor z-index-0">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer spacing-3"  style={{ backgroundImage: 'url("images/bg-image/col-bgimage-2.png")' }}></div>
                                <div className="layer-content">
                                    <div className="section-title style4">
                                        <div className="title-header">
                                            <h3>100%</h3>
                                            <h2 className="title">Satisfaction guarantee</h2>
                                        </div>
                                        <a href={'/About_us'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white mt-20"> Explore offers </a>
                                    </div>
                                </div>
                            </div>
                            <img src="images/bg-image/col-bgimage-2.png" className="ttm-equal-height-image" alt="bg-image" />
                        </div>
                        <div className="col-xl-4">
                            <div className="col-bg-img-one ttm-bg ttm-col-bgimage-yes z-index-0">
                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer spacing-3"  style={{ backgroundImage: 'url("images/bg-image/col-bgimage-1.png")' }}></div>
                                <div className="layer-content">
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                        <div className="ttm-tabs ttm-tab-style-02 bg-theme-GreyColor pt-100 pb-100 pl-50 pr-50">
                            <Tabs>
                                <TabList className="tabs">
                                    <Tab className="tab">
                                        <a>
                                        <i className="flaticon flaticon-research"></i>
                                            <h3>Request an estimate</h3>
                                        </a>
                                    </Tab>
                                </TabList> 
                                <div className="content-tab">                                
                                    <TabPanel>
                                        <form id="login_form" className="login_form wrap-form">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <label>
                                                        <input type="text" id="txtname" placeholder="Your Name" />
                                                    </label>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>
                                                        <input type="email" id="txtemail" placeholder="Email Address" />
                                                    </label>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>
                                                        <input id="password" placeholder="Phone Number"/>
                                                    </label>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>
                                                        <input id="cpassword" placeholder="Zip Code *"/>
                                                    </label>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>
                                                        <select className="custom-select-box h-100">
                                                            <option>Services</option>   
                                                            <option>Laundry Services</option>
                                                            <option>Leather Cleaning</option>
                                                            <option>Wash and Fold</option>
                                                        </select>
                                                    </label>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>
                                                    <textarea name="message" rows="4" placeholder="YourMessage" required=""></textarea>
                                                    </label>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="text-left">
                                                        <button type="submit" id="submit" className="mt-10 submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" value>
                                                            Submit Quote
                                                        </button>
                                                        <div className="text-form">
                                                            <div className="query-text">Been here before? 
                                                                <u>
                                                                    <a href="#" className="fw-700"> Check your query</a>
                                                                </u>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </TabPanel>
                                    <TabPanel>
                                        <form id="login_form" className="login_form wrap-form">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <label>
                                                        <i className="ti ti-user"></i>
                                                        <input type="text" id="txtname" placeholder="Username" />
                                                    </label>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>
                                                        <i className="ti ti-email"></i>
                                                        <input type="email" id="txtemail" placeholder="Email Address" />
                                                    </label>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>
                                                        <i className="ti ti-lock"></i>
                                                        <input type="password" id="password" placeholder="Password"/>
                                                    </label>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>
                                                        <i className="ti ti-lock"></i>
                                                        <input type="password" id="cpassword" placeholder="Confirm Password *"/>
                                                    </label>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>
                                                        <i className="ti ti-mobile"></i>
                                                        <input type="tel" id="txtphone" placeholder="Phone Number"/>
                                                    </label>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>
                                                        <div className="d-md-flex justify-content-between">
                                                            <div className="cookies-checkbox mt-5">
                                                                <div className="d-flex flex-row align-items-center justify-content-start">
                                                                    <input className="w-auto mr-10" id="cookies-consent" name="cookies-consent" type="checkbox" value="yes" />
                                                                    <span>you accept our Terms and Conditions and Privacy Policy</span>
                                                                </div>
                                                            </div>
                                                            <div className="mt-15">
                                                                <p>Already have an account?</p>
                                                            </div>
                                                        </div>
                                                    </label>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>
                                                        <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                                        type="submit">Sign up</button>
                                                    </label>
                                                </div>
                                            </div>
                                        </form>
                                    </TabPanel>
                                </div>       
                            </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row faq-section-2 position-relative z-index-0 clearfix" data-aos="zoom-in-up" data-aos-duration="2000">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-12">
                            <div className="row border-radius-8">
                                <div className="col-lg-4">
                                    <div className="ttm-fid inside style5">
                                        <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                            <h4 className="ttm-fid-inner">
                                                <CountUp start={0} end={12} duration={5} />
                                                <span>k</span>
                                            </h4>
                                            <h3 className="ttm-fid-title">Dry cleaning clothes in last 6 years</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="ttm-fid inside style5">
                                        <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                            <h4 className="ttm-fid-inner">
                                                <CountUp start={0} end={9} duration={5} />
                                                <span>k</span>
                                            </h4>
                                            <h3 className="ttm-fid-title">Happy customers who trusted us</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="ttm-fid inside style5">
                                        <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                            <h4 className="ttm-fid-inner">
                                                <CountUp start={0} end={260} duration={5} />
                                                <span>+</span>
                                            </h4>
                                            <h3 className="ttm-fid-title">Team member in our team</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row progress-section bg-theme-GreyColor clearfix" data-aos="fade-up" data-aos-duration="1000">
              <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="section-title">
                            <div className="title-header">
                                <h3>What We’re Offering</h3>
                                <h2 className="title">How we <span className="webkit-content">[Work]</span></h2>
                            </div>
                        </div>
                        <div className="ttm-progress-bar style1">
                            <h3 className="progressbar-title">Dry cleaning</h3>
                            <ProgressBar  rect  percentage="88" />
                        </div> 
                        <div className="ttm-progress-bar style1">
                            <h3 className="progressbar-title">Wet cleaning</h3>
                            <ProgressBar  rect  percentage="72" />
                        </div> 
                        <div className="ttm-progress-bar style1">
                            <h3 className="progressbar-title">Wash and fold</h3>
                            <ProgressBar  rect  percentage="80" />
                        </div>  
                    </div>
                    <div className="col-lg-6">
                    <div className="ttm-tabs ttm-tab-style-01 mt-0 pl-50 pl-lg-0">
                            <Tabs>
                                <TabList className="tabs">
                                    <Tab className="tab"><a className="tab-1 mb-0" tabIndex="0">Our mission</a></Tab>
                                    <Tab className="tab"><a className="tab-2 mb-0" tabIndex="0">Our work</a></Tab>
                                </TabList> 
                                <div className="content-tab">                                
                                    <TabPanel>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="pt-15">
                                                <p>Our business is cleaning your clothes. As such, it is
                                                imperative that you work diligently to keep your 
                                                facilities and equipment clean and the basis of our good
                                                relationship with our customers.</p>
                                                <ul className="ttm-list style2">
                                                    <li>
                                                        <i className="flaticon-read"></i>
                                                        <span className="ttm-list-li-content">Can I know about specific requests?</span>
                                                        <p>If there are any special care instructions not included?</p>
                                                    </li>
                                                    <li>
                                                        <i className="flaticon-read"></i>
                                                        <span className="ttm-list-li-content">Do I need to count the items in my order?</span>
                                                        <p>Absolutely not, counting is part of the service. Simply handles.</p>
                                                    </li>
                                                    <li>
                                                        <i className="flaticon-read"></i>
                                                        <span className="ttm-list-li-content">How fast will you process my order?</span>
                                                        <p>Processing time is typically 48-72 hours, excluding Sundays</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    </TabPanel>
                                    <TabPanel>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="pt-15">
                                                <p>We wash just about anything. From linen to bath mats to all types of clothing. We want you to know
                                                   that you can rely on us for a complete laundry service including ironing and dry cleaning.</p>
                                                <ul className="ttm-list style2">
                                                    <li>
                                                        <i className="flaticon-read"></i>
                                                        <span className="ttm-list-li-content">What are working hours of operation?</span>
                                                        <p>Our pick-up and drop-off time slots are 10am-3pm, 7pm-11pm</p>
                                                    </li>
                                                    <li>
                                                        <i className="flaticon-read"></i>
                                                        <span className="ttm-list-li-content">What is the laundry cut off times?</span>
                                                        <p>For the morning shift place your order before 10 am same day.</p>
                                                    </li>
                                                    <li>
                                                        <i className="flaticon-read"></i>
                                                        <span className="ttm-list-li-content">How do I get the rid of bed bugs?</span>
                                                        <p>We provide services to get rid of bed bugs and are available.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    </TabPanel>
                                </div>       
                            </Tabs>
                        </div>
                    </div>
                </div>
              </div>
            </section>

            
            <Footer/>
                        
         </div>
       )
   }
}


export default About_us;