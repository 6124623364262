import React, { Component, useEffect, useState } from 'react';
import Header from '../components/layout/Header';
import Slider from 'react-slick';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import Header2 from '../components/layout/Header2';
import CountUp from 'react-countup';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardHeader,
    MDBCardFooter,
    MDBBtn
} from 'mdbreact';

import {
    db,
    auth,
    createUser,
    login,
    signout,
    resetPwd,
    onAuthStateChanged,
    addOrUpdateDoc,
    getDocument,
    getDocumentWhere,
    getAllDocuments
} from '../firebase';
import Modal from '../components/modal/Modal';
import api from '../api';
import templates from '../emailTemplates.js'




export class Admin_Panel extends Component {

    constructor() {
        super();
        this.state = {
            name: "LaundroLift",
            clientSearchk: "",
            selectedClient: {},
            user: null,
            showModal: false,
            modalData: "",
            view: 1,
            ordersView: 1,
            isSameAddress: true,
            existingOrders: [],
            newOrder: {
                date: new Date(),
                time: "",
                address: "",
                status: "",
                orderNumber: "",
                weight: "",
                pricePerLbs: "",
                roundLiftFee: "",
                convenienceFee: ""
            },
            clients: [],
            timeOptions: [],
            firebaseUser: null,
            openNewMemberDialog: false,
            showOrderUpdateModal: false,
            idToken: null
        };
        this.onChangeValue = this.onChangeValue.bind(this);

    }

    componentDidMount() {
        this.unsubscribeAuth = onAuthStateChanged(auth, (user) => {

            console.log("user: ", user)

            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                const uid = user.uid;

                user.getIdToken().then((idTk) => {
                    this.setState({ idToken: idTk });
                })

                getDocumentWhere("users", "uid", uid).then((uDataRes) => {
                    console.log("uDataRes: ", uDataRes);
                    if (uDataRes.status) {
                        if (uDataRes.data[0].type == 5) {
                            this.setState({ user: uDataRes.data[0], view: 1 }, this.getAdminData());
                        }
                    }
                })
                // this.setState({ user: user }, () => {
                //     // this.fetchData();
                // });
                // ...
            } else {
                // User is signed out
                // ...
            }
        });

        // this.fetchData();
    }

    componentWillUnmount() {
        if (this.unsubscribeAuth) {
            this.unsubscribeAuth();
        }
    }

    toggleNewClient = () => {
        this.setState({ openNewMemberDialog: true });
    }

    handleDateChange = (event) => {
        const newDate = event.target.value;
        let [year, month, day] = newDate.split('-');
        const selectedDate = new Date(`${year}/${month}/${day}`);
        const today = new Date();
        const timeInput = document.getElementById('time');
        console.log("formatted d: ", `${year}-${month}-${day}T00:00:00Z`, "newDate: ", newDate, "selectedDate ", selectedDate.toDateString(), "today ", today.toDateString())

        const timeOptions = timeInput.options;
        var currentTimes = this.state.timeOptions;

        if (selectedDate.toDateString() === today.toDateString()) {

            const now = today.getHours() * 60 + today.getMinutes() + 60;

            for (let i = 0; i < currentTimes.length; i++) {
                var tOption = currentTimes[i];
                const time = tOption.value.split(':');
                const timeInMinutes = parseInt(time[0]) * 60 + parseInt(time[1]);
                if (timeInMinutes < now) {
                    tOption.available = false;
                } else {
                    tOption.available = true;
                }
            }
        } else {
            for (let i = 0; i < currentTimes.length; i++) {
                var tOption = currentTimes[i];
                tOption.available = true;
            }
        }

        this.setState({ timeOptions: currentTimes });
    };

    updateTimeOptions = (minTime) => {
        setTimeout(() => {
            const timeInput = document.getElementById('time');
            const dateInput = document.getElementById('date');
            const today = new Date().toISOString().split('T')[0];
            console.log("today: ", new Date().toISOString());
            
            if (dateInput?.min) {
                dateInput.min = today;
            }

            // Array of excluded times in HH:mm format
            // const excludedTimes = ['07:00', '12:00', '15:30'];

            // // Function to check if a time is excluded
            // const isExcludedTime = (time) => excludedTimes.includes(time);


            const options = [];

            // Generate options for times between 6am and 5pm
            for (let hour = 6; hour <= 17; hour++) {
                for (let minute = 0; minute < 60; minute += 30) {
                    const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                    // if (!isExcludedTime(time)) {
                    options.push(time);
                    // }
                }
            }

            var fOptions = [];
            options.forEach(option => {
                fOptions.push({ value: option, available: true })
            });

            console.log("fOptions: ", fOptions);
            console.log("options: ", options);

            this.setState({ timeOptions: fOptions });

        }, 2000)
    }

    onChangeValue = (event) => {
        console.log(event.target.value);
    }

    getAdminData = () => {
        this.getOrders();
        this.getClients();
    }

    getOrders() {
        getAllDocuments('orders').then((res) => {
            console.log("getAllDocuments('orders'): ", res);
            if (res.status && res.data?.length > 0) {
                this.setState({ existingOrders: res.data }, () => (console.log(this.state.existingOrders)))
            }
        })
    }

    getClients() {
        getDocumentWhere("users", "type", 1).then((uDataRes) => {
            console.log("uDataRes: ", uDataRes);
            if (uDataRes.status && uDataRes.data[0]) {
                this.setState({ clients: uDataRes.data });
            }
        });
    }

    login = (event) => {
        event.preventDefault();

        var data = {
            email: event.target.elements.email.value,
            password: event.target.elements.password.value
        }

        var allGood = false;

        login(data.email, data.password).then((res) => {
            console.log("loginRes: ", res)
            if (res.status) {
                getDocumentWhere("users", "uid", res.data.uid).then((uDataRes) => {
                    console.log("uDataRes: ", uDataRes);
                    if (uDataRes.status) {
                        if (uDataRes.data[0].type == 5) {
                            allGood = true;
                            this.setState({ user: uDataRes.data[0], view: 1 }, this.getAdminData());
                        }
                    }

                    if (!allGood) {

                    }

                })
            } else {
                if (res.msg.toLowerCase().indexOf("invalid") > -1) { //"Firebase: Error (auth/invalid-credential)."
                    this.setState({
                        modalData: {
                            toggleModal: () => { },
                            title: "Invalid Credentials",
                            htmlBody: "Please Review youe Email and/or Password And try again.",
                            onNo: () => { },
                            onOk: () => { },
                            noText: "",
                            okText: "Ok"
                        }, showModal: true
                    })
                } else {
                    this.setState({
                        modalData: {
                            title: "Invalid Credentials",
                            htmlBody: res.msg,
                            noText: "",
                            okText: "Ok"
                        }
                    }, () => { this.setState({ showModal: true }) })
                }
            }
        })
    }

    closeModal = (val) => {
        console.log("clicked: ", val)
        this.setState({ showModal: false });
    }

    createAccount = async (event) => {
        event.preventDefault();

        try {
            var data = {
                email: event.target.elements.email.value,
                password: event.target.elements.password.value,
                confirmpassword: event.target.elements.confirmpassword.value,
                lastname: event.target.elements.lastname.value,
                firstname: event.target.elements.firstname.value,
                street: event.target.elements.street.value,
                city: event.target.elements.city.value,
                zip: event.target.elements.zip.value,
                type: 1
            }

            createUser(data.email, data.password).then((res) => {
                if (res.status) {
                    addOrUpdateDoc('users', res.data.uid, { ...data, uid: res.data.uid, accessToken: res.data.accessToken }).then((docres) => {
                        if (docres.status) {
                            console.log("data: ", data);
                            this.setState({ user: { ...data, uid: res.data.uid, accessToken: res.data.accessToken }, view: 2 });
                            this.updateTimeOptions();
                        }
                    })
                }
            });
        } catch (error) {
            console.error('Error signing up: ', error);
            alert(error.message);
        }
    }


    viewOrder = (ord, i) => {

    }

    cancelOrder = (ord, i) => {
        var eO = this.state.existingOrders;
        eO[i].status = "Cancelled";
        console.log("order: ", eO[i]);
        var msg = `<div style={{display: 'flex', flexDirection: 'column', padding: '15px'}}>
        <div style={{margin: '7px'}}>Client: ${eO[i].firstname} ${eO[i].lastname}</div>
        <div style={{margin: '7px'}}>Date Time: ${eO[i].date} ${eO[i].time}</div>
        <div style={{margin: '7px'}}>Address: ${eO[i].address}</div>
        <div style={{margin: '7px'}}>Email: ${eO[i].email}</div>
        <div style={{margin: '7px'}}>Note: ${eO[i].note}</div>
        </div>`
        var emailBody = templates.orderUpdate;
        emailBody = emailBody.replaceAll(`{{Status}}`, ` Cancelled`);
        emailBody = emailBody.replaceAll(`{{clientName}}`, ` ${eO[i].firstname} ${eO[i].lastname}`);
        emailBody = emailBody.replaceAll(`{{orderDateTime}}`, ` ${eO[i].date} ${eO[i].time}`);
        emailBody = emailBody.replaceAll(`{{weight}}`, ` ${eO[i].weight}`);
        emailBody = emailBody.replaceAll(`{{address}}`, ` ${eO[i].address}`);
        emailBody = emailBody.replaceAll(`{{OrderNumber}}`, ` ${eO[i].orderNumber}`);

        this.setState({
            modalData: {
                toggleModal: () => { this.setState({ showOrderUpdateModal: false }) },
                title: "Cancelled This Order?",
                htmlBody: msg,
                onNo: () => { },
                onOk: () => {
                    addOrUpdateDoc('orders', eO[i].orderNumber, { ...eO[i] }).then((docres) => {
                        if (docres.status) {
                            console.log("addOrUpdateDoc('orders'): ", docres);
                            api.sendEmail({ subject: "LaundroLift: Order Cancelled", body: emailBody, to: `${eO[i].email}` }, this.state.idToken).then((res) => {
                                console.log("sendEmailRes: ", res);
                            })
                            this.getOrders();
                            this.setState({ showOrderUpdateModal: false });
                        }
                    })
                },
                noText: "No",
                okText: "Yes"
            }, showOrderUpdateModal: true
        })
    }

    pickupOrder = (ord, i) => {
        var eO = this.state.existingOrders;
        eO[i].status = "In Progress";
        eO[i].pickedUpAt = new Date().toLocaleDateString();
        eO[i].pickedUpBy = this.state.user.uid;

        eO[i].pricePerLbs = 1.35
        eO[i].roundLiftFee = Number(eO[i].weight) > 25 ? 0 : 5.25;
        // eO[i].weight = 
        // eO[i].note = 


        console.log("order: ", eO[i]);
        var msg = `<div style={{display: 'flex', flexDirection: 'column', padding: '15px'}}>
        <div style={{margin: '7px'}}>Client: ${eO[i].firstname} ${eO[i].lastname}</div>
        <div style={{margin: '7px'}}>Date Time: ${eO[i].date} ${eO[i].time}</div>
        <div style={{margin: '7px'}}>Address: ${eO[i].address}</div>
        <div style={{margin: '7px'}}>Email: ${eO[i].email}</div>
        <div style={{margin: '7px'}}>Note: ${eO[i].note}</div>
        </div>`
        var emailBody = templates.orderUpdate;
        emailBody = emailBody.replaceAll(`{{Status}}`, ` Picked Up`);
        emailBody = emailBody.replaceAll(`{{clientName}}`, ` ${eO[i].firstname} ${eO[i].lastname}`);
        emailBody = emailBody.replaceAll(`{{orderDateTime}}`, ` ${eO[i].date} ${eO[i].time}`);
        emailBody = emailBody.replaceAll(`{{weight}}`, ` ${eO[i].weight}`);
        emailBody = emailBody.replaceAll(`{{address}}`, ` ${eO[i].address}`);

        this.setState({
            modalData: {
                toggleModal: () => { this.setState({ showOrderPickupModal: false }) },
                title: "Pickup This Order?",
                htmlBody: msg,
                order: eO[i],
                onNo: () => { },
                onOk: (price) => {
                    this.getOrders();
                    var priceInsert = `${eO[i].orderNumber}
                    </h4>
                  </div>
                    <div class="mb-2" style="width: 100%; padding: 10px 20px;">
                      <h4><i class="fas fa-hourglass-half" aria-hidden="true"
                          style="margin-right: 5px;"></i>
                        Price: ${price}`
                    emailBody = emailBody.replaceAll(`{{OrderNumber}}`, ` ${priceInsert}`);
                    api.sendEmail({ subject: "LaundroLift: Order Picked Up", body: emailBody, to: `${eO[i].email}` }, this.state.idToken).then((res) => {
                        console.log("sendEmailRes: ", res);
                    })
                    this.setState({ showOrderPickupModal: false });
                },
                noText: "No",
                okText: "Yes"
            }, showOrderPickupModal: true
        })
    }

    confirmOrder = (ord, i) => {
        var eO = this.state.existingOrders;
        eO[i].status = "Awaiting Pickup";
        eO[i].confirmedAt = new Date().toLocaleDateString();
        eO[i].confirmedBy = this.state.user.uid;
        console.log("order: ", eO[i]);
        var msg = `<div style={{display: 'flex', flexDirection: 'column', padding: '15px'}}>
        <div style={{margin: '7px'}}>Client: ${eO[i].firstname} ${eO[i].lastname}</div>
        <div style={{margin: '7px'}}>Date Time: ${eO[i].date} ${eO[i].time}</div>
        <div style={{margin: '7px'}}>Address: ${eO[i].address}</div>
        <div style={{margin: '7px'}}>Email: ${eO[i].email}</div>
        <div style={{margin: '7px'}}>Note: ${eO[i].note}</div>
        </div>`
        var emailBody = templates.orderUpdate;
        emailBody = emailBody.replaceAll(`{{Status}}`, ` Confirmed For Pickup`);
        emailBody = emailBody.replaceAll(`{{clientName}}`, ` ${eO[i].firstname} ${eO[i].lastname}`);
        emailBody = emailBody.replaceAll(`{{orderDateTime}}`, ` ${eO[i].date} ${eO[i].time}`);
        emailBody = emailBody.replaceAll(`{{weight}}`, ` ${eO[i].weight}`);
        emailBody = emailBody.replaceAll(`{{address}}`, ` ${eO[i].address}`);
        emailBody = emailBody.replaceAll(`{{OrderNumber}}`, ` ${eO[i].orderNumber}`);

        this.setState({
            modalData: {
                toggleModal: () => { this.setState({ showOrderUpdateModal: false }) },
                title: "Confirm This Order?",
                htmlBody: msg,
                onNo: () => { },
                onOk: () => {
                    addOrUpdateDoc('orders', eO[i].orderNumber, { ...eO[i] }).then((docres) => {
                        if (docres.status) {
                            console.log("addOrUpdateDoc('orders'): ", docres);
                            api.sendEmail({ subject: "LaundroLift: Order Confirmed For Pickup", body: emailBody, to: `${eO[i].email}` }, this.state.idToken).then((res) => {
                                console.log("sendEmailRes: ", res);
                            })
                            this.getOrders();
                            this.setState({ showOrderUpdateModal: false });
                        }
                    })
                },
                noText: "No",
                okText: "Yes"
            }, showOrderUpdateModal: true
        })
    }

    completeOrder = (ord, i) => {
        var eO = this.state.existingOrders;
        eO[i].status = "Completed";
        eO[i].completedAt = new Date().toLocaleDateString();
        eO[i].completedBy = this.state.user.uid;
        console.log("order: ", eO[i]);
        var msg = `<div style={{display: 'flex', flexDirection: 'column', padding: '15px'}}>
        <div style={{margin: '7px'}}>Client: ${eO[i].firstname} ${eO[i].lastname}</div>
        <div style={{margin: '7px'}}>Date Time: ${eO[i].date} ${eO[i].time}</div>
        <div style={{margin: '7px'}}>Address: ${eO[i].address}</div>
        <div style={{margin: '7px'}}>Email: ${eO[i].email}</div>
        <div style={{margin: '7px'}}>Note: ${eO[i].note}</div>
        </div>`
        var emailBody = templates.orderUpdate;
        emailBody = emailBody.replaceAll(`{{Status}}`, ` Dropped Off`);
        emailBody = emailBody.replaceAll(`{{clientName}}`, ` ${eO[i].firstname} ${eO[i].lastname}`);
        emailBody = emailBody.replaceAll(`{{orderDateTime}}`, ` ${eO[i].date} ${eO[i].time}`);
        emailBody = emailBody.replaceAll(`{{weight}}`, ` ${eO[i].weight}`);
        emailBody = emailBody.replaceAll(`{{address}}`, ` ${eO[i].address}`);
        emailBody = emailBody.replaceAll(`{{OrderNumber}}`, ` ${eO[i].orderNumber}`);


        this.setState({
            modalData: {
                toggleModal: () => { this.setState({ showOrderUpdateModal: false }) },
                title: "Complete This Order?",
                htmlBody: msg,
                onNo: () => { },
                onOk: () => {
                    addOrUpdateDoc('orders', eO[i].orderNumber, { ...eO[i] }).then((docres) => {
                        if (docres.status) {
                            console.log("addOrUpdateDoc('orders'): ", docres);
                            api.sendEmail({ subject: "LaundroLift: Order Dropped Off", body: emailBody, to: `${eO[i].email}` }, this.state.idToken).then((res) => {
                                console.log("sendEmailRes: ", res);
                            })
                            this.getOrders();
                            this.setState({ showOrderUpdateModal: false });
                        }
                    })
                },
                noText: "No",
                okText: "Yes"
            }, showOrderUpdateModal: true
        })
    }

    saveNewOrder = (event) => {
        event.preventDefault();
        if (this.state.user) {

            var street = this.state.isSameAddress ? this.state.selectedClient?.street : event.target.elements.street.value
            var city = this.state.isSameAddress ? this.state.selectedClient?.city : event.target.elements.city.value
            var zip = this.state.isSameAddress ? this.state.selectedClient?.zip : event.target.elements.zip.value

            var data = {
                date: event.target.elements.date.value,
                time: event.target.elements.time.value,
                note: event.target.elements.note.value,
                lastname: this.state.selectedClient?.lastname,
                firstname: this.state.selectedClient?.firstname,
                email: this.state.selectedClient?.email,
                clientId: this.state.selectedClient.uid,
                address: street + ", " + city + ", " + zip,
                status: "Awaiting Confirmation",
                orderNumber: this.generateRandomCode(),
                weight: 1,
                pricePerLbs: 1.75,
                roundLiftFee: 8.25,
                convenienceFee: 0.00
            }

            var emailBody = templates.orderUpdate;
            emailBody = emailBody.replaceAll(`{{Status}}`, ` Placed`);
            emailBody = emailBody.replaceAll(`{{clientName}}`, ` ${data.firstname} ${data.lastname}`);
            emailBody = emailBody.replaceAll(`{{orderDateTime}}`, ` ${data.date} ${data.time}`);
            emailBody = emailBody.replaceAll(`{{weight}}`, ` TBD`);
            emailBody = emailBody.replaceAll(`{{address}}`, ` ${data.address}`);
            emailBody = emailBody.replaceAll(`{{OrderNumber}}`, ` ${data.orderNumber}`);

            console.log("data: ", data);

            addOrUpdateDoc('orders', data.orderNumber, { ...data }).then((docres) => {
                if (docres.status) {
                    console.log("data: ", data);
                    this.getOrders();
                    this.setState({ view: 1 });
                    api.sendEmail({ subject: "LaundroLift: Order Placed", body: emailBody, to: `${data.email}` }, this.state.idToken).then((res) => {
                        console.log("sendEmailRes: ", res);
                    })
                }
            })

        } else {
            //TODO: handle no user error
        }
    }

    generateRandomCode() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const seconds = String(today.getSeconds());

        const randomDigits = String(Math.floor(10000 + Math.random() * 90000)); // Generates random 5-digit number
        const code = `${year}${month}${day}${seconds}-${randomDigits}`;

        return code;
    }

    convertTo12HourFormat(time) {
        // Split the input time into hours and minutes
        const [hours, minutes] = time.split(':').map(Number);

        // Determine AM or PM
        const period = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour format to 12-hour format
        const hours12 = hours % 12 || 12; // Modulus operator to convert 24-hour to 12-hour, ensuring 12:00 is correctly handled

        // Return the formatted time
        return `${hours12}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
    }

    render() {
        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 1,

            responsive: [{

                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };
        return (

            <div className="site-main">
                {/* <Modal {...this.state.modalData} /> */}
                {this.state.showModal ? (<Modal
                    toggleModal={this.closeModal}
                    title={this.state.modalData.title}
                    htmlBody={this.state.modalData.htmlBody}
                    onNo={this.closeModal}
                    onOk={this.closeModal}
                    noText={this.state.modalData.noText}
                    okText={this.state.modalData.okText} />) : null}

                {this.state.showOrderUpdateModal ? (<Modal
                    toggleModal={() => this.setState({ showOrderUpdateModal: false })}
                    title={this.state.modalData.title}
                    htmlBody={this.state.modalData.htmlBody}
                    onNo={() => this.setState({ showOrderUpdateModal: false })}
                    onOk={this.state.modalData.onOk}
                    noText={this.state.modalData.noText}
                    okText={this.state.modalData.okText} />) : null}

                {/*  */}

                {this.state.showOrderPickupModal ? (<PickupOrder
                    toggleModal={() => this.setState({ showOrderPickupModal: false })}
                    title={this.state.modalData.title}
                    htmlBody={this.state.modalData.htmlBody}
                    onNo={() => this.setState({ showOrderPickupModal: false })}
                    onOk={this.state.modalData.onOk}
                    noText={"Cancel"}
                    okText={"Confirm Pickup"}
                    order={this.state.modalData.order} />) : null}

                {this.state.openNewMemberDialog ? (<NewClient
                    toggleModal={() => this.setState({ openNewMemberDialog: false })}
                    title={"New Client"}
                    htmlBody={""}
                    onNo={() => this.setState({ openNewMemberDialog: false })}
                    onOk={(res) => {
                        if (res) {
                            this.getClients();

                            this.setState({ openNewMemberDialog: false }, () => {
                                setTimeout(() => {
                                    // this.setState({ clientSearchk: res })
                                    this.setState({ selectedClient: res }, this.updateTimeOptions())
                                }, 2500)
                            })
                        }
                    }}
                    noText={"Cancel"}
                    okText={"Ok"} />) : null}


                <Header2 />

                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title-box text-center">
                                    <div className="page-title-heading">
                                        <h2 className="title">Laundry services</h2>
                                    </div>{/* /.page-title-captions */}
                                    <div className="breadcrumb-wrapper">
                                        <span>
                                            <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                        </span>
                                        <span>
                                            <a title="Homepage" href={'/'} >&nbsp;&nbsp;/ Services</a>
                                        </span>
                                        <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                        <span>Laundry services</span>
                                    </div>
                                </div>
                            </div>{/* /.col-md-12 */}
                        </div>{/* /.row */}
                    </div>{/* /.container */}
                </div>

                <div className="sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left border-right pr-45 pr-lg-15">
                                {!this.state.user ? (
                                    <aside className="widget widget-nav-menu">
                                        <h3 className="widget-title">How It Works</h3>
                                        <ul>
                                            <li className="d-flex">You schedule the pickup Time</li>
                                            <li className="d-flex">At pickup, we wight your garments to give you an Idea of the cost</li>
                                            <li className="d-flex">With your approval and after payment, we take your garments to the washing center</li>
                                            <li className="d-flex">We have your garments wash, dry, fold</li>
                                            <li className="d-flex">And deliver it back to you.</li>
                                            {/* <li className="d-flex">Steam iron</li> */}
                                        </ul>
                                    </aside>
                                ) : null}
                                <aside className="widget image-box">
                                    <div className="widget_img">
                                        <img src="./images/single-img-8-369x154.jpg" />
                                    </div>
                                    <div className="featured-imagebox featured-imagebox-service style2">
                                        <div className="featured-image-box-wrapper">
                                            <div className="featured-thumbnail">
                                                <img width="41" height="41" src="./images/whats-up.png" />
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href="tel:+19085296733">+1 908 529-6733</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <h2>Call us for all your laundry needs</h2>
                                    </div>
                                </aside>
                                <aside className="widget widget-download">
                                    <h3 className="widget-title">Download Our App</h3>
                                    <ul className="download">
                                        <li>
                                            <a><h3>App Store</h3>
                                                <i className="far fa-apple" /></a>
                                        </li>
                                        <li>
                                            <a><h3>PlayStore</h3>
                                                <i className="far fa-android" /></a>
                                        </li>
                                    </ul>
                                    <h3 className="widget-title">Follow Us</h3>
                                    <div className="ttm-social-links-wrapper mt-20">
                                        <ul className="social-icons">
                                            <li className="social-facebook">
                                                <a target="_blank" href="https://www.facebook.com/profile.php?id=61559193497510"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-lg-8 widget-area sidebar-right pl-45 pl-lg-15">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        {!this.state.user ? (
                                            <div className="col-lg-12 col-md-12">

                                                <div className="ttm-service-classic-content">
                                                    <h2 className="pt-0">Welcome! Please enter your credentials</h2>
                                                    <p>

                                                    </p>
                                                </div>
                                                <div className="mt-40 mb-40" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
                                                    <form onSubmit={this.login} className="row" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <h6 className='col-12'>Login Now</h6>
                                                        {/* Login Now */}
                                                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                                            <div className="col-lg-6 col-md-10" style={{ margin: "10px" }}>
                                                                <div className="form-outline" data-mdb-input-init>
                                                                    <label className="form-label" htmlFor="email">Email</label>
                                                                    <input required type="text" id="email" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-10" style={{ margin: "10px" }}>
                                                                <div className="form-outline" data-mdb-input-init>
                                                                    <label className="form-label" htmlFor="password">Password</label>
                                                                    <input required type="password" id="password" className="form-control" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-35">Log In</button>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <img src="./images/single-img-14-836x399.png" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='row'>
                                                <aside>
                                                    {/* <h3 className="widget-title">Menu</h3> */}
                                                    <ul style={{ display: "flex", flexDirection: "row" }}>
                                                        <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => this.setState({ view: 1 })}>
                                                            {this.state.view == 1 ? (
                                                                <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>Manage Orders</div>
                                                            ) : "Orders"}
                                                        </li>
                                                        <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => { this.setState({ selectedClient: {}, view: 2 }); }}>
                                                            {this.state.view == 2 ? (
                                                                <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>New Order</div>
                                                            ) : "New Order"}
                                                        </li>
                                                        <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => this.setState({ view: 3 })}>
                                                            {this.state.view == 3 ? (
                                                                <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>Account Info</div>
                                                            ) : "Account Info"}
                                                        </li>
                                                    </ul>
                                                </aside>
                                                {this.state.view == 1 && (
                                                    <div style={{ display: "flex", flexDirection: "column", padding: "15px" }}>

                                                        <ul style={{ display: "flex", flexDirection: "row" }}>
                                                            <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => this.setState({ ordersView: 1 })}>
                                                                {this.state.ordersView == 1 ? (
                                                                    <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>Awaiting Confirmation</div>
                                                                ) : "Awaiting Confirmation"}
                                                            </li>
                                                            <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => { this.setState({ ordersView: 2 }) }}>
                                                                {this.state.ordersView == 2 ? (
                                                                    <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>Awaiting Pickup</div>
                                                                ) : "Awaiting Pickup"}
                                                            </li>
                                                            <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => this.setState({ ordersView: 3 })}>
                                                                {this.state.ordersView == 3 ? (
                                                                    <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>In Progress</div>
                                                                ) : "In Progress"}
                                                            </li>
                                                            <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => this.setState({ ordersView: 5 })}>
                                                                {this.state.ordersView == 5 ? (
                                                                    <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>Completed</div>
                                                                ) : "Completed"}
                                                            </li>
                                                            <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => this.setState({ ordersView: 4 })}>
                                                                {this.state.ordersView == 4 ? (
                                                                    <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>Cancelled</div>
                                                                ) : "Cancelled"}
                                                            </li>
                                                        </ul>

                                                        {this.state.existingOrders.length < 1 ? (
                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                <h6>You do not have any order yet!</h6>
                                                                <button type='button' onClick={() => { this.setState({ view: 2 }); this.updateTimeOptions(); }} className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-25">
                                                                    Order New Pickup
                                                                </button>
                                                            </div>
                                                        ) : (
                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                {this.state.ordersView == 1 && (
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <h6 className='mt-10 mb-15'>Awaiting Confirmation</h6>
                                                                        {this.state.existingOrders.map((ord, i) => {
                                                                            if (ord.status == "Awaiting Confirmation") {
                                                                                return (
                                                                                    <MDBCard alignment='center' className='mb-10' key={i}>
                                                                                        <MDBCardHeader>Ref #: {ord.orderNumber}</MDBCardHeader>
                                                                                        <MDBCardBody>
                                                                                            <MDBCardText>
                                                                                                <div style={{ display: "flex", flexDirection: "column" }}>

                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-user-alt" aria-hidden="true"></i>
                                                                                                        Client: {ord.firstname} {ord.lastname}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="far fa-clock" aria-hidden="true"></i>
                                                                                                        Pickup Date Time: {ord.date + " at " + ord.time}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-dollar-sign" aria-hidden="true"></i>
                                                                                                        Prices: ${ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : Number(ord.pricePerLbs * ord.weight + (ord.roundLiftFee + ord.convenienceFee))?.toFixed(2)}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-funnel-dollar" aria-hidden="true"></i>
                                                                                                        Weight: {ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : ord.weight} lbs
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-directions" aria-hidden="true"></i>
                                                                                                        Address: {ord.address}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <h6>
                                                                                                            <i className="fas fa-hourglass-half" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                                                                            Status:
                                                                                                            <span style={{ color: "blue" }}>{ord.status}</span></h6>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </MDBCardText>
                                                                                        </MDBCardBody>
                                                                                        <MDBCardFooter>
                                                                                            <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-35 mr-15" onClick={() => this.cancelOrder(ord, i)}>Cancel</button>
                                                                                            <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-35" onClick={() => this.confirmOrder(ord, i)}>Confirm Order</button>
                                                                                        </MDBCardFooter>
                                                                                    </MDBCard>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </div>)}

                                                                {this.state.ordersView == 2 && (
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>

                                                                        <h6 className='mt-10 mb-15'>Awaiting Pickup</h6>
                                                                        {this.state.existingOrders.map((ord, i) => {
                                                                            if (ord.status == "Awaiting Pickup") {
                                                                                return (
                                                                                    <MDBCard alignment='center' className='mb-10' key={i}>
                                                                                        <MDBCardHeader>Ref #: {ord.orderNumber}</MDBCardHeader>
                                                                                        <MDBCardBody>
                                                                                            <MDBCardText>
                                                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-user-alt" aria-hidden="true"></i>
                                                                                                        Client: {ord.firstname} {ord.lastname}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="far fa-clock" aria-hidden="true"></i>
                                                                                                        Pickup Date Time: {ord.date + " at " + ord.time}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-dollar-sign" aria-hidden="true"></i>
                                                                                                        Prices: ${ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : Number(ord.pricePerLbs * ord.weight + (ord.roundLiftFee + ord.convenienceFee))?.toFixed(2)}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-funnel-dollar" aria-hidden="true"></i>
                                                                                                        Weight: {ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : ord.weight} lbs
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-directions" aria-hidden="true"></i>
                                                                                                        Address: {ord.address}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <h6>
                                                                                                            <i className="fas fa-hourglass-half" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                                                                            Status:
                                                                                                            <span style={{ color: "blue" }}>{ord.status}</span></h6>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </MDBCardText>
                                                                                        </MDBCardBody>
                                                                                        <MDBCardFooter className='text-muted'>
                                                                                            <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-35 mr-15" onClick={() => this.cancelOrder(ord, i)}>Cancel</button>
                                                                                            <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-35" onClick={() => this.pickupOrder(ord, i)}>Pickup Load</button>
                                                                                        </MDBCardFooter>
                                                                                    </MDBCard>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </div>)}

                                                                {this.state.ordersView == 3 && (
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <h6 className='mt-10 mb-15'>In Progress</h6>
                                                                        {this.state.existingOrders.map((ord, i) => {
                                                                            if (ord.status == "In Progress") {
                                                                                return (
                                                                                    <MDBCard alignment='center' className='mb-10' key={i}>
                                                                                        <MDBCardHeader>Ref #: {ord.orderNumber}</MDBCardHeader>
                                                                                        <MDBCardBody>
                                                                                            <MDBCardText>
                                                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-user-alt" aria-hidden="true"></i>
                                                                                                        Client: {ord.firstname} {ord.lastname}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="far fa-clock" aria-hidden="true"></i>
                                                                                                        Pickup Date Time: {ord.date + " at " + ord.time}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-dollar-sign" aria-hidden="true"></i>
                                                                                                        Prices: ${ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : Number(ord.pricePerLbs * ord.weight + (ord.roundLiftFee + ord.convenienceFee))?.toFixed(2)}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-funnel-dollar" aria-hidden="true"></i>
                                                                                                        Weight: {ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : ord.weight} lbs
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-directions" aria-hidden="true"></i>
                                                                                                        Address: {ord.address}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <h6>
                                                                                                            <i className="fas fa-hourglass-half" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                                                                            Status:
                                                                                                            <span style={{ color: "blue" }}>{ord.status}</span></h6>
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <h6>
                                                                                                            {ord.paymentStatus == 'Unpaid' && (
                                                                                                                <i className="fas fa-exclamation-triangle" aria-hidden="true" style={{ marginRight: "5px", color: 'red' }}></i>
                                                                                                            )}
                                                                                                            {ord.paymentStatus == 'Paid' && (
                                                                                                                <i className="fas fa-check-circle" aria-hidden="true" style={{ marginRight: "5px", color: 'green' }}></i>
                                                                                                            )}
                                                                                                            Payment Status:
                                                                                                            <span style={{ color: "blue", marginLeft: '15px' }}>{ord.paymentStatus || 'Unpaid'}</span></h6>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </MDBCardText>
                                                                                        </MDBCardBody>
                                                                                        <MDBCardFooter className='text-muted'>
                                                                                            <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-35 mr-15" onClick={() => this.cancelOrder(ord, i)}>Cancel</button>
                                                                                            <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-35" onClick={() => this.completeOrder(ord, i)}>Complete Delivery</button>
                                                                                        </MDBCardFooter>
                                                                                    </MDBCard>
                                                                                )
                                                                            }
                                                                        })}

                                                                    </div>)}

                                                                {this.state.ordersView == 4 && (
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <h6 className='mt-10 mb-15'>Cancelled</h6>
                                                                        {this.state.existingOrders.map((ord, i) => {
                                                                            if (ord.status == "Cancelled") {
                                                                                return (
                                                                                    <MDBCard alignment='center' className='mb-10' key={i}>
                                                                                        <MDBCardHeader>Ref #: {ord.orderNumber}</MDBCardHeader>
                                                                                        <MDBCardBody>
                                                                                            {/* <MDBCardTitle>Pickup Date Time: {ord.date + " at " + ord.time}</MDBCardTitle> */}
                                                                                            <MDBCardText>
                                                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-user-alt" aria-hidden="true"></i>
                                                                                                        Client: {ord.firstname} {ord.lastname}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="far fa-clock" aria-hidden="true"></i>
                                                                                                        Pickup Date Time: {ord.date + " at " + ord.time}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-dollar-sign" aria-hidden="true"></i>
                                                                                                        Prices: ${Number(ord.pricePerLbs * ord.weight + (ord.roundLiftFee + ord.convenienceFee))?.toFixed(2)}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-funnel-dollar" aria-hidden="true"></i>
                                                                                                        Weight: {ord.weight} lbs
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-directions" aria-hidden="true"></i>
                                                                                                        Address: {ord.address}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <h6>
                                                                                                            <i className="fas fa-hourglass-half" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                                                                            Status:
                                                                                                            <span style={{ color: ord.status == "Cancelled" ? "red" : "green", marginLeft: "5px" }}>{ord.status}</span>
                                                                                                        </h6>
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <h6>
                                                                                                            {ord.paymentStatus == 'Unpaid' && (
                                                                                                                <i className="fas fa-exclamation-triangle" aria-hidden="true" style={{ marginRight: "5px", color: 'red' }}></i>
                                                                                                            )}
                                                                                                            {ord.paymentStatus == 'Paid' && (
                                                                                                                <i className="fas fa-check-circle" aria-hidden="true" style={{ marginRight: "5px", color: 'green' }}></i>
                                                                                                            )}
                                                                                                            Payment Status:
                                                                                                            <span style={{ color: "blue", marginLeft: '15px' }}>{ord.paymentStatus || 'Unpaid'}</span></h6>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </MDBCardText>
                                                                                        </MDBCardBody>
                                                                                        <MDBCardFooter className='text-muted'>
                                                                                            <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-35" onClick={() => this.viewOrder(ord, i)}>View Details</button>
                                                                                        </MDBCardFooter>
                                                                                    </MDBCard>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </div>)}

                                                                {this.state.ordersView == 5 && (
                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                        <h6 className='mt-10 mb-15'>Completed</h6>
                                                                        {this.state.existingOrders.map((ord, i) => {
                                                                            if (ord.status == "Completed") {
                                                                                return (
                                                                                    <MDBCard alignment='center' className='mb-10' key={i}>
                                                                                        <MDBCardHeader>Ref #: {ord.orderNumber}</MDBCardHeader>
                                                                                        <MDBCardBody>
                                                                                            {/* <MDBCardTitle>Pickup Date Time: {ord.date + " at " + ord.time}</MDBCardTitle> */}
                                                                                            <MDBCardText>
                                                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-user-alt" aria-hidden="true"></i>
                                                                                                        Client: {ord.firstname} {ord.lastname}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="far fa-clock" aria-hidden="true"></i>
                                                                                                        Pickup Date Time: {ord.date + " at " + ord.time}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-dollar-sign" aria-hidden="true"></i>
                                                                                                        Prices: ${Number(ord.pricePerLbs * ord.weight + (ord.roundLiftFee + ord.convenienceFee))?.toFixed(2)}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-funnel-dollar" aria-hidden="true"></i>
                                                                                                        Weight: {ord.weight} lbs
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-directions" aria-hidden="true"></i>
                                                                                                        Address: {ord.address}
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <h6>
                                                                                                            <i className="fas fa-hourglass-half" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                                                                            Status:
                                                                                                            <span style={{ color: ord.status == "Cancelled" ? "red" : "green", marginLeft: "5px" }}>{ord.status}</span>
                                                                                                        </h6>
                                                                                                    </div>
                                                                                                    <div className='mb-2'>
                                                                                                        <h6>
                                                                                                            {ord.paymentStatus == 'Unpaid' && (
                                                                                                                <i className="fas fa-exclamation-triangle" aria-hidden="true" style={{ marginRight: "5px", color: 'red' }}></i>
                                                                                                            )}
                                                                                                            {ord.paymentStatus == 'Paid' && (
                                                                                                                <i className="fas fa-check-circle" aria-hidden="true" style={{ marginRight: "5px", color: 'green' }}></i>
                                                                                                            )}
                                                                                                            Payment Status:
                                                                                                            <span style={{ color: "blue", marginLeft: '15px' }}>{ord.paymentStatus || 'Unpaid'}</span></h6>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </MDBCardText>
                                                                                        </MDBCardBody>
                                                                                        <MDBCardFooter className='text-muted'>
                                                                                            <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-35" onClick={() => this.viewOrder(ord, i)}>View Details</button>
                                                                                        </MDBCardFooter>
                                                                                    </MDBCard>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </div>)}

                                                            </div>
                                                        )}
                                                    </div>
                                                )}

                                                {this.state.view == 2 && (
                                                    <form onSubmit={this.saveNewOrder} style={{ display: "flex", flexDirection: "column", padding: "15px" }}>
                                                        {this.state.selectedClient?.firstname ? (
                                                            <div className='row'>
                                                                <h6 className='mt-25 mb-10'>New Pickup Order For {this.state.selectedClient?.firstname + ", " + this.state.selectedClient?.lastname}</h6>

                                                                <div className="mt-2 mb-2 col-lg-6">
                                                                    <div className="form-outline">
                                                                        <label className="form-label" htmlFor="date">Date</label>
                                                                        <input onChange={this.handleDateChange} type="date" id="date" className="form-control" required />
                                                                    </div>
                                                                </div>

                                                                {/* <div className="mt-2 mb-2 col-lg-3"> */}
                                                                {/* <div className="form-outline">
                                                                    <label className="form-label" htmlFor="time">Time</label>
                                                                    <input required type="time" id="time" className="form-control" />
                                                                    <small className="form-text text-muted">*Your driver may arrive 15min earlier or later due to other pickups and traffic.</small>
                                                                </div> */}
                                                                <div className="mt-2 mb-2 col-lg-3">
                                                                    <div className="form-outline">
                                                                        <label className="form-label" htmlFor="time">Time</label>
                                                                        <select id="time" className="form-control" required>

                                                                            {this.state.timeOptions.map((opt) => {
                                                                                if (opt.available) {
                                                                                    return (<option value={opt.value}>{this.convertTo12HourFormat(opt.value)}</option>)
                                                                                }
                                                                            })}

                                                                        </select>
                                                                        <small className="form-text text-muted">*Your driver may arrive 15min earlier or later due to other pickups and traffic.</small>
                                                                    </div>
                                                                </div>
                                                                {/* </div> */}

                                                                <div className="mt-2 mb-2 col-lg-6">
                                                                    <div className="form-outline">
                                                                        <label className="form-label" htmlFor="note">Note</label>
                                                                        <textarea id="note" className="form-control"> </textarea>
                                                                    </div>
                                                                </div>

                                                                <div className="mt-2 mb-2 col-lg-6">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            id="differentLocation"
                                                                            checked={this.state.isSameAddress}
                                                                            onChange={(e) => this.setState({ isSameAddress: !this.state.isSameAddress })}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="differentLocation">
                                                                            Pickup is at Client's address
                                                                        </label>
                                                                    </div>
                                                                    <p>{this.state.selectedClient.street}, {this.state.selectedClient.city}, PA {this.state.selectedClient.zip}</p>
                                                                </div>

                                                                {!this.state.isSameAddress && (
                                                                    <div className='row'>
                                                                        <h6 className='mt-25 mb-10'>Pickup Address</h6>
                                                                        <small className="form-text text-muted">*We serve in Philadelphia and nearing Delaware County Cities.</small>
                                                                        <div className="mt-2 mb-2 col-lg-12">
                                                                            <div className="form-outline" data-mdb-input-init>
                                                                                <label className="form-label" htmlFor="street">Street</label>
                                                                                <input required type="text" id="street" className="form-control" />
                                                                            </div>
                                                                        </div>

                                                                        <div className="mt-2 mb-2 col-lg-6">
                                                                            <div className="form-outline" data-mdb-input-init>
                                                                                <label className="form-label" htmlFor="city">City</label>
                                                                                <input required type="text" id="city" className="form-control" />
                                                                                <small>*We serve citys in Delaware County and Phyladelphia</small>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-2 mb-2 col-lg-4 col-md-6">
                                                                            <div className="form-outline" data-mdb-input-init>
                                                                                <label className="form-label" htmlFor="zip">Zip</label>
                                                                                <input required type="text" id="zip" className="form-control" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-25">
                                                                    Order New Pickup
                                                                </button>

                                                            </div>
                                                        ) : (

                                                            <div className='row'>
                                                                <h6 className='mt-25 mb-10'>New Pickup Order</h6>

                                                                <div className="mt-2 mb-2 col-12">
                                                                    <div className="form-outline col-6">
                                                                        <label className="form-label" htmlFor="client">Search Client</label>
                                                                        <input onChange={(e) => this.setState({ clientSearchk: e.target.value })} type="text" id="client" className="form-control" />
                                                                    </div>
                                                                    <div onClick={() => this.toggleNewClient()} style={{ textAlign: 'center', margin: '7px 15px', borderRadius: '5px', width: '200px', border: '1px gray solid', padding: '7px', cursor: 'pointer' }}>

                                                                        <i style={{ marginRight: "5px" }} className="fas fa-plus" aria-hidden="true"></i>

                                                                        New client
                                                                    </div>
                                                                </div>

                                                                <hr />

                                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                    {this.state.clients?.map((cl, i) => {
                                                                        var k = this.state.clientSearchk.toLowerCase();
                                                                        var fullname = cl.firstname + " " + cl.lastname;
                                                                        var rfullname = cl.lastname + " " + cl.firstname;
                                                                        if (cl.firstname.toLowerCase().indexOf(k) > -1 ||
                                                                            cl.lastname.toLowerCase().indexOf(k) > -1 ||
                                                                            fullname.toLowerCase().indexOf(k) > -1 ||
                                                                            rfullname.toLowerCase().indexOf(k) > -1 ||
                                                                            cl.email.toLowerCase().indexOf(k) > -1
                                                                        ) {
                                                                            return (
                                                                                <div key={i + "c"} style={{ margin: '6px 15px', display: 'flex', flexDirection: 'column', cursor: 'pointer', borderRadius: '5px', width: '80%', border: '1px gray solid', padding: '7px' }}
                                                                                    onClick={() => { this.setState({ selectedClient: cl }, this.updateTimeOptions()) }}>
                                                                                    <div style={{ margin: '6px', display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-user-alt" aria-hidden="true"></i>
                                                                                        <div>{cl?.firstname + ", " + cl?.lastname}</div>
                                                                                    </div>
                                                                                    <div style={{ margin: '6px', display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                                                                                        <i style={{ marginRight: "5px" }} className="fas fa-envelope" aria-hidden="true"></i>
                                                                                        <div>{cl?.email}</div>
                                                                                    </div>
                                                                                    <hr />
                                                                                    <div style={{ textAlign: 'center', margin: '7px', borderRadius: '5px', width: '100px', border: '1px gray solid', padding: '7px', cursor: 'pointer' }}>
                                                                                        Select
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </form>
                                                )}

                                                {this.state.view == 3 && (
                                                    <div style={{ display: "flex", flexDirection: "column", padding: "15px" }}>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div className="col-lg-12 col-md-12">

                                            <div className="ttm-service-classic-content">
                                                <h2>Service advantages</h2>
                                                <p>Order for convenient and free shipping on specific days in your area. A minimum order of $100 is
                                                    required to qualify for free shipping, email alert will let you know when free shipping is approaching,
                                                    and dont miss it.</p>
                                            </div>
                                            <div className="row mt-20">
                                                <div className="col-md-5">
                                                    <div className="featured-icon-box style4">
                                                        <div className="featured-icon">
                                                            <i className="flaticon-steam-iron"></i>
                                                        </div>
                                                        <div className="featured-content">
                                                            <div className="featured-title">
                                                                <h3>24/7 Supports</h3>
                                                            </div>
                                                            <div className="featured-desc">
                                                                <p>Contact us now. Don't miss out on free shipping!</p>
                                                            </div>
                                                            <div className="ttm-iconbox right-140">
                                                                <a href={'/Leather_cleaning'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabIndex="0">
                                                                    <i className="flaticon-up-right-arrow"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="border-radius-8 mt-15" src="./images/single-img-15-505x299.png" />
                                                </div>
                                                <p className="mt-20">We believe in vertically integrating as many cleaning processes as possible in order to achieve the high
                                                    standards set by ourselves and ultimately by our customer base. We own and operate a state-of-the-art van
                                                    that we use to personally collect and deliver your garments in pristine condition, pick up a rag during
                                                    business hours.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div >

                <Footer />

            </div >
        )
    }
}

const PickupOrder = (props) => {

    const [total, setTotal] = useState(0);
    const [noteRequired, setNoteRequired] = useState(0);

    useEffect(() => {
        console.log("props: ", props)
    }, []);

    const checkRequiredField = (e) => {
        console.log("e.target.value: ", e.target.value)
        setNoteRequired(e.target.value == 'With Authorized Rep' ? true : false);

    }

    const pickupOrder = async (event) => {
        event.preventDefault();

        try {
            var data = {
                ...props.order,
                pickupType: event.target.elements.pickupType.value,
                note: "Client Order Note: " + props.order.note + " \n Pickup Note: " + event.target.elements.note.value,
                weight: event.target.elements.weight.value,
                pricePerLbs: props.order.pricePerLbs,
                paymentStatus: 'Unpaid',
                totalAmount: total
            }


            console.log("order Pickup Data: ", data);

            addOrUpdateDoc('orders', data.orderNumber, { ...data }).then((docres) => {
                if (docres.status) {
                    console.log("addOrUpdateDoc('orders'): ", docres);
                    props.onOk(total);
                }
            })

        } catch (error) {
            console.error('Error signing up: ', error);
            alert(error.message);
        }
    }


    const calTotal = (e) => {
        var weight = Number(e.target.value) || 0;
        if (weight && weight < 1) {
            weight = 1;
        }
        var ttl = Number(props.order.convenienceFee + props.order.roundLiftFee + (props.order.pricePerLbs * weight))?.toFixed(2)
        setTotal(ttl);
    }

    return (

        <div
            style={{ display: 'flex', flexDirection: 'column', position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', justifyContent: 'center', zIndex: 999, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
            <div style={{ width: '90%', margin: '5%', overflow: 'auto', padding: '15px' }}>
                <div style={{ width: 'fit-content', height: 'fit-content', backgroundColor: 'white', borderRadius: '7px', margin: 'auto', padding: '15px' }}>
                    <div className="modal-header" style={{ width: 'fit-content', height: 'fit-content', margin: '9px' }}>

                        <button
                            type="button"
                            className="btn-close"
                            data-mdb-dismiss="modal"
                            aria-label="Close"
                            style={{ marginRight: '8px' }}
                            onClick={() => props.toggleModal(false)}
                        ></button>
                        <h5 className="modal-title" id="modalBoxLabel">{props.title}</h5>
                    </div>
                    <div className="modal-body" style={{ width: 'fit-content', height: 'fit-content', margin: '9px' }}>

                        <form onSubmit={pickupOrder} className="row">
                            <h6 className='mt-25 mb-10'>Please Enter Pickup Info</h6>
                            <div className="col-lg-6">
                                <div className="form-outline" data-mdb-input-init>
                                    <label className="form-label" htmlFor="weight">Weight (Lbs)</label>
                                    <input required type="number" min={0.1} step={0.1} onChange={calTotal} id="weight" className="form-control" />
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div style={{ display: 'flex', flexDirection: 'column', padding: '15px' }}>
                                    <div style={{ marginBottom: '7px' }}>Price Per Lbs: ${props.order.pricePerLbs}</div>
                                    <div style={{ marginBottom: '7px' }}>Round Lift Fee: ${props.order.roundLiftFee}</div>
                                    <div style={{ marginBottom: '7px' }}>Convenience Fee: ${props.order.convenienceFee}</div>
                                    <div style={{ marginBottom: '7px' }}>Total: ${total}</div>
                                </div>

                                {/* <div className="col-lg-3">
                                    <div className="form-outline" data-mdb-input-init>
                                        <label className="form-label" htmlFor="pricePerLbs">Price Per Lbs</label>
                                        <input required type="number" readOnly value={props.order.pricePerLbs} id="pricePerLbs" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="form-outline" data-mdb-input-init>
                                        {calTotal()}
                                    </div>
                                </div> */}
                            </div>
                            <div className="mt-2 mb-2 col-lg-6">
                                <div className="form-outline">
                                    <label className="form-label" htmlFor="pickupType">Pickup Type</label>
                                    <select id="pickupType" className="form-control" required onChange={checkRequiredField}>
                                        <option value={""}>Select One</option>
                                        <option value={"Contactless"}>Contactless</option>
                                        <option value={"With Client"}>With Client</option>
                                        <option value={"With Authorized Rep"}>With Authorized Rep.</option>
                                    </select>
                                    <small className="form-text text-muted">*If <strong>With Authorized Rep</strong> please enter Client's represantant's name in Note.</small>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-outline" data-mdb-input-init>
                                    <label className="form-label" htmlFor="note">Note</label>
                                    <textarea required={noteRequired} type="text" id="note" className="form-control">
                                    </textarea>
                                </div>
                            </div>

                            <div className="col-12" style={{ margin: "15px" }}>
                                <div dangerouslySetInnerHTML={{ __html: props.htmlBody }}></div>
                            </div>

                            <div className='col-12'>
                                <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-25">
                                    Confirm Pickup
                                </button>
                            </div>
                        </form>

                    </div>
                    <div className="modal-footer" style={{ width: 'fit-content', height: 'fit-content', margin: '9px' }}>
                        {props.noText ? (
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-mdb-dismiss="modal"
                                onClick={() => props.onNo(false)}
                            >
                                {props.noText}
                            </button>) : null}
                        {/* {props.okText ? (
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => props.onOk(false)}
                            >
                                {props.okText}
                            </button>) : null} */}
                    </div>
                </div>
            </div>
        </div>
    )
}

const NewClient = (props) => {


    const createAccount = async (event) => {
        event.preventDefault();

        try {
            var data = {
                email: event.target.elements.email.value,
                password: event.target.elements.password.value,
                confirmpassword: event.target.elements.confirmpassword.value,
                lastname: event.target.elements.lastname.value,
                firstname: event.target.elements.firstname.value,
                street: event.target.elements.street.value,
                city: event.target.elements.city.value,
                zip: event.target.elements.zip.value,
                state: 'PA',
                type: 1
            }

            createUser(data.email, data.password).then((res) => {
                console.log("createAccountRes: ", res);
                if (res.status) {
                    addOrUpdateDoc('users', res.data.uid, { ...data, uid: res.data.uid, accessToken: res.data.accessToken }).then((docres) => {
                        if (docres.status) {
                            console.log("data: ", data);
                            props.onOk({ ...data, uid: res.data.uid, accessToken: res.data.accessToken })
                        }
                    })
                } else {
                    alert(res.msg);
                }
            });
        } catch (error) {
            console.error('Error signing up: ', error);
            alert(error.message);
        }
    }

    return (

        <div
            style={{ display: 'flex', flexDirection: 'column', position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', justifyContent: 'center', zIndex: 999, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
            <div style={{ width: '90%', margin: '5%', overflow: 'auto', padding: '15px' }}>
                <div style={{ width: 'fit-content', height: 'fit-content', backgroundColor: 'white', borderRadius: '7px', margin: 'auto', padding: '15px' }}>
                    <div className="modal-header" style={{ width: 'fit-content', height: 'fit-content', margin: '9px' }}>

                        <button
                            type="button"
                            className="btn-close"
                            data-mdb-dismiss="modal"
                            aria-label="Close"
                            style={{ marginRight: '8px' }}
                            onClick={() => props.toggleModal(false)}
                        ></button>
                        <h5 className="modal-title" id="modalBoxLabel">{props.title}</h5>
                    </div>
                    <div className="modal-body" style={{ width: 'fit-content', height: 'fit-content', margin: '9px' }}>

                        <form onSubmit={createAccount} className="row">
                            <h6 className='mt-25 mb-10'>Please Enter Your Info</h6>
                            <div className="col-lg-6">
                                <div className="form-outline" data-mdb-input-init>
                                    <label className="form-label" htmlFor="firstname">FirstName</label>
                                    <input required type="text" id="firstname" className="form-control" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-outline" data-mdb-input-init>
                                    <label className="form-label" htmlFor="lastname">LastName</label>
                                    <input required type="text" id="lastname" className="form-control" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-outline" data-mdb-input-init>
                                    <label className="form-label" htmlFor="email">Email</label>
                                    <input required type="text" id="email" className="form-control" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-outline" data-mdb-input-init>
                                    <label className="form-label" htmlFor="password">Create Password</label>
                                    <input required type="password" id="password" className="form-control" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-outline" data-mdb-input-init>
                                    <label className="form-label" htmlFor="confirmpassword">Confirm Password</label>
                                    <input required type="password" id="confirmpassword" className="form-control" />
                                </div>
                            </div>
                            <div className='row'>
                                <h6 className='mt-25 mb-10'>Your Pennesylvania Address</h6>
                                <div className="col-lg-12">
                                    <div className="form-outline" data-mdb-input-init>
                                        <label className="form-label" htmlFor="street">Street</label>
                                        <input required type="text" id="street" className="form-control" />
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="form-outline" data-mdb-input-init>
                                        <label className="form-label" htmlFor="city">City</label>
                                        <input required type="text" id="city" className="form-control" />
                                        <small>*We serve citys in Delaware County and Phyladelphia</small>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="form-outline" data-mdb-input-init>
                                        <label className="form-label" htmlFor="zip">Zip</label>
                                        <input required type="number" id="zip" className="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12'>
                                <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-25">Next</button>
                            </div>
                        </form>

                    </div>
                    <div className="modal-footer" style={{ width: 'fit-content', height: 'fit-content', margin: '9px' }}>
                        {props.noText ? (
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-mdb-dismiss="modal"
                                onClick={() => props.onNo(false)}
                            >
                                {props.noText}
                            </button>) : null}
                        {/* {props.okText ? (
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => props.onOk(false)}
                            >
                                {props.okText}
                            </button>) : null} */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Admin_Panel;