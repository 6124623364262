import React, { Component } from "react";
import Slider from 'react-slick';



export class Home3_banner extends Component {
    state = {
        show: false,
      }
      render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [{
  
            breakpoint: 1199,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1
            }
        },
        {
      
            breakpoint: 1024,
            settings: {
            slidesToShow: 1,
            slidesToScroll: 1
            }
        },
        {
      
            breakpoint: 680,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
               
        return (
                
            <Slider className="slick_slider banner_slider banner_slider_3" {...slick_slider} slidesToShow={1} autoplay={false} >
                <div className="slide">
                    <div className="slide_img" style={{ backgroundImage: 'url(./images/slider/slider-main-bg-4.jpg' }}></div>
                    <div className="slide__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 no-gutters">
                                    <div className="slide__content--headings d-block text-md-end text-center">
                                        <div className="padding_top10 padding_bottom10">
                                            <div data-animation="fadeIntop" className="fade-main  text-theme-WhiteColor animated fadeIntop">
                                                <div className="slider-content-head-3">
                                                    <h3>$22.00</h3><p>/Start from</p>
                                                </div>
                                            </div>
                                            <h2  data-animation="fadeInDown" className="text-md-end text-theme-WhiteColor"> Dry cleaning</h2>
                                            <h2  data-animation="fadeInDown" className="text-md-end text-theme-WhiteColor"> Just got  <span className="webkit-content"> [Easier]</span> </h2>
                                            <div className="mt-50 banner-3-button text-end res-767-margin_top20" data-animation="fadeInUp" data-delay="1.4">
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor" href={'/Pricing'}>View Pricing Plan</a> 
                                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-white ml-20"  href={'/Contact_01'}>Contact Us</a> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="slide">
                    <div className="slide_img" style={{ backgroundImage: 'url(./images/slider/slider-main-bg-4.jpg' }}></div>
                    <div className="slide__content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 no-gutters">
                                    <div className="slide__content--headings d-block text-md-end text-center">
                                        <div className="padding_top10 padding_bottom10">
                                            <h2  data-animation="fadeInDown" className="text-md-start text-theme-WhiteColor"> Always on time,</h2>
                                            <h2 data-animation="fadeInDown" className="text-md-start text-theme-WhiteColor"><span className="webkit-content"> [Always]</span> clean</h2>
                                            <div className="banner-3-button text-start res-767-margin_top20" data-animation="fadeInUp" data-delay="1.4">
                                                <div className="heading-icon-3">
                                                    <i className="fa fa-check-circle"></i>
                                                    <div className="slide-icon-content">    
                                                        <h3>24/7 Support</h3>
                                                        <div className="slide-example">
                                                        </div>
                                                    </div>
                                                    <i className="fa fa-check-circle"></i>
                                                    <div className="slide-icon-content">    
                                                        <h3>Offers & Cares </h3>
                                                        <div className="slide-example">
                                                        </div>
                                                    </div>
                                                </div>
                                                <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-border ttm-btn-color-white mt-30">CONTACT US</a> 
                                                <div className="ttm-icon ttm-icon_element-size-md ttm-icon_element-color-skincolor mb-0 text-theme-WhiteColor">   
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>              
            </Slider>
            
        )
    }
}

export default Home3_banner;