import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import Slider from 'react-slick';

export class Carrer_01 extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
          photoIndex: 0,
          isOpen: false,
        };
      }
        render() {
            var slick_slider = {
              dots: false,
              arrow: false,
              autoplay: true,
              infinite: true,
              speed: 1000,
              slidesToShow: 4,
              slidesToScroll: 1,
              rows: 1,
      
              responsive: [{
          
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
            };
              var slick_slider_ltr = {
              infinite: true,
              arrows: false,
              dots: false,                   
              autoplay: true,
              centerMode : false,
              autoplaySpeed: 0,
              speed: 9000,
              cssEase: "linear",
              pauseOnHover: true,
              slidesToShow: 3,
              slidesToScroll: 1,
              }
      
              var slick_slider_rtl = {
              infinite: true,
              arrows: false,
              dots: false,                   
              autoplay: true,
              centerMode : false,
              rtl: true,
              autoplaySpeed: 0,
              speed: 9000,
              cssEase: "linear",
              pauseOnHover: true,
              slidesToShow: 3,
              slidesToScroll: 1,
              };
        
        return (
            <div className="site-main">
                <Header />

                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Our team</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Our team</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>

                <section className="ttm-row team-section_1 clearfix">
                    <div className="container">
                        <div className="row" data-aos="fade-up" data-aos-duration="2000">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="featured-imagebox featured-imagebox-team style1">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-1-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>manager</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details'}>Jack Martin</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="featured-imagebox featured-imagebox-team style1">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-1-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>Organizer</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details'}>Reshta Wann</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="featured-imagebox featured-imagebox-team style1">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-1-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>Team lead</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details'}>Joel Lou</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="featured-imagebox featured-imagebox-team style1">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-1-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>manager</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details'}>Jessica Brown</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="featured-imagebox featured-imagebox-team style1 mt-0">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-1-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>manager</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details'}>Charles Altman</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="featured-imagebox featured-imagebox-team style1 mt-0">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-1-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>Team lead</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details'}>David Gray</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="featured-imagebox featured-imagebox-team style1 mt-0">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-1-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>manager</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details'}>Juliana SIlva</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="featured-imagebox featured-imagebox-team style1 mt-0">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-1-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>Team lead</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details'}>Fumiko Lebron</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                </section>

                <section className="ttm-row marque-section bg-theme-DarkColor clearfix">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 ttm-marquebox-wrapper style1">
                                <ul role="list" className="marquebox-list">
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Dry cleaning</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Alterations & repairs</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Commercial laundry</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Laundry services</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Alterations & repairs</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Laundry services</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Commercial laundry</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row services-section-2 bg-theme-GreyColor bg-img1 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
                    <div className="container">
                        {/* row */}
                        <div className="row" data-aos="zoom-in-up" data-aos-duration="3000">
                            <div className="col-xl-4 col-md-6 pt-lg-30">
                                {/* section title */}
                                <div className="section-title style1 pt-lg-0 mt-md-30">
                                    <div className="title-header">
                                        <h3>Cleaning Excellence!</h3>
                                        <h2 className="title">Offering expanded disinfection services</h2>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                            <div className="col-xl-2 col-md-6 pt-lg-30">
                                <div className="ttm_single_image-wrapper">
                                    <img src="./images/single-img-189x185.png" />
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-6 pt-50 pl-30">
                                <p>Providing the best possible approach to garment for care and superior service by using 
                                the latest dry cleaning and drying technology.</p>
                            </div>
                            <div className="col-xl-2 col-md-6">
                                <div className="text-center">
                                    <a href={'/About_us'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark mt-65"> Explore offers </a>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </section>   


                <Footer/>

            </div>
        )
    }
}


export default Carrer_01;