import React, { Component } from 'react';
import Slider from 'react-slick';
import ProgressBar from 'react-animated-progress-bar';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';


export class Services extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    render() {
      var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [ {

            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },{
    
          breakpoint: 778,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 575,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }]
      };
      var slick_slider_ltr = {
        infinite: true,
        arrows: false,
        dots: false,                   
        autoplay: true,
        centerMode : false,
        autoplaySpeed: 0,
        speed: 9000,
        cssEase: "linear",
        pauseOnHover: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        }

        var slick_slider_rtl = {
        infinite: true,
        arrows: false,
        dots: false,                   
        autoplay: true,
        centerMode : false,
        rtl: true,
        autoplaySpeed: 0,
        speed: 9000,
        cssEase: "linear",
        pauseOnHover: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        };
        
        return (       
          <div className="site-main">

            <Header/>
            
            <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h2 className="title">Services</h2>
                            </div>{/* /.page-title-captions */}
                            <div className="breadcrumb-wrapper">
                            <span>
                                <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                            <span>Services</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                </div>{/* /.container */}                      
            </div>  

            <section className="ttm-row services-section-icon clearfix">
                <div className="container">
                    <div className="row" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000">
                        <div className="col-lg-6 align-self-center">
                            {/* section title */}
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>What Were Offering</h3>
                                    <h2 className="title">Discover all our <span className='webkit-content'>[Services]</span></h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="featured-icon-box style4">
                                <div className="featured-icon">
                                    <i className="flaticon-laundry"></i>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Laundry service</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>It's hard to keep your curtains clean and wrinkle-free.</p>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Alterations_repairs'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="featured-icon-box style4">
                                <div className="featured-icon">
                                    <i className="flaticon-drying"></i>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Dry cleaning</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Get exceptional dry cleaning solutions at affordable pricing</p>
                                    </div>
                                    <div className="ttm-iconbox">
                                    <a href={'/Alterations_repairs'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="featured-icon-box style4">
                                <div className="featured-icon">
                                    <i className="flaticon-steam-iron"></i>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Steam iron</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Dedicated staff provides consistent, superior cleaning</p>
                                    </div>
                                    <div className="ttm-iconbox">
                                    <a href={'/Alterations_repairs'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="featured-icon-box style4">
                                <div className="featured-icon">
                                    <i className="flaticon-laundry-basket"></i>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Commercial laundry</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Experts in top quality that meets all the exacting standards.</p>
                                    </div>
                                    <div className="ttm-iconbox">
                                    <a href={'/Alterations_repairs'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="featured-icon-box style4">
                                <div className="featured-icon">
                                    <i className="flaticon-washing-clothes"></i>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Wash and fold</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Try our high quality dry cleaning service or skip the selection!</p>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Contact_01'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="featured-imagebox featured-imagebox-service style2">
                                <div className="featured-image-box-wrapper">
                                    <div className="featured-thumbnail">
                                        <img width="33" height="33" src="./images/whats-up.png" />
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3><a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                        </div>
                                    </div>
                                </div>
                                <h2>Call us for all your laundry needs</h2>
                                <div className="ttm-iconbox">
                                    <a href={'/Alterations_repairs'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark"> Contact Us </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row services-section-1 bg-theme-GreyColor bg-img1 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
                <div className="container">
                    {/* row */}
                    <Slider className="row slick_slider slick-dots-style1" {...slick_slider} slidesToShow={1}arrows={false}
                    dots={true}autoplay={false} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 1,slidesToScroll: 1 }},
                    {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] } >
                        <div className="col-md-12" data-aos-duration="2000" data-aos="fade-up">
                            <div className="featured-imagebox featured-imagebox-service style1">
                                <div className="ttm-top-imagebox ttm-wrap-cell">
                                    <div className="featured-thumbnail">
                                        <img src="./images/single-img-3-583x494.png" />
                                    </div>
                                </div>
                                <div className="featured-content ttm-wrap-cell">
                                    <div className="process-num">
                                        <span className="number">01</span>
                                    </div>
                                    <div className="featured-title">
                                        <h2>We pick up your valuable <span className='webkit-content'>[Clothes]</span></h2>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Our delivery services work no matter where you are. Do you need your clothes
                                            dropped off at your place of work? We can do that for you. Well drop it off
                                            at your house, apartment, or even at your hotel.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="featured-imagebox featured-imagebox-service style1">
                                <div className="ttm-top-imagebox ttm-wrap-cell">
                                    <div className="featured-thumbnail">
                                        <img src="./images/single-img-3-583x494.png" />
                                    </div>
                                </div>
                                <div className="featured-content ttm-wrap-cell">
                                    <div className="process-num">
                                        <span className="number">02</span>
                                    </div>
                                    <div className="featured-title">
                                        <h2>Pack up your valuable <span className='webkit-content'>[Clothes]</span></h2>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Pack the item in a disposable bag, use one bag per service, our driver will
                                            transfer them to a reusable laundry heap bag that you can save for your next
                                            order, pack one bag per service, no need to count or weight the items,
                                            efficient water use.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="featured-imagebox featured-imagebox-service style1">
                                <div className="ttm-top-imagebox ttm-wrap-cell">
                                    <div className="featured-thumbnail">
                                        <img src="./images/single-img-3-583x494.png" />
                                    </div>
                                </div>
                                <div className="featured-content ttm-wrap-cell">
                                    <div className="process-num">
                                        <span className="number">03</span>
                                    </div>
                                    <div className="featured-title">
                                        <h2>Wait for driver to pick up <span className='webkit-content'>[Clothes]</span></h2>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Get notified when your driver is nearby. They will pick up your bag and take
                                         it to a local cleaning facility. Regular order updates, live driver tracking,
                                         zero-emission delivery vehicles, trusted local cleaners, and convenient laundry
                                         solutions those help save the environment.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="featured-imagebox featured-imagebox-service style1">
                                <div className="ttm-top-imagebox ttm-wrap-cell">
                                    <div className="featured-thumbnail">
                                        <img src="./images/single-img-3-583x494.png" />
                                    </div>
                                </div>
                                <div className="featured-content ttm-wrap-cell">
                                    <div className="process-num">
                                        <span className="number">04</span>
                                    </div>
                                    <div className="featured-title">
                                        <h2>We take care of your <span className='webkit-content'>[Laundry]</span></h2>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Items will be meticulously cleaned by a local partner facility.
                                            Our driver will bring them whenever you want. You are in complete control of your deliveries and can
                                            reschedule at any time if you are not home.24 hour processing time,
                                            real-time order status and easy rescheduling
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>

            <section className="ttm-row padding_top_zero-section marque-section_1 bg-theme-SkinColor overflow-hidden">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 ttm-marquebox-wrapper style3">
                            <ul role="list" className="marquebox-list">
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Dry cleaning</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Alterations & repairs</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Commercial laundry</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Laundry services</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Alterations & repairs</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Laundry services</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Commercial laundry</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="ttm-row padding_bottom_zero-section clearfix" data-aos="fade-up" data-aos-duration="2000">
                <div className="container-fluid">
                    
                    <div className="row">
                        <div className="col-lg-12 pl-80 pr-80 pl-lg-0 pr-lg-0">
                            <div className="section-title title-style-center_text mb-0">
                                <div className="title-header pb-0">
                                    <h2 className="title">What they <span className="webkit-content">[Say ?]</span></h2>
                                </div>
                            </div>
                            <Slider className="row slick_slider slick-arrows-style1 ttm-boxes-spacing-10px" {...slick_slider} slidesToShow={3}arrows={true}
                            autoplay={false} responsive= { [{ breakpoint: 1199, settings: {slidesToShow: 2,slidesToScroll: 2 }},
                            {breakpoint: 991, settings: { slidesToShow: 2, slidesToScroll: 1 }} ,
                            {breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                            <div className="col-lg-12">
                                <div className="testimonials style1 text-center"> 
                                    <div className="testimonial-content">
                                        <blockquote>My suits came back quickly and felt newer than the day I bought them great
                                        that was easy to use,best part they came to pick them up and drop them off for me.</blockquote>
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" />
                                            </div>
                                        </div>
                                        <div className="testimonial-caption">
                                            <label>Charles Altman</label>                                             
                                            <p>Chairman and founder</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="testimonials style1 text-center"> 
                                    <div className="testimonial-content">
                                        <blockquote>This service was very helpful and convenient. The guy came out and 
                                        picked up the laundry for dry cleaning. Good price compared to most of Miami.</blockquote>
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" />
                                            </div>
                                        </div>
                                        <div className="testimonial-caption">
                                            <label>Peter Daniels</label>                                             
                                            <p>Businessman</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="testimonials style1 text-center"> 
                                    <div className="testimonial-content">
                                        <blockquote>My suits came back quickly and felt newer than the day I bought them great
                                        that was easy to use,best part they came to pick them up and drop them off for me.</blockquote>
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" />
                                            </div>
                                        </div>
                                        <div className="testimonial-caption">
                                            <label>Judy Wells</label>                                             
                                            <p>Chairman and founder</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="testimonials style1 text-center"> 
                                    <div className="testimonial-content">
                                        <blockquote>The app is so straightforward and easy to follow. Excellent service
                                        and quality, prices than many dry cleaners around this area. I clean all my 
                                        clothes with them.</blockquote>
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" />
                                            </div>
                                        </div>
                                        <div className="testimonial-caption">
                                            <label>Joel Elliott</label>                                             
                                            <p>Businessman</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </Slider>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <Slider className="row pt-15 pb-15 slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={6}arrows={false}
                    autoplay={true} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 4,slidesToScroll: 2 }},
                        {breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 }} ,
                    {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-1-163x31.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-2-128x25.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-1-163x31.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-2-128x25.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-1-163x31.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-2-128x25.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-2-128x25.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section> */}


            <Footer/>
                        
         </div>
       )
   }
}


export default Services;