import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Slider from 'react-slick';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import ProgressBar from 'react-animated-progress-bar';
import CountUp from 'react-countup';


export class Blog_grid_view extends Component {

    constructor() {
        super();
        this.state = {
          name: "React"
        };
        this.onChangeValue = this.onChangeValue.bind(this);

    }
    
    onChangeValue(event) {
        console.log(event.target.value);
    }

    render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [{
      
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        var slick_slider_ltr = {
            infinite: true,
            arrows: false,
            dots: false,                   
            autoplay: true,
            centerMode : false,
            autoplaySpeed: 0,
            speed: 9000,
            cssEase: "linear",
            pauseOnHover: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            }
    
            var slick_slider_rtl = {
            infinite: true,
            arrows: false,
            dots: false,                   
            autoplay: true,
            centerMode : false,
            rtl: true,
            autoplaySpeed: 0,
            speed: 9000,
            cssEase: "linear",
            pauseOnHover: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            };
        return (

            <div className="site-main">
                <Header/>
            
                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Blog Grid View</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Blog Grid View</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>

                <section className="ttm-row grid-section ttm-bgcolor-grey clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-blog style2">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/blog/blog-7-650x433.jpg" />
                                    </div>{/* featured-thumbnail end */}
                                    <div className="featured-content">
                                        <div className="post-meta">
                                            <span className="ttm-meta-line byline"><a href={'/Blog_details'}>Sanitizing</a></span>
                                            <span className="ttm-meta-line category-link">03 Dec 2022</span>
                                        </div>
                                        <div className="featured-title">
                                            <h3><a href={'/Blog_details'}>We wash shirts and pantsdrying with the best detergent</a></h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href="Single-Case-Studies.html"></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-blog style2">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/blog/blog-7-650x433.jpg" />
                                    </div>{/* featured-thumbnail end */}
                                    <div className="featured-content">
                                        <div className="post-meta">
                                            <span className="ttm-meta-line byline"><a href={'/Blog_details'}>Cleaning, supplies</a></span>
                                            <span className="ttm-meta-line category-link">02 Dec 2022</span>
                                        </div>
                                        <div className="featured-title">
                                            <h3><a href={'/Blog_details'}>The definitive guide to natural laundry detergents</a></h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href="Single-Case-Studies.html"></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-blog style2">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/blog/blog-7-650x433.jpg" />
                                    </div>{/* featured-thumbnail end */}
                                    <div className="featured-content">
                                        <div className="post-meta">
                                            <span className="ttm-meta-line byline"><a href={'/Blog_details'}>Sanitizing</a></span>
                                            <span className="ttm-meta-line category-link">09 Nov 2022</span>
                                        </div>
                                        <div className="featured-title">
                                            <h3><a href={'/Blog_details'}>10 reasons to hire professional dry cleaner services</a></h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href="Single-Case-Studies.html"></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-blog style2">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/blog/blog-7-650x433.jpg" />
                                    </div>{/* featured-thumbnail end */}
                                    <div className="featured-content">
                                        <div className="post-meta">
                                            <span className="ttm-meta-line byline"></span>
                                            <span className="ttm-meta-line category-link">08 Nec 2022</span>
                                        </div>
                                        <div className="featured-title">
                                            <h3><a href={'/Blog_details'}>We wash clothes with the best detergent</a></h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href="Single-Case-Studies.html"></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-blog style2">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/blog/blog-7-650x433.jpg" />
                                    </div>{/* featured-thumbnail end */}
                                    <div className="featured-content">
                                        <div className="post-meta">
                                            <span className="ttm-meta-line byline">Supplies</span>
                                            <span className="ttm-meta-line category-link">07 Nov 2022</span>
                                        </div>
                                        <div className="featured-title">
                                            <h3><a href={'/Blog_details'}>Wedding suit deserves special treatment in dry cleaning</a></h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href="Single-Case-Studies.html"></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-blog style2">
                                    {/* featured-thumbnail */}
                                    <div className="featured-thumbnail">
                                        <img src="./images/blog/blog-7-650x433.jpg" />
                                    </div>{/* featured-thumbnail end */}
                                    <div className="featured-content">
                                        <div className="post-meta">
                                            <span className="ttm-meta-line byline">Sanitizing</span>
                                            <span className="ttm-meta-line category-link">01 Nov 2022</span>
                                        </div>
                                        <div className="featured-title">
                                            <h3><a href={'/Blog_details'}>How to clean your woolens and cashmere items in your home</a></h3>
                                        </div>
                                    </div>
                                    <div classclassName="ttm-iconbox">
                                        <a href="Single-Case-Studies.html"></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                
            <Footer/>
                        
            </div>
          )
      }
   }

export default Blog_grid_view;