import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export class Blog_details extends Component {
    render() {
        return (

            <div className="site-main">
                <Header/>
            
                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Blog Details</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Blog Details</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>


                <div className="sidebar ttm-sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">                            
                            <div className="col-lg-8 content-area border-right pr-45 pr-lg-15 pt-lg-50 pb-lg-40">
                                {/* post */}
                                <article className="post ttm-blog-single clearfix">
                                    {/* post-featured-wrapper */}
                                    <div className="ttm-blog-single-content">
                                        <div className="ttm-post-entry-header">
                                            <div className="post-meta">
                                                <span className="ttm-meta-line byline"> <a href={'/Blog'}>Cleaning</a></span>
                                                <span className="ttm-meta-line entry-date">November 8, 2022</span>
                                                <span className="ttm-meta-line tags-links">0 Comments</span>
                                            </div>  
                                        </div>
                                        <div className="ttm-post-featured-wrapper ttm-featured-wrapper">
                                            <div className="ttm-post-featured mt-30">
                                            <img className="border-radius-8" src="./images/blog/blog-14-833x421.jpg" />
                                            </div>
                                        </div>{/* post-featured-wrapper end */}
                                        {/* ttm-blog-classic-content */}
                                        <div className="entry-content">
                                            <div className="ttm-box-desc-text mt-30">
                                                <p>It’s easy to see that you have a dirty surface that needs cleaning. Figuring out which cleanser to use
                                                can be difficult! Choosing the right product from the start will make your cleaning job easier, faster
                                                and more efficient. So how do you know which detergent to use. Absolutely not! All floors are different
                                                and need to be handled properly.</p>
                                                <blockquote>
                                                    <div className="qoute-text">I really love this Laundromat and appreciate the workers and all that
                                                    they do to keep the store running smoothly with its great staff and even in this modern day.</div>
                                                    <cite>-John martin</cite>
                                                    <p className="cite-righttext">
                                                        <i className="fa fa-star"></i>
                                                        8.5/10
                                                    </p>
                                                </blockquote>
                                                <h3 className="fs-24 fw-700">List overview</h3>
                                                <p>Laundry business has unlimited potential to generate huge profits, you need to do everything right to
                                                create a detailed laundromat business plan that will help you along the way.</p>
                                                <div className="d-md-flex align-items-center mb-20">``
                                                    <div className="pt-10 pb-15">
                                                        <div className="row">
                                                            <div className="col-md-6">
                                                                <img className="border-radius-8" src="./images/blog/blog-15-400x244.png" />
                                                            </div>
                                                            <div className="col-md-6 align-self-center pt-md-30">
                                                                <ul className="ttm-list ttm-list-style-icon ttm-list-icon-color-skincolor">
                                                                    <li><i className="flaticon-read"></i><div className="ttm-list-li-content">We wash clothes with the best detergents</div></li>
                                                                    <li><i className="flaticon-read"></i><div className="ttm-list-li-content">Survived not only five centuries</div></li>
                                                                    <li><i className="flaticon-read"></i><div className="ttm-list-li-content">Satisfaction is our top priority</div></li>
                                                                    <li><i className="flaticon-read"></i><div className="ttm-list-li-content">Pressure washing, but what really matters</div></li>
                                                                    <li><i className="flaticon-read"></i><div className="ttm-list-li-content">We’re insured and reliable</div></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="social-media-block">
                                                    <div className="d-sm-flex justify-content-between">
                                                        <div className="ttm_tag_lists mt-15">
                                                            <span className="ttm-tags-links-title">Tags:</span>
                                                            <a href={'/Blog'} rel="tag">Laundry</a>
                                                            <a href={'/Blog'} rel="tag">Washing</a>
                                                        </div>
                                                        <div className="ttm-social-share-wrapper mt-15">
                                                            <ul className="social-icons">
                                                                <li className="social-facebook">
                                                                    <a target="_blank" href="https://www.facebook.com/preyantechnosys19"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                                                </li>
                                                                <li className="social-twitter">  
                                                                    <a target="_blank" href="https://twitter.com/PreyanTechnosys"><i className="fab fa-twitter" aria-hidden="true"></i></a>
                                                                </li>
                                                                <li className="social-pinterest">
                                                                    <a target="_blank" href="https://dribbble.com/PreyanTechnosys"><i className="fab fa-dribbble" aria-hidden="true"></i></a>
                                                                </li>
                                                                <li className="social-instagram">
                                                                    <a target="_blank" href="https://www.instagram.com/preyan_technosys/"><i className="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="separator">
                                                    <div className="sep-line mt-50 mb-50"></div>
                                                </div>
                                                <div className="ttm-tabs ttm-tab-style-02">
                                                    <Tabs>
                                                        <TabList className="tabs">
                                                            <Tab className="tab">
                                                                <a>
                                                                <i className="flaticon flaticon-research"></i>
                                                                    <h3 className="fs-36 fw-700">Leave a Reply</h3>
                                                                    <p className="mb-20 mt-20">Your email address will not be published. Required fields are marked *</p>
                                                                </a>
                                                            </Tab>
                                                        </TabList> 
                                                        <div className="content-tab">                                
                                                            <TabPanel>
                                                                <form id="login_form" className="login_form wrap-form">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <label>
                                                                            <textarea name="message" rows="4" placeholder="YourComment" required=""></textarea>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <label>
                                                                                <input type="text" id="txtname" placeholder="Your Name" />
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <label>
                                                                                <input type="email" id="txtemail" placeholder="Email Address" />
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-lg-4">
                                                                            <label>
                                                                                <input type="password" id="password" placeholder="Phone Number"/>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="text-left">
                                                                                <button type="submit" id="submit" className="mt-10 submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" value>
                                                                                    Submit Quote
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </TabPanel>
                                                            <TabPanel>
                                                                <form id="login_form" className="login_form wrap-form">
                                                                    <div className="row">
                                                                        <div className="col-lg-6">
                                                                            <label>
                                                                                <i className="ti ti-user"></i>
                                                                                <input type="text" id="txtname" placeholder="Username" />
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <label>
                                                                                <i className="ti ti-email"></i>
                                                                                <input type="email" id="txtemail" placeholder="Email Address" />
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <label>
                                                                                <i className="ti ti-lock"></i>
                                                                                <input type="password" id="password" placeholder="Password"/>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-lg-6">
                                                                            <label>
                                                                                <i className="ti ti-lock"></i>
                                                                                <input type="password" id="cpassword" placeholder="Confirm Password *"/>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-lg-12">
                                                                            <label>
                                                                                <i className="ti ti-mobile"></i>
                                                                                <input type="tel" id="txtphone" placeholder="Phone Number"/>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-lg-12">
                                                                            <label>
                                                                                <div className="d-md-flex justify-content-between">
                                                                                    <div className="cookies-checkbox mt-5">
                                                                                        <div className="d-flex flex-row align-items-center justify-content-start">
                                                                                            <input className="w-auto mr-10" id="cookies-consent" name="cookies-consent" type="checkbox" value="yes" />
                                                                                            <span>you accept our Terms and Conditions and Privacy Policy</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mt-15">
                                                                                        <p>Already have an account?</p>
                                                                                    </div>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                        <div className="col-lg-12">
                                                                            <label>
                                                                                <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                                                                type="submit">Sign up</button>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </TabPanel>
                                                        </div>       
                                                    </Tabs>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* ttm-blog-classic-content end */}
                                </article>{/* post end */}
                            </div>
                            <div className="col-lg-4 widget-area sidebar-left pl-45 pl-lg-15">
                                <div className="sidebar-right">
                                    <aside className="widget widget-search">
                                        <h3 className="widget-title">Categories</h3>
                                        <form role="search" className="search-form">
                                            <label>
                                                <span className="screen-reader-text">Search for:</span>
                                                <input type="search" className="input-text" placeholder="Search...." value="" />
                                            </label>
                                            <button className="btn" type="submit"><i className="fa fa-search"></i></button>
                                        </form>
                                    </aside>
                                    <aside className="widget widget-nav-menu">
                                        <h3 className="widget-title">Categories</h3>
                                        <ul>
                                            <li className="d-flex">Cleaning (2)</li>
                                            <li className="d-flex">Cleaning (3)</li>
                                            <li className="d-flex">Cleaning (2)</li>
                                        </ul>
                                    </aside>
                                    <aside className="widget widget-recent-post with-title">
                                        <h3 className="widget-title">Recent Posts</h3>
                                        <ul>
                                            <li>
                                                <a href={'/Blog_details'}>
                                                    <img width="180" height="80" src="./images/blog/blog-11-1200x800.jpg" />
                                                </a>    
                                                <div className="post-detail">
                                                    <span className="post-date">October 10, 2020</span>
                                                    <a href={'/Blog_details'}>We wash shirts and pantsdrying with the best detergent</a>       
                                                </div>
                                            </li>
                                            <li>
                                                <a href={'/Blog_details'}>
                                                    <img width="145" height="80" src="./images/blog/blog-9-835x422.jpg" />
                                                </a>    
                                                <div className="post-detail">
                                                <span className="post-date">October 10, 2020</span>
                                                    <a href={'/Blog_details'}>The definitive guide to natural laundry detergents</a>
                                                </div>
                                            </li>
                                            <li>
                                                <a href={'/Blog_details'}>
                                                    <img width="145" height="80" src="./images/blog/blog-10-835x422.jpg" />
                                                </a>    
                                                <div className="post-detail">
                                                <span className="post-date">October 10, 2020</span>
                                                    <a href={'/Blog_details'}>10 reasons to hire professional dry cleaner services</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </aside>
                                    <aside className="widget tagcloud-widget with-title">
                                        <h3 className="widget-title">Popular Tags</h3>
                                        <div className="tagcloud">
                                            <a to={'/Blog'} className="tag-cloud-link">Cleaning</a>
                                            <a to={'/Blog'} className="tag-cloud-link">Dry cleaning</a>
                                            <a to={'/Blog'} className="tag-cloud-link">Laundry</a>
                                            <a to={'/Blog'} className="tag-cloud-link">Steam Ironing</a>
                                            <a to={'/Blog'} className="tag-cloud-link">Washing</a>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

            <Footer/>
                        
            </div>
        )
      }
   }

export default Blog_details;