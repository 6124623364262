import React, {Component} from 'react';

class Logo extends Component {
    render() {
        return (

            <a className="home-link" href={'/'} title="Laundrolift" rel="home">
                <img width="185" height="50" id="logo-img" className="img-fluid" alt="logo" src="images/logo-img.svg" />
            </a>
            );
    }
}

export default Logo;
