import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

export default function Modal(props) {

    useEffect(() => {

        console.log("dialog props: ", props)

    }, [props]);

    return (
        <div
            style={{ display: 'flex', flexDirection: 'column', position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', justifyContent: 'center', zIndex: 999, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        >
            <div style={{ width: '90%', margin: '5%', overflow: 'auto', padding: '15px' }}>
                <div style={{ width: 'fit-content', height: 'fit-content', backgroundColor: 'white', borderRadius: '7px', margin: 'auto', padding: '15px' }}>
                    <div className="modal-header" style={{ width: 'fit-content', height: 'fit-content', margin: '9px' }}>
                        
                        <button
                            type="button"
                            className="btn-close"
                            data-mdb-dismiss="modal"
                            aria-label="Close"
                            style={{marginRight: '8px'}}
                            onClick={() => props.toggleModal(false)}
                        ></button>
                        <h5 className="modal-title" id="modalBoxLabel">{props.title}</h5>
                    </div>
                    <div className="modal-body" style={{ width: 'fit-content', height: 'fit-content', margin: '9px' }}>
                        <div dangerouslySetInnerHTML={{ __html: props.htmlBody }}></div>
                    </div>
                    <div className="modal-footer" style={{ width: 'fit-content', height: 'fit-content', margin: '9px' }}>
                        {props.noText ? (
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-mdb-dismiss="modal"
                                onClick={() => props.onNo(false)}
                            >
                                {props.noText}
                            </button>) : null}
                        {props.okText ? (
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={() => props.onOk(false)}
                            >
                                {props.okText}
                            </button>) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

Modal.propTypes = {
    toggleModal: PropTypes.func,
    title: PropTypes.string,
    htmlBody: PropTypes.string,
    onNo: PropTypes.func,
    onOk: PropTypes.func,
    noText: PropTypes.string,
    okText: PropTypes.string
};

// Modal.defaultProps = {
//     toggleModal: () => { },
//     title: "",
//     htmlBody: "",
//     onNo: () => { },
//     onOk: () => { },
//     noText: "",
//     okText: ""
// };
