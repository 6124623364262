import axios from 'axios';

const api = {
    sendEmail: (data, idToken) => {
        return axios.post('/send-email', data, {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }).then((res) => {
            return res;
        })
    },

    getStripeK: (idToken) => {
        return axios.get('/spk', {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }).then((res) => {
            return res;
        })
    },

    getStripeS: (data, idToken) => {
        return axios.post('/sps', data, {
            headers: {
              Authorization: `Bearer ${idToken}`
            }
          }).then((res) => {
            return res;
        })
    }


}


export default api;