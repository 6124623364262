import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

export class Menu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
        }
    }


    render() {
        return (
            <Router>
                <nav className="main-menu">
                    <ul className="menu">
                        {!this.props.user ? (
                            <li className="mega-menu-item" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                <a href={this.props.user?.type == 5 ? '/admin_panel' : '/Laundry_services'}>
                                    Order service now
                                </a>
                                <a style={{marginLeft: '20px'}} href={this.props.user?.type == 5 ? '/admin_panel' : '/Laundry_services'}>
                                    Login
                                    <i className="fas fa-sign-in-alt" style={{marginLeft: '5px'}}></i>
                                </a>
                            </li>
                        ) : (
                            <li style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>

                                <a style={{marginRight: '20px'}} href={this.props.user?.type == 5 ? '/admin_panel' : '/Laundry_services'}>
                                    Welcome {this.props.user?.firstname} {this.props.user?.lastname[0]}.
                                </a>

                                <a href="#" onClick={() => this.props.logout()}>
                                    Sign out
                                    <i className="fas fa-sign-out-alt" style={{marginLeft: '5px'}}></i>
                                </a>
                            </li>
                        )}
                        {/* <li className="mega-menu-item">
                            <a href={'/Laundry_services'} className="mega-menu-link">Laundry services</a>
                        </li> */}
                        {/* <li className="mega-menu-item">
                            <a href={'/House_cleaning'} className="mega-menu-link">House cleaning</a>
                        </li> */}
                        {/* <li className="mega-menu-item">
                            <a href={'/About'} className="mega-menu-link">About Us</a>
                        </li>
                        <li className="mega-menu-item">
                            <a href={'/Contact'} className="mega-menu-link">Contact Us</a>
                        </li> */}
                        {/* <li className="mega-menu-item">
                            <a href={'/'}>Home</a> */}
                        {/* <a href={'/'} className="mega-menu-link">Home</a>
                            <ul className="mega-submenu">
                                <li><a href={'/'}>Home 01</a></li>
                                <li><a href={'/home2'}>Home 02</a></li>
                                <li><a href={'/home3'}>Home 03</a></li>
                            </ul> */}
                        {/* </li> */}
                        {/* <li className="mega-menu-item">
                            <a href={'/about_us'} className="mega-menu-link">Pages</a>
                            <ul className="mega-submenu"> */}
                        {/* <li><a href={'/about_us'}>About Us</a></li> */}
                        {/* <li><a href={'/About'}>About</a></li> */}
                        {/* <li><a href={'/contact_01'}>Contact us 01</a></li> */}
                        {/* <li><a href={'/Contact'}>Contact</a></li> */}
                        {/* <li><a href={'/Services'}>Services</a></li> */}
                        {/* <li><a href={'/services_2'}>Services 2</a></li> */}
                        {/* <li><a href={'/Our_team'}>Our Team</a></li> */}
                        {/* <li><a href={'/Team_details'}>Team Details</a></li> */}
                        {/* <li><a href={'/Pricing'}>Pricing</a></li> */}
                        {/* </ul>
                        </li> */}
                        {/* <li className="mega-menu-item">
                            <a href='#' className="mega-menu-link">Our Services</a>
                            <ul className="mega-submenu"> */}
                        {/* <li><a href={'/Alterations_repairs'}>Alterations & repairs</a></li>
                                <li><a href={'/Commercial_laundry'}>Commercial laundry</a></li>
                                <li><a href={'/dry_cleaning'}>Dry cleaning</a></li> */}
                        {/* <li><a href={'/Laundry_services'}>Laundry services</a></li>
                                <li><a href={'/House_cleaning'}>House cleaning</a></li> */}
                        {/* <li><a href={'/Steam_iron'}>Steam iron</a></li> */}
                        {/* </ul>
                        </li> */}
                        {/* <li className="mega-menu-item">
                            <a href={'/Our_case_study'} className="mega-menu-link">Case Study</a>
                            <ul className="mega-submenu">
                                <li><a href={'/Our_case_study'}>Our Case Study</a></li>
                                <li><a href={'/Case_study_details'}>Case Study Details</a></li>
                            </ul>
                        </li> */}
                        {/* <li className="mega-menu-item">
                            <a href={'/Blog'} className="mega-menu-link">Blog</a>
                            <ul className="mega-submenu">
                                <li><a href={'/Blog'}>Blog</a></li>
                                <li><a href={'/Blog_grid_view'}>Blog grid view</a></li>
                                <li><a href={'/Blog_details'}>Blog Details</a></li>
                            </ul>
                        </li>                     */}
                    </ul>
                </nav>
            </Router>
        )

    }
}

export default Menu;