const templates = {
    orderUpdate: `<!DOCTYPE HTML
    PUBLIC "-//W3C//DTD XHTML 1.0 Transitional //EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">
  
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="x-apple-disable-message-reformatting">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <title></title>
  
    <style type="text/css">
      @media only screen and (min-width: 620px) {
        .u-row {
          width: 600px !important;
        }
  
        .u-row .u-col {
          vertical-align: top;
        }
  
        .u-row .u-col-33p33 {
          width: 199.98px !important;
        }
  
        .u-row .u-col-100 {
          width: 600px !important;
        }
  
      }
  
      @media (max-width: 620px) {
        .u-row-container {
          max-width: 100% !important;
          padding-left: 0px !important;
          padding-right: 0px !important;
        }
  
        .u-row .u-col {
          min-width: 320px !important;
          max-width: 100% !important;
          display: block !important;
        }
  
        .u-row {
          width: 100% !important;
        }
  
        .u-col {
          width: 100% !important;
        }
  
        .u-col>div {
          margin: 0 auto;
        }
      }
  
      body {
        margin: 0;
        padding: 0;
      }
  
      table,
      tr,
      td {
        vertical-align: top;
        border-collapse: collapse;
      }
  
      p {
        margin: 0;
      }
  
      .ie-container table,
      .mso-container table {
        table-layout: fixed;
      }
  
      * {
        line-height: inherit;
      }
  
      a[x-apple-data-detectors='true'] {
        color: inherit !important;
        text-decoration: none !important;
      }
  
      table,
      td {
        color: #000000;
      }
  
      #u_body a {
        color: #0000ee;
        text-decoration: underline;
      }
  
      @media (max-width: 480px) {
        #u_content_image_10 .v-container-padding-padding {
          padding: 30px 10px 10px !important;
        }
  
        #u_content_image_10 .v-src-width {
          width: auto !important;
        }
  
        #u_content_image_10 .v-src-max-width {
          max-width: 19% !important;
        }
  
        #u_content_text_8 .v-container-padding-padding {
          padding: 10px 10px 30px !important;
        }
  
        #u_column_10 .v-col-background-color {
          background-color: #b90509 !important;
        }
  
        #u_column_10 .v-col-border {
          border-top: 0px solid transparent !important;
          border-left: 0px solid transparent !important;
          border-right: 0px solid transparent !important;
          border-bottom: 0px solid transparent !important;
        }
  
        #u_content_image_11 .v-container-padding-padding {
          padding: 30px 10px 10px !important;
        }
  
        #u_content_image_11 .v-src-width {
          width: auto !important;
        }
  
        #u_content_image_11 .v-src-max-width {
          max-width: 24% !important;
        }
  
        #u_content_text_9 .v-container-padding-padding {
          padding: 10px 10px 30px !important;
        }
  
        #u_content_image_12 .v-container-padding-padding {
          padding: 30px 10px 10px !important;
        }
  
        #u_content_image_12 .v-src-width {
          width: auto !important;
        }
  
        #u_content_image_12 .v-src-max-width {
          max-width: 20% !important;
        }
  
        #u_content_text_10 .v-container-padding-padding {
          padding: 10px 10px 30px !important;
        }
      }
    </style>
  
    <link href="https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap" rel="stylesheet" type="text/css">
  
  </head>
  
  <body class="clean-body u_body"
    style="margin: 0;padding: 0;-webkit-text-size-adjust: 100%;background-color: #e7e7e7;color: #000000">
  
    <table id="u_body"
      style="border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;min-width: 320px;Margin: 0 auto;background-color: #e7e7e7;width:100%"
      cellpadding="0" cellspacing="0">
      <tbody>
        <tr style="vertical-align: top">
          <td style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
  
            <div class="u-row-container" style="padding: 0px;background-color: #5482cf">
              <div class="u-row"
                style="margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;">
                <div
                  style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                  <div class="u-col u-col-100"
                    style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                    <div class="v-col-background-color" style="height: 100%;width: 100% !important;">
                      <div class="v-col-border"
                        style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;">
  
                        <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0"
                          cellspacing="0" width="100%" border="0">
                          <tbody>
                            <tr>
                              <td class="v-container-padding-padding"
                                style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                align="left">
  
                                <h1
                                  style="margin: 0px; color: #5482cf; line-height: 140%; text-align: center; word-wrap: break-word; font-family: 'Montserrat',sans-serif; font-size: 24px; font-weight: 700;">
                                  <span><span>Order Update</span></span>
                                </h1>
  
                              </td>
                            </tr>
                          </tbody>
                        </table>
  
                        <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0"
                          cellspacing="0" width="100%" border="0">
                          <tbody>
                            <tr>
                              <td class="v-container-padding-padding"
                                style="overflow-wrap:break-word;word-break:break-word;padding:30px 10px 10px;font-family:arial,helvetica,sans-serif;"
                                align="left">
  
                                <h1
                                  style="margin: 0px; line-height: 140%; text-align: center; word-wrap: break-word; font-family: 'Montserrat',sans-serif; font-size: 18px; font-weight: 700;">
                                  <span><span><span>
                                        Your order has been
                                        {{Status}}
                                      </span></span></span>
                                </h1>
  
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
  
  
  
  
            <div class="u-row-container" style="padding: 0px;background-color: #5482cf">
              <div class="u-row"
                style="margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: #ffffff;">
                <div
                  style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                  <div class="u-col u-col-100"
                    style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                    <div class="v-col-background-color"
                      style="height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                      <div class="v-col-border"
                        style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                        <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0"
                          cellspacing="0" width="100%" border="0">
                          <tbody>
                            <tr>
                              <td class="v-container-padding-padding"
                                style="padding:10px;font-family:arial,helvetica,sans-serif;" align="left">
  
                                <p style="width: 100%; padding: 10px 20px;"><i class="fas fa-user-alt" aria-hidden="true"
                                    style="margin-right: 5px;"></i>
                                  Client: {{clientName}}
                                </p>
                                <p style="width: 100%; padding: 10px 20px;"><i class="far fa-clock" aria-hidden="true"
                                    style="margin-right: 5px;"></i>
                                  Pickup Date Time: {{orderDateTime}}
                                </p>
                                <p style="width: 100%; padding: 10px 20px;"><i class="fas fa-funnel-dollar"
                                    aria-hidden="true" style="margin-right: 5px;"></i>
                                  Weight:
                                  {{weight}}
                                </p>
                                <p style="width: 100%; padding: 10px 20px;"><i class="fas fa-directions"
                                    aria-hidden="true" style="margin-right: 5px;"></i>
                                  Address: {{address}}
                                </p>
                                <p style="width: 100%; padding: 10px 20px;">
                                  <i class="fas fa-hourglass-half" aria-hidden="true" style="margin-right: 5px;"></i>
                                  Status: {{Status}}
  
                                </p>
                                <p style="width: 100%; padding: 10px 20px;">
                                  <i class="fas fa-hourglass-half" aria-hidden="true" style="margin-right: 5px;"></i>
                                  Order Number: {{OrderNumber}}
  
                                </p>
  
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="u-row-container" style="padding: 0px;background-color: #ffffff">
              <div class="u-row"
                style="margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                <div
                  style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                  <div class="u-col u-col-33p33"
                    style="max-width: 320px;min-width: 200px;display: table-cell;vertical-align: top;">
                    <div class="v-col-background-color"
                      style="background-color: #5482cf;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
                      <div class="v-col-border"
                        style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
  
                        <table id="u_content_image_10" style="font-family:arial,helvetica,sans-serif;" role="presentation"
                          cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                            <tr>
                              <td class="v-container-padding-padding"
                                style="overflow-wrap:break-word;word-break:break-word;padding:60px 10px 10px;font-family:arial,helvetica,sans-serif;"
                                align="left">
  
                                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                  <tr>
                                    <td style="padding-right: 0px;padding-left: 0px;" align="center">
  
                                      <img align="center" border="0"
                                        src="https://firebasestorage.googleapis.com/v0/b/laundrolift-67de5.appspot.com/o/email-image-1.png?alt=media&token=3adf32d7-9a97-4552-8ba2-a860f04f260c"
                                        alt="image" title="image"
                                        style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 35%;max-width: 63px;"
                                        width="63" class="v-src-width v-src-max-width" />
  
                                    </td>
                                  </tr>
                                </table>
  
                              </td>
                            </tr>
                          </tbody>
                        </table>
  
                        <table id="u_content_text_8" style="font-family:arial,helvetica,sans-serif;" role="presentation"
                          cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                            <tr>
                              <td class="v-container-padding-padding"
                                style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                align="left">
  
                                <div
                                  style="font-size: 14px; color: #ffffff; line-height: 140%; text-align: center; word-wrap: break-word;">
                                  <p style="font-size: 14px; line-height: 140%;"><strong>Pickup and Delivery</strong></p>
                                </div>
  
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div id="u_column_10" class="u-col u-col-33p33"
                    style="max-width: 320px;min-width: 200px;display: table-cell;vertical-align: top;">
                    <div class="v-col-background-color"
                      style="background-color: #5482cf;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
  
                      <div class="v-col-border"
                        style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 2px solid #ffffff;border-right: 2px solid #ffffff;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
  
  
                        <table id="u_content_image_11" style="font-family:arial,helvetica,sans-serif;" role="presentation"
                          cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                            <tr>
                              <td class="v-container-padding-padding"
                                style="overflow-wrap:break-word;word-break:break-word;padding:60px 10px 10px;font-family:arial,helvetica,sans-serif;"
                                align="left">
  
                                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                  <tr>
                                    <td style="padding-right: 0px;padding-left: 0px;" align="center">
  
                                      <img align="center" border="0"
                                        src="https://firebasestorage.googleapis.com/v0/b/laundrolift-67de5.appspot.com/o/email-image-3.png?alt=media&token=b1bf36ed-1947-4759-9f06-542bc06e10e6"
                                        alt="image" title="image"
                                        style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 52%;max-width: 93.6px;"
                                        width="93.6" class="v-src-width v-src-max-width" />
  
                                    </td>
                                  </tr>
                                </table>
  
                              </td>
                            </tr>
                          </tbody>
                        </table>
  
                        <table id="u_content_text_9" style="font-family:arial,helvetica,sans-serif;" role="presentation"
                          cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                            <tr>
                              <td class="v-container-padding-padding"
                                style="overflow-wrap:break-word;word-break:break-word;padding:10px;font-family:arial,helvetica,sans-serif;"
                                align="left">
  
                                <div
                                  style="font-size: 14px; color: #ffffff; line-height: 140%; text-align: center; word-wrap: break-word;">
                                  <p style="font-size: 14px; line-height: 140%;"><strong>Fast and Convenient</strong></p>
                                </div>
  
                              </td>
                            </tr>
                          </tbody>
                        </table>
  
  
                      </div>
                    </div>
                  </div>
                  <div class="u-col u-col-33p33"
                    style="max-width: 320px;min-width: 200px;display: table-cell;vertical-align: top;">
                    <div class="v-col-background-color"
                      style="background-color: #5482cf;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
  
                      <div class="v-col-border"
                        style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
  
  
                        <table id="u_content_image_12" style="font-family:arial,helvetica,sans-serif;" role="presentation"
                          cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                            <tr>
                              <td class="v-container-padding-padding"
                                style="overflow-wrap:break-word;word-break:break-word;padding:60px 10px 10px;font-family:arial,helvetica,sans-serif;"
                                align="left">
  
                                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                  <tr>
                                    <td style="padding-right: 0px;padding-left: 0px;" align="center">
                                      <img align="center" border="0"
                                        src="https://firebasestorage.googleapis.com/v0/b/laundrolift-67de5.appspot.com/o/email-image-2.png?alt=media&token=383844d4-0ef0-43be-a44e-8c8966b7bd2d"
                                        alt="image" title="image"
                                        style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: inline-block !important;border: none;height: auto;float: none;width: 40%;max-width: 72px;"
                                        width="72" class="v-src-width v-src-max-width" />
  
                                    </td>
                                  </tr>
                                </table>
  
                              </td>
                            </tr>
                          </tbody>
                        </table>
  
                        <table id="u_content_text_10" style="font-family:arial,helvetica,sans-serif;" role="presentation"
                          cellpadding="0" cellspacing="0" width="100%" border="0">
                          <tbody>
                            <tr>
                              <td class="v-container-padding-padding"
                                style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 60px;font-family:arial,helvetica,sans-serif;"
                                align="left">
  
                                <div
                                  style="font-size: 14px; color: #ffffff; line-height: 140%; text-align: center; word-wrap: break-word;">
                                  <p style="font-size: 14px; line-height: 140%;"><strong>Secure Payments</strong></p>
                                </div>
  
                              </td>
                            </tr>
                          </tbody>
                        </table>
  
  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
  
  
  
  
            <div class="u-row-container" style="padding: 0px;background-color: #5482cf">
              <div class="u-row"
                style="margin: 0 auto;min-width: 320px;max-width: 600px;overflow-wrap: break-word;word-wrap: break-word;word-break: break-word;background-color: transparent;">
                <div
                  style="border-collapse: collapse;display: table;width: 100%;height: 100%;background-color: transparent;">
                  <div class="u-col u-col-100"
                    style="max-width: 320px;min-width: 600px;display: table-cell;vertical-align: top;">
                    <div class="v-col-background-color"
                      style="background-color: #ffffff;height: 100%;width: 100% !important;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
  
                      <div class="v-col-border"
                        style="box-sizing: border-box; height: 100%; padding: 0px;border-top: 0px solid transparent;border-left: 0px solid transparent;border-right: 0px solid transparent;border-bottom: 0px solid transparent;border-radius: 0px;-webkit-border-radius: 0px; -moz-border-radius: 0px;">
  
  
                        <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0"
                          cellspacing="0" width="100%" border="0">
                          <tbody>
                            <tr>
                              <td class="v-container-padding-padding"
                                style="overflow-wrap:break-word;word-break:break-word;padding:20px 50px 10px;font-family:arial,helvetica,sans-serif;"
                                align="left">
  
                                <div
                                  style="font-size: 14px; color: #000000; line-height: 140%; text-align: center; word-wrap: break-word;">
                                  <p style="line-height: 140%;"><span style="font-size: 18px; line-height: 25.2px;">Thank
                                      You For Your Business</span></p>
                                  <p style="line-height: 140%;"><span style="line-height: 25.2px; font-size: 18px;"><span
                                        style="color: #000000; line-height: 19.6px;"><a rel="noopener"
                                          href="tel:+1%20(908)%20529-6733" target="_blank"
                                          style="color: #000000; text-decoration: underline;">+1 (908)
                                          529-6733</a></span></span><br /><span
                                      style="line-height: 25.2px; font-size: 18px;"><span
                                        style="color: #000000; line-height: 19.6px;"><a rel="noopener"
                                          href="https://laundrolift.com" target="_blank"
                                          style="color: #000000; text-decoration: underline;">https://laundrolift.com</a></span></span>
                                  </p>
                                </div>
  
                              </td>
                            </tr>
                          </tbody>
                        </table>
  
                        <table style="font-family:arial,helvetica,sans-serif;" role="presentation" cellpadding="0"
                          cellspacing="0" width="100%" border="0">
                          <tbody>
                            <tr>
                              <td class="v-container-padding-padding"
                                style="overflow-wrap:break-word;word-break:break-word;padding:10px 10px 60px;font-family:arial,helvetica,sans-serif;"
                                align="left">
  
                                <div align="center">
                                  <div style="display: table; max-width:46px;">
                                    <table align="center" border="0" cellspacing="0" cellpadding="0" width="32"
                                      height="32"
                                      style="width: 32px !important;height: 32px !important;display: inline-block;border-collapse: collapse;table-layout: fixed;border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;vertical-align: top;margin-right: 0px">
                                      <tbody>
                                        <tr style="vertical-align: top">
                                          <td align="center" valign="middle"
                                            style="word-break: break-word;border-collapse: collapse !important;vertical-align: top">
                                            <a href="https://www.facebook.com/people/LaundroLift/61559193497510/"
                                              title="Facebook" target="_blank">
                                              <img
                                                src="https://firebasestorage.googleapis.com/v0/b/laundrolift-67de5.appspot.com/o/email-image-4.png?alt=media&token=1223f286-4792-46a2-bf0f-dd4705610c1b"
                                                alt="Facebook" title="Facebook" width="32"
                                                style="outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;clear: both;display: block !important;border: none;height: auto;float: none;max-width: 32px !important">
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
  
                              </td>
                            </tr>
                          </tbody>
                        </table>
  
  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </body>
  
  </html>`
}


export default templates;