import React, { Component } from "react";
import {
  MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
  MDBHamburgerToggler, MDBListGroup, MDBListGroupItem
} from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';
import { Link } from "react-router-dom";


class Mobile_menu extends Component {

  constructor(props) {
      super(props);

      this.state = {
        collapse1: false,
        collapseID: ''
      }
  }


  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
  }

  toggleSingleCollapse = collapseId => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId]
    });
  }


  render() {

    return (

      <Router>

        <MDBNavbar>
          <MDBHamburgerToggler id="hamburger1" onClick={() => this.toggleSingleCollapse('collapse1')} />
          <MDBCollapse isOpen={this.state.collapse1} navbar>
            {/* <MDBNavbarNav left>
                <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>
                          <span>Home</span>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                          <MDBDropdownItem to={'/'}>Home 01</MDBDropdownItem>
                          <MDBDropdownItem to={'/Home2'}>Home 02</MDBDropdownItem>
                          <MDBDropdownItem to={'/Home3'}>Home 03</MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>
                          <span>Pages</span>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem to={'/about_us'}>About Us</MDBDropdownItem>
                        <MDBDropdownItem to={'/about_us_2'}>About us 2</MDBDropdownItem>
                        <MDBDropdownItem to={'/contact_01'}>Contact us 01</MDBDropdownItem>
                        <MDBDropdownItem to={'/contact_02'}>Contact us 02</MDBDropdownItem>
                        <MDBDropdownItem to={'/services'}>Services 1</MDBDropdownItem>
                        <MDBDropdownItem to={'/services_2'}>Services 2</MDBDropdownItem>
                        <MDBDropdownItem to={'/Our_team'}>Our Team</MDBDropdownItem>
                        <MDBDropdownItem to={'/Team_details'}>Team Details</MDBDropdownItem>
                        <MDBDropdownItem to={'/Pricing'}>Pricing</MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem> 
                <MDBNavItem>
                  <MDBDropdown>
                      <MDBDropdownToggle nav caret>
                          <span>Services</span>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem to={'/Alterations_repairs'}>Alterations & repairs</MDBDropdownItem>
                        <MDBDropdownItem to={'/Commercial_laundry'}>Commercial laundry</MDBDropdownItem>
                        <MDBDropdownItem to={'/dry_cleaning'}>Dry cleaning</MDBDropdownItem>
                        <MDBDropdownItem to={'/Laundry_services'}>Laundry services</MDBDropdownItem>
                        <MDBDropdownItem to={'/Leather_cleaning'}>Leather cleaning</MDBDropdownItem>
                        <MDBDropdownItem to={'/Steam_iron'}>Steam iron</MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>
                          <span>Case Study</span>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                          <MDBDropdownItem to={'/Our_case_study'}>Our Case Study</MDBDropdownItem>
                          <MDBDropdownItem to={'/Case_study_details'}>Case Study Details</MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>
                          <span>Blog</span>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem to={'/Blog'}>Blog</MDBDropdownItem>
                        <MDBDropdownItem to={'/Blog_grid_view'}>Blog grid View</MDBDropdownItem>
                        <MDBDropdownItem to={'/Blog_details'}>Blog Details</MDBDropdownItem>
                    </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                    <MDBDropdown>
                      <MDBDropdownToggle nav caret>
                          <span>Contact Us</span>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem to={'/contact_01'}>Contact us 01</MDBDropdownItem>
                      </MDBDropdownMenu>
                    </MDBDropdown>
                </MDBNavItem>
              </MDBNavbarNav> */}
            <MDBListGroup style={{ minWidthL: '22rem' }} light>
              {!this.props.user && <MDBListGroupItem> <a href="/">Home </a></MDBListGroupItem>}
              {!this.props.user && <MDBListGroupItem> <a href="/Laundry_services">Order service now </a></MDBListGroupItem>}
              {!this.props.user && <MDBListGroupItem> <a href="/Laundry_services">Login <i className="fas fa-sign-in-alt" style={{marginLeft: '5px'}}></i></a></MDBListGroupItem>}
              {this.props.user && <MDBListGroupItem> <a href="#"> Welcome {this.props.user?.firstname} {this.props.user?.lastname[0]}. </a></MDBListGroupItem>}
              {this.props.user && <MDBListGroupItem> <a href={this.props.user?.type == 5 ? '/Laundry_services' : '/admin_panel'}>My Orders</a></MDBListGroupItem>}
              {this.props.user && <MDBListGroupItem style={{marginTop: '20px'}}> <a href="#" onClick={() => this.props.logout()}> 
                  Sign out <i className="fas fa-sign-out-alt" style={{marginLeft: '5px'}}></i></a></MDBListGroupItem>}
              {/* <MDBListGroupItem> <a href="/About">About Us</a></MDBListGroupItem>
              <MDBListGroupItem> <a href="/Contact">Contact Us</a></MDBListGroupItem> */}
            </MDBListGroup>
            
          </MDBCollapse>
        </MDBNavbar>
      </Router>
    );
  }
}

export default Mobile_menu;