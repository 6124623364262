import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Slider from 'react-slick';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';


export class Alterations_repairs extends Component {

    constructor() {
        super();
        this.state = {
          name: "React"
        };
        this.onChangeValue = this.onChangeValue.bind(this);

    }
    
    onChangeValue(event) {
        console.log(event.target.value);
    }

    render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [{
      
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        return (

            <div className="site-main">
                <Header/>
            
                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Alterations & repairs</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;/ Services</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Alterations & repairs</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>

                <div className="sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left border-right pr-45 pr-lg-15">
                                <aside className="widget widget-nav-menu">
                                    <h3 className="widget-title">Our services</h3>
                                    <ul>
                                        <li className="d-flex active"><a href="#">Alterations & repairs</a></li>
                                        <li className="d-flex">Commercial laundry</li>
                                        <li className="d-flex">Dry cleaning</li>
                                        <li className="d-flex">Laundry services</li>
                                        <li className="d-flex">Leather cleaning</li>
                                        <li className="d-flex">Steam iron</li>
                                    </ul>
                                </aside>
                                <aside className="widget image-box">
                                    <div className="widget_img">
                                        <img src="./images/single-img-8-369x154.jpg" />
                                    </div>
                                    <div className="featured-imagebox featured-imagebox-service style2">
                                        <div className="featured-image-box-wrapper">
                                            <div className="featured-thumbnail">
                                                <img width="41" height="41" src="./images/whats-up.png" />
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <h2>Call us for all your laundry needs</h2>
                                    </div>
                                </aside>
                                <aside className="widget widget-download">
                                    <h3 className="widget-title">Download</h3>
                                    <ul className="download">
                                        <li>
                                            <a><h3>Repair</h3>
                                            <i className="far fa-file-pdf" /></a>
                                        </li>
                                        <li>
                                            <a><h3>Maintenance</h3>
                                            <i className="far fa-file-pdf" /></a>
                                        </li>
                                    </ul>
                                    <h3 className="widget-title">Share now</h3>
                                    <div className="ttm-social-links-wrapper mt-20">
                                        <ul className="social-icons">
                                            <li className="social-facebook">
                                                <a target="_blank" href="https://www.facebook.com/preyantechnosys19"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-twitter">  
                                                <a target="_blank" href="https://twitter.com/PreyanTechnosys"><i className="fab fa-twitter" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-pinterest">
                                                <a target="_blank" href="https://dribbble.com/PreyanTechnosys"><i className="fab fa-dribbble" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-instagram">
                                                <a target="_blank" href="https://www.instagram.com/preyan_technosys/"><i className="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-lg-8 widget-area sidebar-right pl-45 pl-lg-15">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <img src="./images/single-img-9-836x469.png" />
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="ttm-service-classic-content">
                                            <h2>Dry cleaning</h2>
                                            <p>Taking our clothes to the dry cleaners is often on our to-do lists, but we rarely
                                            think about how to actually clean our most precious shirts, pants, and uniforms,
                                            unlike traditional washing using water and detergent, dry cleaning is the process
                                            of cleaning clothes without using water, as weird as it sounds, dry cleaning
                                            actually cleans your clothes.</p>
                                            <h2>Working process</h2>
                                            <p>Taking our clothes to the dry cleaners is often on our to-do lists, but we rarely
                                            think about how to actually clean our most precious shirts, pants, and uniforms,
                                            unlike traditional washing using water and detergent, dry cleaning is the process
                                            of cleaning clothes without using water, as weird as it sounds, dry cleaning
                                            actually cleans your clothes.</p>
                                        </div>
                                        <div className="ttm-tabs ttm-tab-style-01 pl-lg-15 pr-lg-15 pb-lg-10">
                                            <Tabs>
                                                <TabList className="tabs">
                                                    <Tab className="tab"><a className="tab-1 mb-md-0" tabIndex="0">Our mission</a></Tab>
                                                    <Tab className="tab"><a className="tab-2 mb-md-0" tabIndex="0">Our work</a></Tab>
                                                    <Tab className="tab"><a className="tab-3 mb-md-0" tabIndex="0">Completed</a></Tab>
                                                </TabList> 
                                                <div className="content-tab">                                
                                                    <TabPanel>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <img className="border-radius-8 mt-md-30 mb-md-30" src="./images/single-img-10-263x308.png" />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="pt-0">
                                                                <p>Our business is cleaning your clothes. As such, it is
                                                                imperative that you work diligently to keep your 
                                                                facilities and equipment clean and the basis of our good
                                                                relationship with our customers.</p>
                                                                <ul className="ttm-list style2">
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">Can I know about specific requests?</span>
                                                                        <p>If there are any special care instructions not included?</p>
                                                                    </li>
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">Do I need to count the items in my order?</span>
                                                                        <p>Absolutely not, counting is part of the service. Simply handles.</p>
                                                                    </li>
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">How fast will you process my order?</span>
                                                                        <p>Processing time is typically 48-72 hours, excluding Sundays</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <img className="border-radius-8" src="./images/single-img-11-263x308.png" />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="pt-0">
                                                                <p>We have the experience and skills necessary to tackle just about every
                                                                   type of job that comes our way, clients know exactly what to expect – professionalism,
                                                                   efficiency and exceptional results.</p>
                                                                <ul className="ttm-list style2">
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">What shall do have big order with bags?</span>
                                                                        <p>Please send us an email to inform us that you have a big order</p>
                                                                    </li>
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">What if I do not receive an email receipt?</span>
                                                                        <p>Try checking your spam folder, it’s not there, simply email it</p>
                                                                    </li>
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">What type of services do you provide?</span>
                                                                        <p>The following services: Dry Clean, Wash & Fold.</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <img className="border-radius-8" src="./images/single-img-10-263x308.png" />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="pt-0">
                                                                <p>We wash just about anything. From linen to bath mats to all types of clothing.
                                                                   We want you to know that you can rely on us for a complete laundry service including
                                                                   ironing and dry cleaning.</p>
                                                                <ul className="ttm-list style2">
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">What are working hours of operation?</span>
                                                                        <p>Our pick-up and drop-off time slots are 10am-3pm, 7pm-11pm</p>
                                                                    </li>
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">What is the laundry cut off times?</span>
                                                                        <p>For the morning shift place your order before 10 am same day.</p>
                                                                    </li>
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">How do I get the rid of bed bugs?</span>
                                                                        <p>We provide services to get rid of bed bugs and are available.</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </TabPanel>
                                                </div>       
                                            </Tabs>
                                        </div>
                                        <div className="row mt-55">
                                        <h2>Services plans & pricing</h2>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="ttm-pricing-plan pl-0">
                                                    <div className="ttm-p_table-head text-start">
                                                        <div className="ttm-p_table-main-icon">
                                                            <div className="ttm-p_table-icon">
                                                                <i className="flaticon-drying"></i>
                                                            </div>
                                                        </div>
                                                        <div className="ttm-p_table-heading">
                                                            <div className="ttm-p_table-title">
                                                                <h3>Dry cleaning</h3>
                                                                <p>Wash, dry and fold</p>
                                                            </div>
                                                            <div className="ttm-p_table-amount">
                                                                <span className="pac_frequency">From </span>
                                                                <span className="cur_symbol">$</span>
                                                                <span className="ttm-ptablebox-price">22.00</span>
                                                            </div>
                                                        </div>
                                                        <div className="ttm-p_table-footer">
                                                            <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="ttm-pricing-plan pl-0">
                                                    <div className="ttm-p_table-head text-start">
                                                        <div className="ttm-p_table-main-icon">
                                                            <div className="ttm-p_table-icon">
                                                                <i className="flaticon-dress"></i>
                                                            </div>
                                                        </div>
                                                        <div className="ttm-p_table-heading">
                                                            <div className="ttm-p_table-title">
                                                                <h3>Day dress service</h3>
                                                                <p>Dry Cleaning</p>
                                                            </div>
                                                            <div className="ttm-p_table-amount">
                                                                <span className="pac_frequency">From </span>
                                                                <span className="cur_symbol">$</span>
                                                                <span className="ttm-ptablebox-price">31.00</span>
                                                            </div>
                                                        </div>
                                                        <div className="ttm-p_table-footer">
                                                        <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6">
                                                <div className="ttm-pricing-plan pl-0">
                                                    <div className="ttm-p_table-head text-start">
                                                        <div className="ttm-p_table-main-icon">
                                                            <div className="ttm-p_table-icon">
                                                                <i className="flaticon-iron"></i>
                                                            </div>
                                                        </div>
                                                        <div className="ttm-p_table-heading">
                                                            <div className="ttm-p_table-title">
                                                                <h3>Ironing service</h3>
                                                                <p>Iron and Fold</p>
                                                            </div>
                                                            <div className="ttm-p_table-amount">
                                                                <span className="pac_frequency">From </span>
                                                                <span className="cur_symbol">$</span>
                                                                <span className="ttm-ptablebox-price">17.00</span>
                                                            </div>
                                                        </div>
                                                        <div className="ttm-p_table-footer">
                                                        <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>
            <Footer/>
                        
            </div>
          )
      }
   }

export default Alterations_repairs;