import React, { Component } from 'react';
import Slider from 'react-slick';
import Header from '../components/layout/Header';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';


export class Commercial_laundry extends Component {

    constructor(props) {
        super(props);
     
        this.state = {
          photoIndex: 0,
          isOpen: false,
        };
    }
    render() {
        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 1,

            responsive: [{
        
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };

        return (

            <div className="site-main">
                <Header/>
            
                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Commercial laundry</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;/ Services</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Commercial laundry</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>


                <div className="sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left border-right pr-45 pr-lg-15">
                                <aside className="widget widget-nav-menu">
                                    <h3 className="widget-title">Our services</h3>
                                    <ul>
                                        <li className="d-flex">Alterations & repairs</li>
                                        <li className="d-flex">Commercial laundry</li>
                                        <li className="d-flex">Dry cleaning</li>
                                        <li className="d-flex">Laundry services</li>
                                        <li className="d-flex">Leather cleaning</li>
                                        <li className="d-flex">Steam iron</li>
                                    </ul>
                                </aside>
                                <aside className="widget image-box">
                                    <div className="widget_img">
                                        <img src="./images/single-img-8-369x154.jpg" />
                                    </div>
                                    <div className="featured-imagebox featured-imagebox-service style2">
                                        <div className="featured-image-box-wrapper">
                                            <div className="featured-thumbnail">
                                                <img width="41" height="41" src="./images/whats-up.png" />
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <h2>Call us for all your laundry needs</h2>
                                    </div>
                                </aside>
                                <aside className="widget widget-download">
                                    <h3 className="widget-title">Download</h3>
                                    <ul className="download">
                                        <li>
                                            <a><h3>Repair</h3>
                                            <i className="far fa-file-pdf" /></a>
                                        </li>
                                        <li>
                                            <a><h3>Maintenance</h3>
                                            <i className="far fa-file-pdf" /></a>
                                        </li>
                                    </ul>
                                    <h3 className="widget-title">Share now</h3>
                                    <div className="ttm-social-links-wrapper mt-20">
                                        <ul className="social-icons">
                                            <li className="social-facebook">
                                                <a target="_blank" href="https://www.facebook.com/preyantechnosys19"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-twitter">  
                                                <a target="_blank" href="https://twitter.com/PreyanTechnosys"><i className="fab fa-twitter" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-pinterest">
                                                <a target="_blank" href="https://dribbble.com/PreyanTechnosys"><i className="fab fa-dribbble" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-instagram">
                                                <a target="_blank" href="https://www.instagram.com/preyan_technosys/"><i className="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-lg-8 widget-area sidebar-right pl-45 pl-lg-15">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="ttm-service-classic-content">
                                            <h2 className="pt-0">Dry cleaning</h2>
                                            <p>Taking our clothes to the dry cleaners is often on our to-do lists, but we rarely
                                            think about how to actually clean our most precious shirts, pants, and uniforms,
                                            unlike traditional washing using water and detergent, dry cleaning is the process
                                            of cleaning clothes without using water, as weird as it sounds, dry cleaning
                                            actually cleans your clothes.</p>
                                        </div>
                                        <div className="row mt-20 mb-50">
                                            <div className="col-md-6">
                                                <div className="featured-icon-box style4">
                                                    <div className="featured-icon">
                                                        <i className="flaticon-facemask"></i>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>End-to-end automation</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>An intelligent complete solution from washer/dryer to finishing to packaging.</p>
                                                        </div>
                                                        <div className="ttm-iconbox">
                                                            <a href={'/Alterations_repairs'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                                                <i className="flaticon-up-right-arrow"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="featured-icon-box style4">
                                                    <div className="featured-icon">
                                                        <i className="flaticon-drying"></i>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>Customized solutions</h3>
                                                        </div>
                                                        <div className="featured-desc">
                                                            <p>Each opportunity has its own characteristics, we assess the need</p>
                                                        </div>
                                                        <div className="ttm-iconbox">
                                                        <a href={'/Alterations_repairs'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                                                <i className="flaticon-up-right-arrow"></i>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12">
                                            <img src="./images/single-img-13-836x399.png" />
                                        </div>
                                        <div className="ttm-tabs ttm-tab-style-01 pl-lg-15 pr-lg-15 pb-lg-10">
                                            <Tabs>
                                                <TabList className="tabs">
                                                    <Tab className="tab"><a className="tab-1" tabIndex="0">Our mission</a></Tab>
                                                    <Tab className="tab"><a className="tab-2" tabIndex="0">Our work</a></Tab>
                                                    <Tab className="tab"><a className="tab-3" tabIndex="0">Completed</a></Tab>
                                                </TabList> 
                                                <div className="content-tab">                                
                                                    <TabPanel>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <img src="./images/single-img-10-263x308.png" />
                                                        </div>
                                                        <div className="col-md-8">
                                                            <div className="pt-0 pt-md-30">
                                                                <p>Our business is cleaning your clothes. As such, it is
                                                                imperative that you work diligently to keep your 
                                                                facilities and equipment clean and the basis of our good
                                                                relationship with our customers.</p>
                                                                <ul className="ttm-list style2">
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">Can I know about specific requests?</span>
                                                                        <p>If there are any special care instructions not included?</p>
                                                                    </li>
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">Do I need to count the items in my order?</span>
                                                                        <p>Absolutely not, counting is part of the service. Simply handles.</p>
                                                                    </li>
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">How fast will you process my order?</span>
                                                                        <p>Processing time is typically 48-72 hours, excluding Sundays</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <img src="./images/single-img-11-263x308.png" />
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="pt-15">
                                                                <p>Our business is cleaning your clothes. As such, it is
                                                                imperative that you work diligently to keep your 
                                                                facilities and equipment clean and the basis of our good
                                                                relationship with our customers.</p>
                                                                <ul className="ttm-list style2">
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">Can I know about specific requests?</span>
                                                                        <p>If there are any special care instructions not included?</p>
                                                                    </li>
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">Do I need to count the items in my order?</span>
                                                                        <p>Absolutely not, counting is part of the service. Simply handles.</p>
                                                                    </li>
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">How fast will you process my order?</span>
                                                                        <p>Processing time is typically 48-72 hours, excluding Sundays</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </TabPanel>
                                                    <TabPanel>
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <img src="./images/single-img-10-263x308.png" />
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="pt-15">
                                                                <p>Our business is cleaning your clothes. As such, it is
                                                                imperative that you work diligently to keep your 
                                                                facilities and equipment clean and the basis of our good
                                                                relationship with our customers.</p>
                                                                <ul className="ttm-list style2">
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">Can I know about specific requests?</span>
                                                                        <p>If there are any special care instructions not included?</p>
                                                                    </li>
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">Do I need to count the items in my order?</span>
                                                                        <p>Absolutely not, counting is part of the service. Simply handles.</p>
                                                                    </li>
                                                                    <li>
                                                                        <i className="flaticon-read"></i>
                                                                        <span className="ttm-list-li-content">How fast will you process my order?</span>
                                                                        <p>Processing time is typically 48-72 hours, excluding Sundays</p>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </TabPanel>
                                                </div>       
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>
                
            <Footer/>
                        
            </div>
        )
    }
}

export default Commercial_laundry;