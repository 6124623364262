import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Slider from 'react-slick';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import CountUp from 'react-countup';

export class Services_details extends Component {
    render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [{
      
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        return (

            <div className="site-main">
                <Header/>
            
                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Services 2</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Services 2</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>

                <section className="ttm-row clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row" data-aos="fade-down" data-aos-duration="2000">
                            <div className="col-lg-12">
                                <div className="section-title style3">
                                    <div className="title-header pb-0">
                                    <img width="98" height="14" src="./images/star.png" />
                                        <h3>4.8 (30k Reviews)</h3>
                                        <h2 className="title mb-0">Top rated leaning <span className="webkit-content">[Agency]</span></h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="featured-icon-box icon-align-top-content style3">
                                            <div className="ttm-iconbox-wrapper">
                                                <div className="ttm-subheading">
                                                    <h3>Claim Support</h3>
                                                    <h2>24/7 Supports</h2>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>We love to help you to get your clothes fresh and clean so we are here for you 24/7 for any question, concern or suggestion.</p>
                                                </div>
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-onlytxt ttm-icon_element-size-lg">
                                                        <i className="flaticon-24-hours-support"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="featured-icon-box icon-align-top-content style3">
                                            <div className="ttm-iconbox-wrapper">
                                                <div className="ttm-subheading">
                                                    <h3>100% Reliable</h3>
                                                    <h2>Offers & cares</h2>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>Every item sent to dry cleaning or Laundromat, hands of experts who take care of your garments in detailed way.</p>
                                                </div>
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-onlytxt ttm-icon_element-size-lg">
                                                        <i className="flaticon-offer"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                        <div className="featured-icon-box icon-align-top-content style3">
                                            <div className="ttm-iconbox-wrapper">
                                                <div className="ttm-subheading">
                                                    <h3>Consultation</h3>
                                                    <h2>Expert teams</h2>
                                                </div>
                                                <div className="featured-desc">
                                                    <p>With our subscription, you can set up your services and never worry about laundry or for dry cleaning again.</p>
                                                </div>
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-onlytxt ttm-icon_element-size-lg">
                                                        <i className="flaticon-help"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 text-center mt-20 res-991-mt-30">
                                        <p className="mb-0 text-theme-DarkColor">Dont hesitate, contact us for help and services 
                                        <strong><u><a  className="ttm-textcolor-darkgrey"> View More Services</a></u></strong></p>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </section>

                <section className="ttm-row portfolio-section bg-theme-DarkColor bg-img1 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-2.png")' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="section-title">
                                    <div className="title-header">
                                        <h2 className="title">Successfully completed projects.</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="section-title style5">
                                    <div className="title-header">
                                        <h2>250+</h2>
                                    </div>
                                    <div className="title-desc">
                                        <p>Completed Projects</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid p-0">
                        <Slider className="row g-0 mt-60 mt-lg-0 slick_slider ttm-boxes-spacing-30px mt-md-0" {...slick_slider} slidesToShow={4}arrows={false}
                        autoplay={false} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 4,slidesToScroll: 2 }},
                            {breakpoint: 991, settings: { slidesToShow: 3, slidesToScroll: 1 }} ,
                            {breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 }} ,
                            {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-portfolio style3">
                                    <div className="ttm-post-item">
                                        <div className="item-figure">
                                            <div className="featured-thumbnail">
                                                <a href="single-style-1.html">
                                                    <img className="img-fluid" src="images/portfolio/portfolio-10-484x565.jpg" alt="" />
                                                </a>
                                            </div>
                                            <div className="readmore_btn">
                                                <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-darkgrey" href="single-style-1.html">View Services</a>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Clear the clutter</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Before you even start cleaning, go room to room and pick up the clutter.</p>
                                            </div>
                                            <div className="ttm-icon-box">
                                                <a href="single-style-1.html"><i className="flaticon-right-down"></i></a>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-portfolio style3">
                                    <div className="ttm-post-item">
                                        <div className="item-figure">
                                            <div className="featured-thumbnail">
                                                <a href="single-style-1.html">
                                                    <img className="img-fluid" src="images/portfolio/portfolio-10-484x565.jpg" alt="" />
                                                </a>
                                            </div>
                                            <div className="readmore_btn">
                                                <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-darkgrey" href="single-style-1.html">View Services</a>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Dust and vacuum</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p> Concentrate your dusting on the tops of furniture and the undersides of shelves.</p>
                                            </div>
                                            <div className="ttm-icon-box">
                                                <a href="single-style-1.html"><i className="flaticon-right-down"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-portfolio style3">
                                    <div className="ttm-post-item">
                                        <div className="item-figure">
                                            <div className="featured-thumbnail">
                                                <a href="single-style-1.html">
                                                    <img className="img-fluid" src="images/portfolio/portfolio-10-484x565.jpg" alt="" />
                                                </a>
                                            </div>
                                            <div className="readmore_btn">
                                                <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-darkgrey" href="single-style-1.html">View Services</a>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Wipe mirrors and glass</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Use one damp microfiber cloth, in wiping clean all the and glass surfaces.</p>
                                            </div>
                                            <div className="ttm-icon-box">
                                                <a href="single-style-1.html"><i className="flaticon-right-down"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-portfolio style3">
                                    <div className="ttm-post-item">
                                        <div className="item-figure">
                                            <div className="featured-thumbnail">
                                                <a href="single-style-1.html">
                                                    <img className="img-fluid" src="images/portfolio/portfolio-10-484x565.jpg" alt="" />
                                                </a>
                                            </div>
                                            <div className="readmore_btn">
                                                <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-darkgrey" href="single-style-1.html">View Services</a>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Sweep, then mop</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Start mopping from the farthest corner of the room and move backwards towards the doorway.</p>
                                            </div>
                                            <div className="ttm-icon-box">
                                                <a href="single-style-1.html"><i className="flaticon-right-down"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="featured-imagebox featured-imagebox-portfolio style3">
                                    <div className="ttm-post-item">
                                        <div className="item-figure">
                                            <div className="featured-thumbnail">
                                                <a href="single-style-1.html">
                                                    <img width="446" height="521" className="img-fluid" src="images/portfolio/portfolio-10-484x565.jpg" alt="" />
                                                </a>
                                            </div>
                                            <div className="readmore_btn">
                                                <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-darkgrey" href="single-style-1.html">View Services</a>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Clean the whole house</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Pick one task and do it in every room in the house feeling like you’re in an endless cleaning cycle.</p>
                                            </div>
                                            <div className="ttm-icon-box">
                                                <a href="single-style-1.html"><i className="flaticon-right-down"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </section>

                <section className="ttm-row services-details-section bg-theme-GreyColor bg-img1 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title title-style-center_text mb-0">
                                    <div className="title-header">
                                        <h3>How we work</h3>
                                        <h2 className="title">How clean world <span className='webkit-content'>[Works]</span></h2>
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div className="row" data-aos="zoom-in" data-aos-duration="1000">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-icon-box style12">
                                    <div className="featured-num-box">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-booking"></i>
                                            </div>
                                        </div>
                                        <div className="process-num">
                                            <span className="number">1</span>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Free pickup</h3>
                                            </div>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Why pay for convenience a full bag of laundry or just one shirt, we pick-up for free!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-icon-box style12">
                                    <div className="featured-num-box">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-washing-machine"></i>
                                            </div>
                                        </div>
                                        <div className="process-num">
                                            <span className="number">2</span>
                                        </div>
                                    
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Reasonable price</h3>
                                            </div>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Top quality service at a very affordable price. It doesn't get any better than that!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="featured-icon-box style12">
                                    <div className="featured-num-box">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-laundry-basket"></i>
                                            </div>
                                        </div>
                                        <div className="process-num">
                                            <span className="number">3</span>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>No cash, no problem</h3>
                                            </div>
                                        </div>
                                        <div className="featured-desc">
                                            <p>On delivery, you can pay through our secure payment gateway or simply make a payment</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row bottom-zero-padding-section fid-section bg-theme-SkinColor clearfix">
                    <div className="container">
                        <div className="row" data-aos="fade-up" data-aos-duration="1000">
                            <div className="col-lg-4">
                                <div className="section-title style2 clearfix">
                                    <div className="title-header">
                                        <h2>Domestic cleaning </h2>
                                    </div>
                                    <div className="title-desc">
                                        <p>One prevent answer for all of your dry cleaning, laundry and stitching needs</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                {/*ttm-fid*/}
                                <div className="ttm-fid inside style2 text-theme-WhiteColor">
                                    <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                    <h3 className="ttm-fid-title">since</h3>{/*ttm-fid-title*/}
                                        <h4 className="ttm-fid-inner">
                                            <CountUp start={0} end={1994} duration={500} />
                                        </h4>
                                    </div>
                                </div>{/* ttm-fid end*/}
                            </div>
                            <div className="col-lg-4 col-md-4">
                                {/*ttm-fid*/}
                                <div className="ttm-fid inside style2 text-theme-WhiteColor">
                                    <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                    <h3 className="ttm-fid-title">since</h3>{/*ttm-fid-title*/}
                                        <h4 className="ttm-fid-inner">
                                            <CountUp start={0} end={300} duration={100} />
                                        </h4>
                                    </div>
                                </div>{/* ttm-fid end*/}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section title */}
                            <div className="section-title title-style-center_text mb-0">
                                <div className="title-header">
                                    <h3>We Are Committed To Cleaning Excellence!</h3>
                                    <h2 className="title">Services plans &  <span className='webkit-content'>[Pricing]</span></h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="ttm-pricing-plan">
                                        <div className="ttm-p_table-head">
                                            <div className="ttm-p_table-main-icon">
                                                <div className="ttm-p_table-icon">
                                                    <i className="flaticon-blanket"></i>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3><a href={'/Contact_01'}></a>Dry cleaning</h3>
                                                    <p>Wash, Dry and Fold</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="pac_frequency">From </span>
                                                    <span className="cur_symbol">$</span>
                                                    <span className="ttm-ptablebox-price">22.00</span>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-footer">
                                                <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ttm-pricing-plan">
                                        <div className="ttm-p_table-head">
                                            <div className="ttm-p_table-main-icon">
                                                <div className="ttm-p_table-icon">
                                                    <i className="flaticon-dress"></i>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                <h3><a href={'/Contact_01'}></a>Dress service</h3>
                                                    <p>Dry Cleaning</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="pac_frequency">From </span>
                                                    <span className="cur_symbol">$</span>
                                                    <span className="ttm-ptablebox-price">31.00</span>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-footer">
                                                <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row border-top">
                                <div className="col-md-6">
                                    <div className="ttm-pricing-plan">
                                        <div className="ttm-p_table-head">
                                            <div className="ttm-p_table-main-icon">
                                                <div className="ttm-p_table-icon pt-lg-30">
                                                    <i className="flaticon-iron"></i>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                <h3><a href={'/Contact_01'}></a>Ironing service</h3>
                                                    <p>Iron and Fold</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="pac_frequency">From </span>
                                                    <span className="cur_symbol">$</span>
                                                    <span className="ttm-ptablebox-price">17.00</span>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-footer">
                                                <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ttm-pricing-plan">
                                        <div className="ttm-p_table-head">
                                            <div className="ttm-p_table-main-icon">
                                                <div className="ttm-p_table-icon pt-lg-30">
                                                    <i className="flaticon-curtains"></i>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                <h3><a href={'/Contact_01'}></a>Curtains service</h3>
                                                    <p>Washed and Pressed</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="pac_frequency">From </span>
                                                    <span className="cur_symbol">$</span>
                                                    <span className="ttm-ptablebox-price">24.00</span>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-footer">
                                                <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                            <div className="ttm-single-img-1">
                                <div className="ttm_single_image-wrapper pt-40 pl-30">
                                    <img src="./images/single-img-634x543.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer/>
                        
            </div>
          )
      }
   }

export default Services_details;