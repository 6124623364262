import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export class Contact_01 extends Component {
    render() {
        
        return (
            <div className="site-main">
                <Header />

                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor pb-150" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Contact Us 1</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Contact Us 1</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>

                <section className="ttm-row request-section clearfix">
                    <div className="container">
                        <div className="row box-shadow p-55 border-radius-8 p-lg-0 bg-theme-WhiteColor mt_125 position-relative z-index-1">
                            <div className="col-lg-6 align-self-center">
                                <span className="ttm-call-to-arrow">
                                    <h3>Get a free <br></br> quotes</h3>
                                    <img className="img-fluid" src="images/arrow-text.png" alt="product-icon" />
                                </span>
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>What Were Offering</h3>
                                        <h2 className="title">Lets talk with <span className="webkit-content">[Expertise]</span></h2>
                                    </div>
                                </div>
                                <div className="featured-icon-box icon-align-before-title style5">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                            <i className="flaticon-support"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-desc">
                                            <p>Choose a plan to save, unique service options, and to view many additional services</p>
                                        </div>
                                    </div>
                                    <div className="social-icons circle">
                                        <ul className="list-inline">
                                            <li className="social-facebook">
                                                <a className="tooltip-top" target="_blank" href="https://www.facebook.com/themetechMount18">
                                                    <i className="ti ti-facebook"></i>Facebook
                                                </a>
                                            </li>
                                            <li className="social-twitter">
                                                <a className="tooltip-top" target="_blank" href="https://twitter.com/themetechmount">
                                                    <i className="ti ti-twitter"></i>Twitter
                                                </a>
                                            </li>
                                            <li className="social-printerest">
                                                <a className="tooltip-top" target="_blank" href="https://in.pinterest.com/themetechmount/_created/">
                                                    <i className="ti ti-pinterest"></i>Printerest
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="ttm-tabs ttm-tab-style-02 border-left border-left-0 pl-70 pl-lg-0">
                                    <Tabs>
                                        <TabList className="tabs">
                                            <Tab className="tab">
                                                <a>
                                                <i className="flaticon flaticon-research"></i>
                                                    <h3>Request an estimate</h3>
                                                </a>
                                            </Tab>
                                        </TabList> 
                                        <div className="content-tab">                                
                                            <TabPanel>
                                                <form id="login_form" className="login_form wrap-form">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <input type="text" id="txtname" placeholder="Your Name" />
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <input type="email" id="txtemail" placeholder="Email Address" />
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <input type="password" id="password" placeholder="Phone Number"/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <input type="password" id="cpassword" placeholder="Zip Code *"/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <label>
                                                                <input type="tel" id="txtphone" placeholder="Services"/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <label>
                                                            <textarea name="message" rows="4" placeholder="YourMessage" required=""></textarea>
                                                            </label>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="text-left">
                                                                <button type="submit" id="submit" className="mt-10 submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" value>
                                                                    Submit Quote
                                                                </button>
                                                                <div className="text-form">
                                                                    <div className="query-text">Been here before? 
                                                                        <u>
                                                                            <a href="#" className="fw-700"> Check your query</a>
                                                                        </u>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </TabPanel>
                                            <TabPanel>
                                                <form id="login_form" className="login_form wrap-form">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <i className="ti ti-user"></i>
                                                                <input type="text" id="txtname" placeholder="Username" />
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <i className="ti ti-email"></i>
                                                                <input type="email" id="txtemail" placeholder="Email Address" />
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <i className="ti ti-lock"></i>
                                                                <input type="password" id="password" placeholder="Password"/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <i className="ti ti-lock"></i>
                                                                <input type="password" id="cpassword" placeholder="Confirm Password *"/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <label>
                                                                <i className="ti ti-mobile"></i>
                                                                <input type="tel" id="txtphone" placeholder="Phone Number"/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <label>
                                                                <div className="d-md-flex justify-content-between">
                                                                    <div className="cookies-checkbox mt-5">
                                                                        <div className="d-flex flex-row align-items-center justify-content-start">
                                                                            <input className="w-auto mr-10" id="cookies-consent" name="cookies-consent" type="checkbox" value="yes" />
                                                                            <span>you accept our Terms and Conditions and Privacy Policy</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-15">
                                                                        <p>Already have an account?</p>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <label>
                                                                <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                                                type="submit">Sign up</button>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </form>
                                            </TabPanel>
                                        </div>       
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row zero_padding-section clearfix">
                    <div className="container-fluid">
                        <div className="row g-0">
                            <div className="col-lg-3 col-md-12">
                                <div className="col-bg-img-one ttm-bg ttm-col-bgimage-yes z-index-2">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer spacing-3 ml_15"  style={{ backgroundImage: 'url("images/bg-image/col-bgimage-1.png")' }}></div>
                                    <div className="layer-content">
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-12">
                                <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg ttm-left-span bg-theme-SkinColor mt_15 z-index-1">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: 'url("images/bg-image/col-bgimage-2.png")' }}></div>
                                    <div className="layer-content">
                                        <div className="text-center">
                                            <div className="featured-imagebox featured-imagebox-service style4">
                                                <div className="featured-image-box-wrapper">
                                                    <div className="featured-thumbnail">
                                                        <img width="41" height="41" src="./images/whats-up.png" />
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-title">
                                                            <h3>call: <a href="tel:+123457899"> +18 - 2222 - 3555</a></h3>
                                                        </div>
                                                        <div className="ttm-mail">
                                                            <h3>Email: <a className="text-theme-WhiteColor" href="#">info@laundrolift.com</a></h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h2>Call us for all your laundry needs</h2>
                                                <h3>Opening hours: </h3>
                                                <p> 06:00AM to 04:00PM</p>
                                                <div className="ttm-iconbox">
                                                    <a href={'/Contact_02'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-White"> Contact Us </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <img src="images/bg-image/col-bgimage-2.png" className="ttm-equal-height-image" alt="bg-image" />
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="map">
                                    <iframe height="560" width="100%" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12609.73108660754!2d-122.47279800000001!3d37.803331!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808586e6302615a1%3A0x86bd130251757c00!2sStorey%20Ave%2C%20San%20Francisco%2C%20CA%2094129!5e0!3m2!1sen!2sus!4v1591859311334!5m2!1sen!2sus" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row contact-us-section clearfix" data-aos="zoom-in-up" data-aos-duration="2000">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="featured-icon-box icon-align-before-content style10">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                            <i className="flaticon-palm-islands"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Dubai</h3>
                                        </div>
                                    </div>
                                    <div className="featured-desc">
                                        <h3>Call us on: <a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                        <h3 className="mb-30">Email: <a href="#">info@laundrolift.com</a></h3>
                                        <h3 className="d-inline">Address: </h3> A-1, envanto headquarters melbourne, australia.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="featured-icon-box icon-align-before-content style10">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                            <i className="flaticon-toronto"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Canada</h3>
                                        </div>
                                    </div>
                                    <div className="featured-desc">
                                        <h3>Call us on: <a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                        <h3 className="mb-30">Email: <a href="#">info@laundrolift.com</a></h3>
                                        <h3 className="d-inline">Address: </h3> A-1, envanto headquarters melbourne, australia.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="featured-icon-box icon-align-before-content style10">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                            <i className="flaticon-london-eye"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>London</h3>
                                        </div>
                                    </div>
                                    <div className="featured-desc">
                                        <h3>Call us on: <a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                        <h3 className="mb-30">Email: <a href="#">info@laundrolift.com</a></h3>
                                        <h3 className="d-inline">Address: </h3> A-1, envanto headquarters melbourne, australia.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="featured-icon-box icon-align-before-content style10">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                            <i className="flaticon-melbourne"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Melbourne</h3>
                                        </div>
                                    </div>
                                    <div className="featured-desc">
                                        <h3>Call us on: <a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                        <h3 className="mb-30">Email: <a href="#">info@laundrolift.com</a></h3>
                                        <h3 className="d-inline">Address: </h3> A-1, envanto headquarters melbourne, australia.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <Footer/>

            </div>
        )
    }
}


export default Contact_01;