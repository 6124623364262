import React, { Component } from 'react';
import Slider from 'react-slick';
import ProgressBar from 'react-animated-progress-bar';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import Video from '../components/layout/Video';


export class About_us extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    render() {
      var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [ {

            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },{
    
          breakpoint: 778,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 575,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }]
      };
        
        return (       
          <div className="site-main">

            <Header/>
            
            <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12"> 
                        <div className="title-box text-center">
                            <div className="page-title-heading">
                            <h2 className="title">About Us</h2>
                            </div>{/* /.page-title-captions */}
                            <div className="breadcrumb-wrapper">
                            <span>
                                <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                            </span>
                            <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                            <span>About Us 01</span>
                            </div>  
                        </div>
                        </div>{/* /.col-md-12 */}  
                    </div>{/* /.row */}  
                </div>{/* /.container */}                      
            </div>

            <section className="ttm-row faq-section-3 clearfix" data-aos="zoom-in" data-aos-duration="2000">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>We Are Committed To Cleaning Excellence!</h3>
                                    <h2 className="title">Our cleaning experts deliver the <span className="webkit-content">[Clean]</span></h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="ttm-fid inside style1 text-theme-WhiteColor">
                                        <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                        <h3 className="ttm-fid-title">Year <br /> experience</h3>
                                            <h4 className="ttm-fid-inner">
                                                <CountUp start={0} end={25} duration={5} />
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <p className="position-relative mr_20 mr-lg-0 right">We prioritize speed and efficiency to ensure you get your clothes back on time.
                                    Once we pick up your items, we clean them as soon as possible. 
                                    Your clothes use a separate machine, work based on your preferences.</p>
                                </div>
                            </div>
                            <div className="separator">
                                <div className="sep-line mt-4 mb-40"></div>
                            </div>
                            <ul className="ttm-list style1">
                            <li>
                                <i className="fa fa-check"></i>
                                <span className="ttm-list-li-content">24/7 Support</span>
                            </li>
                            <li>
                                <i className="fa fa-check"></i>
                                <span className="ttm-list-li-content">Offers & cares</span>
                            </li>
                            <li>
                                <i className="fa fa-check"></i>
                                <span className="ttm-list-li-content">Expert teams</span>
                            </li>
                        </ul>
                        </div>
                        <div className="col-xl-5">
                            <div className="pl-50 pl-xl-0">
                                <div className="featured-icon-box icon-align-before-content style9">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                            <i className="flaticon-mission"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3><a href="chronic-pain-relief.html">Our mission</a></h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Providing customers with world-class laundry room operations providing superior quality and service.</p>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href="Single-Case-Studies.html" />
                                    </div>
                                </div>
                                <div className="featured-icon-box icon-align-before-content style9">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                            <i className="flaticon-focus"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3><a href="chronic-pain-relief.html">Our vision & values</a></h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Passionate about providing highest quality laundry services with focus, integrity, discipline</p>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href="Single-Case-Studies.html" />
                                    </div>
                                </div>
                                <div className="featured-icon-box icon-align-before-content style9">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                            <i className="flaticon-history"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3><a href="chronic-pain-relief.html">Our history</a></h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>We are having a great 25 years of experience in laundry & dry cleaning service</p>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href="Single-Case-Studies.html" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row client-section bg-theme-GreyColor clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-12 text-center">
                    <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={6}arrows={false}
                    autoplay={true} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 4,slidesToScroll: 2 }},
                        {breakpoint: 767, settings: { slidesToShow: 3, slidesToScroll: 1 }} ,
                    {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-1-163x31.png" />
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-2-128x25.png" />
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-1-163x31.png" />
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-2-128x25.png" />
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-1-163x31.png" />
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-2-128x25.png" />
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-2-128x25.png" />
                                </div>
                            </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </section>

            <section className="ttm-row bottom-zero-padding-section fid-section bg-theme-SkinColor bg-img1 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="section-title style2 clearfix">
                                <div className="title-header">
                                    <h2>Domestic cleaning </h2>
                                </div>
                                <div className="title-desc">
                                    <p>One prevent answer for all of your dry cleaning, laundry and stitching needs</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            {/*ttm-fid*/}
                            <div className="ttm-fid inside style2 text-theme-WhiteColor">
                                <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                <h3 className="ttm-fid-title">since</h3>{/*ttm-fid-title*/}
                                    <h4 className="ttm-fid-inner">
                                        <CountUp start={0} end={1994} duration={500} />
                                    </h4>
                                </div>
                            </div>{/* ttm-fid end*/}
                        </div>
                        <div className="col-lg-4 col-md-4">
                            {/*ttm-fid*/}
                            <div className="ttm-fid inside style2 text-theme-WhiteColor">
                                <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                <h3 className="ttm-fid-title">since</h3>{/*ttm-fid-title*/}
                                    <h4 className="ttm-fid-inner">
                                        <CountUp start={0} end={300} duration={100} />
                                        <span>+</span>
                                    </h4>
                                </div>
                            </div>{/* ttm-fid end*/}
                        </div>
                    </div>
                    <div className="separator">
                        <div className="sep-line mt-4 mb-75 mb-lg-20" />
                    </div>
                </div>
                <div className="container-fluid">
                    <Slider className="row pl-60 pr-60 slick_slider ttm-boxes-spacing-10px pl-xl-0 pr-xl-0" {...slick_slider} slidesToShow={5}arrows={false}
                    autoplay={false} responsive= { [{ breakpoint: 1199, settings: {slidesToShow: 4,slidesToScroll: 2 }},
                        {breakpoint: 991, settings: { slidesToShow: 3, slidesToScroll: 1 }},
                        {breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 }},
                        {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-team style2">
                                <div className="featured-thumbnail">
                                    <img src="./images/team/team-1-307x368.jpg" />
                                </div>
                                <div className="social-icon-wrapper">
                                    <ul className="social-icons list-inline">
                                        <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                        <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                        <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                        <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-content-box-inner">
                                        <p>manager</p>
                                        <div className="featured-title">
                                            <h3><a href={'/Team_details.js'}>Juliana SIlva</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-team style2">
                                <div className="featured-thumbnail">
                                    <img src="./images/team/team-1-307x368.jpg" />
                                </div>
                                <div className="social-icon-wrapper">
                                    <ul className="social-icons list-inline">
                                        <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                        <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                        <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                        <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-content-box-inner">
                                        <p>Team lead</p>
                                        <div className="featured-title">
                                            <h3><a href={'/Team_details.js'}>Joel Lou</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-team style2">
                                <div className="featured-thumbnail">
                                    <img src="./images/team/team-1-307x368.jpg" />
                                </div>
                                <div className="social-icon-wrapper">
                                    <ul className="social-icons list-inline">
                                        <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                        <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                        <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                        <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-content-box-inner">
                                        <p>Manager</p>
                                        <div className="featured-title">
                                            <h3><a href={'/Team_details.js'}>Jack Martin</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-team style2">
                                <div className="featured-thumbnail">
                                    <img src="./images/team/team-1-307x368.jpg" />
                                </div>
                                <div className="social-icon-wrapper">
                                    <ul className="social-icons list-inline">
                                        <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                        <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                        <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                        <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-content-box-inner">
                                        <p>manager</p>
                                        <div className="featured-title">
                                            <h3><a href={'/Team_details.js'}>Jessica Brown</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-team style2">
                                <div className="featured-thumbnail">
                                    <img src="./images/team/team-1-307x368.jpg" />
                                </div>
                                <div className="social-icon-wrapper">
                                    <ul className="social-icons list-inline">
                                        <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                        <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                        <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                        <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-content-box-inner">
                                        <p>manager</p>
                                        <div className="featured-title">
                                            <h3><a href={'/Team_details.js'}>Charles Altman</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-team style2">
                                <div className="featured-thumbnail">
                                    <img src="./images/team/team-1-307x368.jpg" />
                                </div>
                                <div className="social-icon-wrapper">
                                    <ul className="social-icons list-inline">
                                        <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                        <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                        <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                        <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-content-box-inner">
                                        <p>manager</p>
                                        <div className="featured-title">
                                            <h3><a href={'/Team_details.js'}>Charles Altman</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <p className="mb-0 text-theme-WhiteColor">Meet our trusted professionals with great years of experience.
                            <strong><u><a href={'/Team_details.js'}> View More Team</a></u></strong></p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row blog-section clearfix">
                <div className="container">
                    <div className="row" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000">
                        <div className="col-md-12">
                            <div className="section-title title-style-center_text mb-0">
                                <div className="title-header">
                                    <h3>From the blog</h3>
                                    <h2 className="title">Our latest  <span className='webkit-content'>[blog]</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000">
                        <div className="col-lg-6">
                            <Slider className="row slick_slider" {...slick_slider} slidesToShow={1}arrows={false}
                            dots={false}autoplay={false} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 1,slidesToScroll: 2 }},
                            {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] } >
                                <div className="col-md-12">
                                    <div className="featured-imagebox featured-imagebox-blog style2">
                                        {/* featured-thumbnail */}
                                        <div className="featured-thumbnail">
                                            <img src="./images/blog/blog-7-650x433.jpg" />
                                        </div>{/* featured-thumbnail end */}
                                        <div className="featured-content">
                                            <div className="post-meta">
                                                <span className="ttm-meta-line byline"><a href={'/Blog'}>Cleaning, supplies</a></span>
                                                <span className="ttm-meta-line category-link">02 Dec 2022</span>
                                            </div>
                                            <div className="featured-title">
                                                <h3><a href={'/Blog_details'}>The definitive guide to natural laundry detergents</a></h3>
                                            </div>
                                        </div>
                                        <div className="ttm-iconbox">
                                            <a href={'/Blog_details'}></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-imagebox featured-imagebox-blog style2">
                                        {/* featured-thumbnail */}
                                        <div className="featured-thumbnail">
                                            <img src="./images/blog/blog-7-650x433.jpg" />
                                        </div>{/* featured-thumbnail end */}
                                        <div className="featured-content">
                                            <div className="post-meta">
                                                <span className="ttm-meta-line byline"><a href={'/Blog'}>Sanitizing</a></span>
                                                <span className="ttm-meta-line category-link">09 Dec 2022</span>
                                            </div>
                                            <div className="featured-title">
                                                <h3><a href={'/Blog_details'}>10 reasons to hire professional dry cleaner services</a></h3>
                                            </div>
                                        </div>
                                        <div className="ttm-iconbox">
                                            <a href={'/Blog_details'}></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-imagebox featured-imagebox-blog style2">
                                        {/* featured-thumbnail */}
                                        <div className="featured-thumbnail">
                                            <img src="./images/blog/blog-7-650x433.jpg" />
                                        </div>{/* featured-thumbnail end */}
                                        <div className="featured-content">
                                            <div className="post-meta">
                                                <span className="ttm-meta-line byline"><a href={'/Blog'}>Sanitizing</a></span>
                                                <span className="ttm-meta-line category-link">03 Dec 2022</span>
                                            </div>
                                            <div className="featured-title">
                                                <h3><a href={'/Blog_details'}>We wash shirts and pantsdrying with the best detergent</a></h3>
                                            </div>
                                        </div>
                                        <div className="ttm-iconbox">
                                            <a href={'/Blog_details'}></a>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                        <div className="col-lg-6">
                            <div className="pl-45 pl-lg-0">
                                <div className="featured-imagebox featured-imagebox-blog style2">
                                    <div className="featured-content pt-0">
                                        <div className="post-meta">
                                            <span className="ttm-meta-line byline"><a href={'/Blog'}>Sanitizing</a></span>
                                            <span className="ttm-meta-line category-link">03 Dec 2022</span>
                                        </div>
                                        <div className="featured-title">
                                            <h3><a href={'/Blog_details'}>We wash shirts and pantsdrying with the best detergent</a></h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Blog_details'}></a>
                                    </div>
                                </div>
                                <div className="separator">
                                    <div className="sep-line mt-4 mb-4"></div>
                                </div>
                                <div className="featured-imagebox featured-imagebox-blog style2">
                                    <div className="featured-content pt-0">
                                        <div className="post-meta">
                                            <span className="ttm-meta-line byline"><a href={'/Blog'}>Cleaning, supplies</a></span>
                                            <span className="ttm-meta-line category-link">02 Dec 2022</span>
                                        </div>
                                        <div className="featured-title">
                                            <h3><a href={'/blog_details'}>The definitive guide to natural laundry detergents</a></h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Blog_details'}></a>
                                    </div>
                                </div>
                                <div className="separator">
                                    <div className="sep-line mt-4 mb-4"></div>
                                </div>
                                <div className="featured-imagebox featured-imagebox-blog style2">
                                    <div className="featured-content pt-0">
                                        <div className="post-meta">
                                            <span className="ttm-meta-line byline"><a href={'/Blog'}>Sanitizing</a></span>
                                            <span className="ttm-meta-line category-link">09 Nov 2022</span>
                                        </div>
                                        <div className="featured-title">
                                            <h3><a href={'/blog_details'}>10 reasons to hire professional dry cleaner services</a></h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Blog_details'}></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <Footer/>
                        
         </div>
       )
   }
}


export default About_us;