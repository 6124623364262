import React, { Component } from 'react';
import Slider from 'react-slick';
import ProgressBar from 'react-animated-progress-bar';
import { Banner } from '../components/banner/Home1_banner';
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Header3 from '../components/layout/Header3';
import Header2 from '../components/layout/Header2';
import Header from '../components/layout/Header';
import CountUp from 'react-countup';
import Video from '../components/layout/Video';

export class Home2 extends Component {
    constructor(props) {
        super(props);
     
        this.state = {
          photoIndex: 0,
          isOpen: false,
          currentSlide: 0,
        };
    }

    handleAfterChange = (index) => {
        console.log("after change", index);
        this.setState({
        currentSlide: index
        });
    };

    render() {
        const { photoIndex, isOpen } = this.state;
        const settings = {
            beforeChange: function (currentSlide, nextSlide) {
              console.log("before change", currentSlide, nextSlide);
            },
            afterChange: this.handleAfterChange
          };


        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToScroll: 1,
            slidesToShow:4,
            rows: 1,
           
            responsive: [{
        
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };
        
        return (    
          <div className="site-main">

            <Header2/>

            
            {/* Banner */} 
            <Banner/>
            {/* Banner end */} 

            
            {/* about-section */}
            <section className="ttm-row clearfix" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center style3">
                                <div className="title-header">
                                <img width="98" height="14" src="./images/star.png" />
                                    {/* <h3>4.8 (30k Reviews)</h3> */}
                                    <h2 className="title">We pick up and deliver your valuable <span className="webkit-content">[Clothes]</span></h2>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="ttm-iconbox-wrapper">
                                            <div className="ttm-subheading">
                                                <h3>Claim Support</h3>
                                                <h2>24/7 Supports</h2>
                                            </div>
                                            <div className="featured-desc">
                                                <p>We love to help you to get your clothes fresh and clean so we are here for you 24/7 for any question, suggestion.</p>
                                            </div>
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-onlytxt ttm-icon_element-size-lg">
                                                    <i className="flaticon-24-hours-support"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="ttm-iconbox-wrapper">
                                            <div className="ttm-subheading">
                                                <h3>100% Reliable</h3>
                                                <h2>Offers & cares</h2>
                                            </div>
                                            <div className="featured-desc">
                                                <p>Every item sent to dry cleaning or Laundromat, hands of experts who take care of your garments in detailed way.</p>
                                            </div>
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-onlytxt ttm-icon_element-size-lg">
                                                    <i className="flaticon-offer"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="featured-icon-box icon-align-top-content style3">
                                        <div className="ttm-iconbox-wrapper">
                                            <div className="ttm-subheading">
                                                <h3>Consultation</h3>
                                                <h2>Expert teams</h2>
                                            </div>
                                            <div className="featured-desc">
                                                <p>With our subscription, you can set up your services and never worry about laundry or for dry cleaning again.</p>
                                            </div>
                                            <div className="featured-icon">
                                                <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-onlytxt ttm-icon_element-size-lg">
                                                    <i className="flaticon-help"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="row">
                                {/* <div className="col-md-12 text-center mt-20 res-991-mt-30">
                                    <p className="mb-0 text-theme-DarkColor">Dont hesitate, contact us for help and services 
                                    <strong><u><a href={'/Contact_01'}> View More Services</a></u></strong></p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* about-section end */}

            {/* features-section */}
            <section className="ttm-row services-section-1 bg-theme-GreyColor bg-img1 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
                <div className="container">
                    {/* row */}
                    <Slider className="row slick_slider slick-dots-style1" {...slick_slider} slidesToShow={1}arrows={false}
                    dots={true}autoplay={false} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 1,slidesToScroll: 1 }},
                    {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] } >
                        <div className="col-md-12" data-aos-duration="2000" data-aos="fade-up">
                            <div className="featured-imagebox featured-imagebox-service style1">
                                <div className="ttm-top-imagebox ttm-wrap-cell">
                                    <div className="featured-thumbnail">
                                        <img src="./images/single-img-3-583x494.png" />
                                    </div>
                                </div>
                                <div className="featured-content ttm-wrap-cell">
                                    <div className="process-num">
                                        <span className="number">01</span>
                                    </div>
                                    <div className="featured-title">
                                        <h2>Place your order online or over the phone <span className='webkit-content'>[Today]</span></h2>
                                    </div>
                                    <div className="featured-desc">
                                        <p>We confirm your pick up date/time and your estimated delivery window
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="featured-imagebox featured-imagebox-service style1">
                                <div className="ttm-top-imagebox ttm-wrap-cell">
                                    <div className="featured-thumbnail">
                                        <img src="./images/single-img-3-583x494.png" />
                                    </div>
                                </div>
                                <div className="featured-content ttm-wrap-cell">
                                    <div className="process-num">
                                        <span className="number">02</span>
                                    </div>
                                    <div className="featured-title">
                                        <h2>Pack up your valuable <span className='webkit-content'>[Clothes]</span></h2>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Pack the item in a disposable bag, use one bag per service, our driver will
                                            transfer them to a reusable laundry heap bag that you can save for your next
                                            order, pack one bag per service.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="featured-imagebox featured-imagebox-service style1">
                                <div className="ttm-top-imagebox ttm-wrap-cell">
                                    <div className="featured-thumbnail">
                                        <img src="./images/single-img-3-583x494.png" />
                                    </div>
                                </div>
                                <div className="featured-content ttm-wrap-cell">
                                    <div className="process-num">
                                        <span className="number">03</span>
                                    </div>
                                    <div className="featured-title">
                                        <h2>Wait for driver to pick up <span className='webkit-content'>[Clothes]</span></h2>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Get notified when your driver is nearby. They will pick up your bag and take
                                         it to a local cleaning facility. Regular order updates, live driver tracking,
                                         zero-emission delivery vehicles, trusted local cleaners, and convenient laundry
                                         solutions those help save the environment.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="featured-imagebox featured-imagebox-service style1">
                                <div className="ttm-top-imagebox ttm-wrap-cell">
                                    <div className="featured-thumbnail">
                                        <img src="./images/single-img-3-583x494.png" />
                                    </div>
                                </div>
                                <div className="featured-content ttm-wrap-cell">
                                    <div className="process-num">
                                        <span className="number">04</span>
                                    </div>
                                    <div className="featured-title">
                                        <h2>Expect your clothes <span className='webkit-content'>[Delivery]</span></h2>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Items will be meticulously cleaned by a local partner facility.
                                            Our driver will bring them back to nicely fold. You are in complete control of your deliveries and can
                                            reschedule at any time if you are not home. 24 hour processing time,
                                            real-time order status and easy rescheduling
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>    
            {/* features-section end */}

            {/* <section className="ttm-row services-section-icon clearfix">
                <div className="container">
                    <div className="row" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="1000">
                        <div className="col-lg-6 align-self-center">

                            <div className="section-title">
                                <div className="title-header">
                                    <h3>What Were Offering</h3>
                                    <h2 className="title">Discover all our <span className='webkit-content'>[Services]</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="featured-icon-box style4">
                                <div className="featured-icon">
                                    <i className="flaticon-laundry"></i>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3><a href={'/Alterations_repairs'} />Laundry service</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>It's hard to keep your curtains clean and wrinkle-free.</p>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Alterations_repairs'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="featured-icon-box style4">
                                <div className="featured-icon">
                                    <i className="flaticon-drying"></i>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Dry cleaning</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Get exceptional dry cleaning solutions at affordable pricing</p>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Laundry_services'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="featured-icon-box style4">
                                <div className="featured-icon">
                                    <i className="flaticon-steam-iron"></i>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Steam iron</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Dedicated staff provides consistent, superior cleaning</p>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Commercial_laundry'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="featured-icon-box style4">
                                <div className="featured-icon">
                                    <i className="flaticon-laundry-basket"></i>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Commercial laundry</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Experts in top quality that meets all the exacting standards.</p>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Alterations_repairs'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="featured-icon-box style4">
                                <div className="featured-icon">
                                    <i className="flaticon-washing-clothes"></i>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3>Wash and fold</h3>
                                    </div>
                                    <div className="featured-desc">
                                        <p>Try our high quality dry cleaning service or skip the selection!</p>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Commercial_laundry'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="featured-imagebox featured-imagebox-service style2">
                                <div className="featured-image-box-wrapper">
                                    <div className="featured-thumbnail">
                                        <img width="33" height="33" src="./images/whats-up.png" />
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3><a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                        </div>
                                    </div>
                                </div>
                                <h2>Call us for all your laundry needs</h2>
                                <div className="ttm-iconbox">
                                    <a href={'/Alterations_repairs'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark"> Contact Us </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <section className="ttm-row padding_zero-section bg-theme-GreyColor clearfix">
                <div className="container-fluid">
                    <div className="row g-0">
                        <div className="col-lg-3 col-md-12">
                            <div className="col-bg-img-one ttm-col-bgimage-yes ttm-bg ttm-left-span bg-theme-SkinColor z-index-0">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer spacing-3"  style={{ backgroundImage: 'url("images/bg-image/col-bgimage-2.png")' }}></div>
                                <div className="layer-content">
                                    <div className="section-title style4">
                                        <div className="title-header">
                                            {/* <h3>100%</h3> */}
                                            <h2 className="title">Satisfaction guarantee</h2>
                                        </div>
                                        <a href={'/Laundry_services'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white mt-20"> Order Now </a>
                                    </div>
                                </div>
                            </div>
                            <img src="images/bg-image/col-bgimage-2.png" className="ttm-equal-height-image" alt="bg-image" />
                        </div>
                        <div className="col-lg-4 col-md-12">
                            <div className="col-bg-img-one ttm-bg ttm-col-bgimage-yes z-index-0">
                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer spacing-3"  style={{ backgroundImage: 'url("images/bg-image/col-bgimage-1.png")' }}></div>
                                <div className="layer-content">
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 pl-60 pr-60 align-self-center pt-40 pt-lg-130 pl-lg-15 pr-lg-15">
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>What We’re Offering</h3>
                                    <h2 className="title">Door-to-Door Wash & <span className="webkit-content">[Fold]</span></h2>
                                </div>
                            </div>
                            <div className="ttm-progress-bar style1">
                                <h3 className="progressbar-title">We pick up at your door</h3>
                                <ProgressBar  rect  percentage="33" />
                            </div> 
                            <div className="ttm-progress-bar style1">
                                <h3 className="progressbar-title">Wash, fold and seal your clothes in a clear plastic bag</h3>
                                <ProgressBar  rect  percentage="66" />
                            </div> 
                            <div className="ttm-progress-bar style1">
                                <h3 className="progressbar-title">We return your clothes at your doo</h3>
                                <ProgressBar  rect  percentage="100" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section className="ttm-row faq-section clearfix">
                <div className="container-fluid">
                    <div className="row g-0">
                        <div className="col-lg-3">
                            <div className="col-bg-img-one ttm-bg ttm-col-bgimage-yes bg-theme-SkinColor z-index-1">
                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer spacing-1"  style={{ backgroundImage: 'url("images/bg-image/col-bgimage-2.png")' }}></div>
                                <div className="layer-content">
                                    <div className="section-title style4">
                                        <div className="title-header">
                                            <h3>100%</h3>
                                            <h2 className="title">Satisfaction guarantee</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <img className="img-fluid ttm-equal-height-image" src="images/bg-image/col-bgimage-2.png" alt="col-bgimage-9" />
                        </div>
                        <div className="col-lg-4">
                            <div className="col-bg-img-one ttm-bg ttm-col-bgimage-yes z-index-1">
                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer spacing-1"  style={{ backgroundImage: 'url("images/bg-image/col-bgimage-1.png")' }}></div>
                                <div className="layer-content">
                                </div>
                            </div>
                            <img className="img-fluid ttm-equal-height-image" src="images/bg-image/col-bgimage-2.png" alt="col-bgimage-9" />
                        </div>
                        <div className="col-lg-5">
                            <div className="section-title pt-90 pb-60 pl-60">
                                <div className="title-header">
                                    <h3>What Were Offering</h3>
                                    <h2 className="title">How we <span className="webkit-content">[Work]</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>      */}

            <section className="ttm-row padding_bottom_zero-section clearfix">
                <div className="container">
                    <div className="row box-shadow p-70 border-radius-8 bg-theme-WhiteColor g-0 pl-lg-15 pr-lg-15">
                        <div className="col-lg-6 align-self-center mt_90">
                            <span className="ttm-call-to-arrow">
                                <h3>Get a free <br /> quotes</h3>
                                <img className="img-fluid" src="images/arrow-text.png" alt="product-icon" />
                            </span>
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>Get in touch today</h3>
                                    <h2 className="title">Lets talk with <span className="webkit-content">[Expertise]</span></h2>
                                </div>
                            </div>
                            <div className="featured-icon-box icon-align-before-title style5">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon-support"></i>
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-desc">
                                        <p>Choose a plan to save, unique service options, and to view many additional services</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="ttm-tabs ttm-tab-style-02 border-left pl-75 border-left-0 pl-lg-0">
                                <Tabs>
                                    <TabList className="tabs">
                                        <Tab className="tab">
                                            <a>
                                            <i className="flaticon flaticon-research"></i>
                                                <h3>Request an estimate</h3>
                                            </a>
                                        </Tab>
                                    </TabList> 
                                    <div className="content-tab">                                
                                        <TabPanel>
                                            <form id="login_form" className="login_form wrap-form">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="text-left">
                                                            {/* <button type="submit" id="submit" className="mt-10 submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" value> */}
                                                            <a href={'/Laundry_services'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark mt-5"> Schedule a Pickup </a>
                                                            {/* </button> */}
                                                            <div className="text-form">
                                                                <div className="query-text">Been here before? 
                                                                    <u>
                                                                        <a href="#" className="fw-700">Login</a>
                                                                    </u>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </TabPanel>
                                        <TabPanel>
                                            <form id="login_form" className="login_form wrap-form">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <label>
                                                            <i className="ti ti-user"></i>
                                                            <input type="text" id="txtname" placeholder="Username" />
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label>
                                                            <i className="ti ti-email"></i>
                                                            <input type="email" id="txtemail" placeholder="Email Address" />
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label>
                                                            <i className="ti ti-lock"></i>
                                                            <input type="password" id="password" placeholder="Password"/>
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label>
                                                            <i className="ti ti-lock"></i>
                                                            <input type="password" id="cpassword" placeholder="Confirm Password *"/>
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label>
                                                            <i className="ti ti-mobile"></i>
                                                            <input type="tel" id="txtphone" placeholder="Phone Number"/>
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label>
                                                            <div className="d-md-flex justify-content-between">
                                                                <div className="cookies-checkbox mt-5">
                                                                    <div className="d-flex flex-row align-items-center justify-content-start">
                                                                        <input className="w-auto mr-10" id="cookies-consent" name="cookies-consent" type="checkbox" value="yes" />
                                                                        <span>you accept our Terms and Conditions and Privacy Policy</span>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-15">
                                                                    <p>Already have an account?</p>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <label>
                                                            <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                                            type="submit">Sign up</button>
                                                        </label>
                                                    </div>
                                                </div>
                                            </form>
                                        </TabPanel>
                                    </div>       
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* padding_zero-section */}
            {/* <section className="ttm-row testimonial-section bg-theme-GreyColor bg-img1 mt-lg-0 mt_100 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}> */}
                {/* <div className="container-fluid">
                    
                    <div className="row">
                        <div className="col-lg-12 pl-80 pr-80 pl-lg-15 pr-lg-15">
                            <div className="section-title title-style-center_text mb-0">
                                <div className="title-header">
                                    <h2 className="title">What they <span className="webkit-content">[Say ?]</span></h2>
                                </div>
                            </div>
                            <Slider className="row slick_slider slick-arrows-style4 ttm-boxes-spacing-10px" {...slick_slider} slidesToShow={3}arrows={true}
                            autoplay={false} responsive= { [{ breakpoint: 1199, settings: {slidesToShow: 2,slidesToScroll: 2 }},
                            {breakpoint: 991, settings: { slidesToShow: 2, slidesToScroll: 1 }} ,
                            {breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                            <div className="col-lg-12">
                                <div className="testimonials style1 text-center mt-lg-30"> 
                                    <div className="testimonial-content">
                                        <blockquote>My suits came back quickly and felt newer than the day I bought them great
                                        that was easy to use,best part they came to pick them up and drop them off for me.</blockquote>
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" />
                                            </div>
                                        </div>
                                        <div className="testimonial-caption">
                                            <label>Charles Altman</label>                                             
                                            <p>Chairman and founder</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="testimonials style1 text-center mt-lg-30"> 
                                    <div className="testimonial-content">
                                        <blockquote>This service was very helpful and convenient. The guy came out and 
                                        picked up the laundry for dry cleaning. Good price compared to most of Miami.</blockquote>
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" />
                                            </div>
                                        </div>
                                        <div className="testimonial-caption">
                                            <label>Peter Daniels</label>                                             
                                            <p>Businessman</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="testimonials style1 text-center mt-lg-30"> 
                                    <div className="testimonial-content">
                                        <blockquote>My suits came back quickly and felt newer than the day I bought them great
                                        that was easy to use,best part they came to pick them up and drop them off for me.</blockquote>
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" />
                                            </div>
                                        </div>
                                        <div className="testimonial-caption">
                                            <label>Judy Wells</label>                                             
                                            <p>Chairman and founder</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="testimonials style1 text-center mt-lg-30"> 
                                    <div className="testimonial-content">
                                        <blockquote>The app is so straightforward and easy to follow. Excellent service
                                        and quality, prices than many dry cleaners around this area. I clean all my 
                                        clothes with them.</blockquote>
                                        <div className="testimonial-avatar">
                                            <div className="testimonial-img">
                                                <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" />
                                            </div>
                                        </div>
                                        <div className="testimonial-caption">
                                            <label>Joel Elliott</label>                                             
                                            <p>Businessman</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </Slider>
                        </div>
                    </div>
                </div> */}
                {/* <div className="container">
                    <Slider className="row pt-15 pb-15 slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={6}arrows={false}
                    autoplay={true} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 4,slidesToScroll: 2 }},
                        {breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 }} ,
                        {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-1-163x31.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-2-128x25.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-1-163x31.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-2-128x25.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-1-163x31.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-2-128x25.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="client-box ttm-box-view-boxed-logo">
                                <div className="client">
                                    <div className="ttm-client-logo-tooltip">
                                        <img src="./images/client/client-2-128x25.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div> */}
            {/* </section> */}
            {/* padding_zero-section end */}

            {/* fid-section */}
            {/* <section className="ttm-row bg-theme-DarkColor padding_bottom_zero-section mb_65 clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title border p-70 border-radius-8 mb-0 pb-40 pl-md-15 pr-md-15 p-lg-30 pb-lg-10">
                                <div className="title-header">
                                    <h2 className="title">Get in <span className="webkit-content">[Clean]</span></h2>
                                </div>
                                <div className="featured-icon-box text-theme-WhiteColor icon-align-before-title style6">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                                        <a href="#" className="mega-menu-link">
                                            <i className="flaticon-up-right-arrow-1"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>  */}
            {/* fid-section end */}


            <Footer/>
                        
          </div>
        
        )
    }
}

export default Home2;