import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Slider from 'react-slick';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import Header2 from '../components/layout/Header2';
import ProgressBar from 'react-animated-progress-bar';
import CountUp from 'react-countup';


export class Leather_cleaning extends Component {

    constructor() {
        super();
        this.state = {
          name: "React"
        };
        this.onChangeValue = this.onChangeValue.bind(this);

    }
    
    onChangeValue(event) {
        console.log(event.target.value);
    }

    render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [{
      
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        return (

            <div className="site-main">
                <Header2/>
            
                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Leather cleaning</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;/ Services</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Leather cleaning</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>

                <div className="sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left border-right pr-45 pr-lg-15">
                                <aside className="widget widget-nav-menu">
                                    <h3 className="widget-title">Our services</h3>
                                    <ul>
                                        <li className="d-flex">Alterations & repairs</li>
                                        <li className="d-flex">Commercial laundry</li>
                                        <li className="d-flex">Dry cleaning</li>
                                        <li className="d-flex">Laundry services</li>
                                        <li className="d-flex">Leather cleaning</li>
                                        <li className="d-flex">Steam iron</li>
                                    </ul>
                                </aside>
                                <aside className="widget image-box">
                                    <div className="widget_img">
                                        <img src="./images/single-img-8-369x154.jpg" />
                                    </div>
                                    <div className="featured-imagebox featured-imagebox-service style2">
                                        <div className="featured-image-box-wrapper">
                                            <div className="featured-thumbnail">
                                                <img width="41" height="41" src="./images/whats-up.png" />
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <h2>Call us for all your laundry needs</h2>
                                    </div>
                                </aside>
                                <aside className="widget widget-download">
                                    <h3 className="widget-title">Download</h3>
                                    <ul className="download">
                                        <li>
                                            <a><h3>Repair</h3>
                                            <i className="far fa-file-pdf" /></a>
                                        </li>
                                        <li>
                                            <a><h3>Maintenance</h3>
                                            <i className="far fa-file-pdf" /></a>
                                        </li>
                                    </ul>
                                    <h3 className="widget-title">Share now</h3>
                                    <div className="ttm-social-links-wrapper mt-20">
                                        <ul className="social-icons">
                                            <li className="social-facebook">
                                                <a target="_blank" href="https://www.facebook.com/preyantechnosys19"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-twitter">  
                                                <a target="_blank" href="https://twitter.com/PreyanTechnosys"><i className="fab fa-twitter" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-pinterest">
                                                <a target="_blank" href="https://dribbble.com/PreyanTechnosys"><i className="fab fa-dribbble" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-instagram">
                                                <a target="_blank" href="https://www.instagram.com/preyan_technosys/"><i className="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-lg-8 widget-area sidebar-right pl-45 pl-lg-15">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="ttm-service-classic-content">
                                            <h2 className="pt-0">Leather cleaning</h2>
                                            <p>We care about your importance to cleanliness. We care about the health you will feel when you return
                                            to a clean home. Because cleanliness is our business. Our expertise is based on experience and we have
                                            systems suitable for all types of cleaning jobs. Every member of our team is trained and undergoes regular
                                            health and safety checks to ensure your safety and we are nea certified and we keep up to date with the
                                            latest guidance</p>
                                        </div>
                                        <div className="col-lg-12 col-md-12 mt-30">
                                            <img src="./images/single-img-16-836x469.png" />
                                        </div>
                                        <div className="mt-30 bg-theme-SkinColor border-radius-8 text-theme-WhiteColor p-40">
                                            <h2 className="fs-24">Our laundry detailing solutions include: </h2>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <ul className="ttm-list style3">
                                                        <li>
                                                            <i className="flaticon-read"></i>
                                                            <span className="ttm-list-li-content">Experienced team</span>
                                                        </li>
                                                        <li>
                                                            <i className="flaticon-read"></i>
                                                            <span className="ttm-list-li-content">Water repellent for only applications</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul className="ttm-list style3">
                                                        <li>
                                                            <i className="flaticon-read"></i>
                                                            <span className="ttm-list-li-content">Repairs and alterations for leather, suede</span>
                                                        </li>
                                                        <li>
                                                            <i className="flaticon-read"></i>
                                                            <span className="ttm-list-li-content">Get the leather garment cleaning?</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ttm-service-classic-content">
                                            <h2>Personal experience</h2>
                                            <p>Apply mild leather cleaner to the surface, work with a lint-free cloth, and dry. You can also use a
                                            damp lint-free cloth. After letting it air dry, apply a conditioner to improve the leather's appearance.</p>
                                        </div>
                                        <div className="row mt-30">
                                            <div className="col-lg-12">
                                                <div className="ttm-progress-bar style1">
                                                    <h3 className="progressbar-title">Satisfied clients</h3>
                                                    <ProgressBar  rect  percentage="88" />
                                                </div> 
                                                <div className="ttm-progress-bar style1">
                                                    <h3 className="progressbar-title">Eco friendly</h3>
                                                    <ProgressBar  rect  percentage="72" />
                                                </div> 
                                                <div className="ttm-progress-bar style1">
                                                    <h3 className="progressbar-title">Great efficiency</h3>
                                                    <ProgressBar  rect  percentage="80" />
                                                </div>
                                            </div> 
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                            <Slider className="row slick_slider slick-arrows-style1 ttm-boxes-spacing-10px" {...slick_slider} slidesToShow={2}arrows={true}
                                                autoplay={false} responsive= { [{ breakpoint: 1199, settings: {slidesToShow: 2,slidesToScroll: 2 }},
                                                {breakpoint: 991, settings: { slidesToShow: 2, slidesToScroll: 1 }} ,
                                                {breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                                                <div className="col-lg-12">
                                                    <div className="testimonials style1 text-center"> 
                                                        <div className="testimonial-content">
                                                            <blockquote>My suits came back quickly and felt newer than the day I bought them great
                                                            that was easy to use,best part they came to pick them up and drop them off for me.</blockquote>
                                                            <div className="testimonial-avatar">
                                                                <div className="testimonial-img">
                                                                    <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" />
                                                                </div>
                                                            </div>
                                                            <div className="testimonial-caption">
                                                                <label>Charles Altman</label>                                             
                                                                <p>Chairman and founder</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="testimonials style1 text-center"> 
                                                        <div className="testimonial-content">
                                                            <blockquote>This service was very helpful and convenient. The guy came out and 
                                                            picked up the laundry for dry cleaning. Good price compared to most of Miami.</blockquote>
                                                            <div className="testimonial-avatar">
                                                                <div className="testimonial-img">
                                                                    <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" />
                                                                </div>
                                                            </div>
                                                            <div className="testimonial-caption">
                                                                <label>Peter Daniels</label>                                             
                                                                <p>Businessman</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="testimonials style1 text-center"> 
                                                        <div className="testimonial-content">
                                                            <blockquote>My suits came back quickly and felt newer than the day I bought them great
                                                            that was easy to use,best part they came to pick them up and drop them off for me.</blockquote>
                                                            <div className="testimonial-avatar">
                                                                <div className="testimonial-img">
                                                                    <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" />
                                                                </div>
                                                            </div>
                                                            <div className="testimonial-caption">
                                                                <label>Judy Wells</label>                                             
                                                                <p>Chairman and founder</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="testimonials style1 text-center"> 
                                                        <div className="testimonial-content">
                                                            <blockquote>The app is so straightforward and easy to follow. Excellent service
                                                            and quality, prices than many dry cleaners around this area. I clean all my 
                                                            clothes with them.</blockquote>
                                                            <div className="testimonial-avatar">
                                                                <div className="testimonial-img">
                                                                    <img width="60" height="60" src="./images/testimonial/testimonial-1-150x150.jpg" />
                                                                </div>
                                                            </div>
                                                            <div className="testimonial-caption">
                                                                <label>Joel Elliott</label>                                             
                                                                <p>Businessman</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>
                
            <Footer/>
                        
            </div>
          )
      }
   }

export default Leather_cleaning;