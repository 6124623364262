import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Slider from 'react-slick';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';


export class Dry_cleaning extends Component {

    constructor() {
        super();
        this.state = {
          name: "React"
        };
        this.onChangeValue = this.onChangeValue.bind(this);

    }
    
    onChangeValue(event) {
        console.log(event.target.value);
    }

    render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [{
      
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        return (

            <div className="site-main">
                <Header/>
            
                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Dry cleaning</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;/ Services</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Dry cleaning</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>

                <div className="sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left border-right pr-45 pr-lg-15">
                                <aside className="widget widget-nav-menu">
                                    <h3 className="widget-title">Our services</h3>
                                    <ul>
                                        <li className="d-flex">Alterations & repairs</li>
                                        <li className="d-flex">Commercial laundry</li>
                                        <li className="d-flex">Dry cleaning</li>
                                        <li className="d-flex">Laundry services</li>
                                        <li className="d-flex">Leather cleaning</li>
                                        <li className="d-flex">Steam iron</li>
                                    </ul>
                                </aside>
                                <aside className="widget image-box">
                                    <div className="widget_img">
                                        <img src="./images/single-img-8-369x154.jpg" />
                                    </div>
                                    <div className="featured-imagebox featured-imagebox-service style2">
                                        <div className="featured-image-box-wrapper">
                                            <div className="featured-thumbnail">
                                                <img width="41" height="41" src="./images/whats-up.png" />
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <h2>Call us for all your laundry needs</h2>
                                    </div>
                                </aside>
                                <aside className="widget widget-download">
                                    <h3 className="widget-title">Download</h3>
                                    <ul className="download">
                                        <li>
                                            <a><h3>Repair</h3>
                                            <i className="far fa-file-pdf" /></a>
                                        </li>
                                        <li>
                                            <a><h3>Maintenance</h3>
                                            <i className="far fa-file-pdf" /></a>
                                        </li>
                                    </ul>
                                    <h3 className="widget-title">Share now</h3>
                                    <div className="ttm-social-links-wrapper mt-20">
                                        <ul className="social-icons">
                                            <li className="social-facebook">
                                                <a target="_blank" href="https://www.facebook.com/preyantechnosys19"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-twitter">  
                                                <a target="_blank" href="https://twitter.com/PreyanTechnosys"><i className="fab fa-twitter" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-pinterest">
                                                <a target="_blank" href="https://dribbble.com/PreyanTechnosys"><i className="fab fa-dribbble" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-instagram">
                                                <a target="_blank" href="https://www.instagram.com/preyan_technosys/"><i className="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-lg-8 widget-area sidebar-right pl-45 pl-lg-15">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="ttm-service-classic-content">
                                            <h2 className="pt-0">Laundry service</h2>
                                            <p>Dry cleaning clothes is often one of the top items on our to-do list, but
                                            we rarely think about how our most valuable shirts, pants and uniforms 
                                            actually get cleaned. The cleaning of clothes without using water, unlike 
                                            traditional laundering which uses water and detergent. It may sound strange, 
                                            but dry cleaning actually cleans your clothes.</p>
                                        </div>
                                        <div className="bg-theme-GreyColor mt-40">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="featured-icon-box style12">
                                                        <div className="featured-num-box">
                                                            <div className="process-num">
                                                                <span className="number">1</span>
                                                            </div>
                                                            <div className="featured-content">
                                                                <div className="featured-title">
                                                                    <h3>Dry cleaning</h3>
                                                                </div>
                                                            </div>
                                                            <div className="featured-desc">
                                                                <p>Our process is stain resistant yet gentle on your clothes.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="featured-icon-box style12">
                                                        <div className="featured-num-box">
                                                            <div className="process-num">
                                                                <span className="number">2</span>
                                                            </div>
                                                        
                                                            <div className="featured-content">
                                                                <div className="featured-title">
                                                                    <h3>Free pickup</h3>
                                                                </div>
                                                            </div>
                                                            <div className="featured-desc">
                                                                <p>We keep your dress looking as good as the day you wore it.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="featured-icon-box style12">
                                                        <div className="featured-num-box">
                                                            <div className="process-num">
                                                                <span className="number">3</span>
                                                            </div>
                                                            <div className="featured-content">
                                                                <div className="featured-title">
                                                                    <h3>Couture care</h3>
                                                                </div>
                                                            </div>
                                                            <div className="featured-desc">
                                                                <p>Garments are inspected by our most senior inspector.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-20 mb-30">
                                            <div className="ttm-service-classic-content">
                                                <h2>Service overview</h2>
                                                <p>Dry cleaning clothes is often one of the top items on our to-do list, but we rarely think about 
                                                how our most valuable shirts, pants and uniforms actually get cleaned. The cleaning of clothes 
                                                without using water</p>
                                            </div>
                                            <div className="row mt-20">
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="featured-icon-box icon-align-top-content style3">
                                                        <div className="ttm-iconbox-wrapper">
                                                            <div className="ttm-subheading">
                                                                <h3>Claim Support</h3>
                                                                <h2>24/7 Supports</h2>
                                                            </div>
                                                            <div className="featured-desc">
                                                                <p>We love to help you to get your clothes fresh and clean so we are here for you 24/7 for any question, concern or suggestion.</p>
                                                            </div>
                                                            <div className="featured-icon">
                                                                <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-onlytxt ttm-icon_element-size-lg">
                                                                    <i className="flaticon-24-hours-support"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-sm-12">
                                                    <div className="featured-icon-box icon-align-top-content style3">
                                                        <div className="ttm-iconbox-wrapper">
                                                            <div className="ttm-subheading">
                                                                <h3>100% Reliable</h3>
                                                                <h2>Offers & cares</h2>
                                                            </div>
                                                            <div className="featured-desc">
                                                                <p>Every item sent to dry cleaning or Laundromat, hands of experts who take care of your garments in detailed way.</p>
                                                            </div>
                                                            <div className="featured-icon">
                                                                <div className="ttm-icon ttm-icon_element-color-skincolor ttm-icon_element-onlytxt ttm-icon_element-size-lg">
                                                                    <i className="flaticon-offer"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Tabs>
                                            <TabList className="tabs">
                                                <Tab className="tab">
                                                    <a>
                                                    <i className="flaticon flaticon-research"></i>
                                                        <h3 className="fs-36">Leave a reply</h3>
                                                        <p>Your email address will not be published. Required fields are marked</p>
                                                    </a>
                                                </Tab>
                                            </TabList> 
                                            <div className="content-tab">                                
                                                <TabPanel>
                                                    <form id="login_form" className="login_form wrap-form">
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <label>
                                                                    <input type="text" id="txtname" placeholder="Your Name" />
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <label>
                                                                    <input type="email" id="txtemail" placeholder="Email Address" />
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <label>
                                                                    <input id="password" placeholder="Phone Number"/>
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label>
                                                                <textarea name="message" rows="4" placeholder="YourMessage" required=""></textarea>
                                                                </label>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="text-left">
                                                                    <button type="submit" id="submit" className="mt-10 submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" value>
                                                                        Leave A Comment
                                                                    </button>
                                                                    <div className="text-form">
                                                                        <p className="comment-cookies">
                                                                            <input id="comment-cookies-consent" name="comment-cookies-consent" type="checkbox" value="yes" className="me-2" />
                                                                            <label className="d-inline" htmlFor="comment-cookies-consent">I agree that my submitted data is being collected and stored</label>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </TabPanel>
                                                <TabPanel>
                                                    <form id="login_form" className="login_form wrap-form">
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-user"></i>
                                                                    <input type="text" id="txtname" placeholder="Username" />
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-email"></i>
                                                                    <input type="email" id="txtemail" placeholder="Email Address" />
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-lock"></i>
                                                                    <input type="password" id="password" placeholder="Password"/>
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <label>
                                                                    <i className="ti ti-lock"></i>
                                                                    <input type="password" id="cpassword" placeholder="Confirm Password *"/>
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label>
                                                                    <i className="ti ti-mobile"></i>
                                                                    <input type="tel" id="txtphone" placeholder="Phone Number"/>
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label>
                                                                    <div className="d-md-flex justify-content-between">
                                                                        <div className="cookies-checkbox mt-5">
                                                                            <div className="d-flex flex-row align-items-center justify-content-start">
                                                                                <input className="w-auto mr-10" id="cookies-consent" name="cookies-consent" type="checkbox" value="yes" />
                                                                                <span>you accept our Terms and Conditions and Privacy Policy</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-15">
                                                                            <p>Already have an account?</p>
                                                                        </div>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <label>
                                                                    <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                                                    type="submit">Sign up</button>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </TabPanel>
                                            </div>       
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>
                
            <Footer/>
                        
            </div>
          )
      }
   }

export default Dry_cleaning;