import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import Slider from 'react-slick';
import { Footer } from '../components/layout/Footer';
import Header2 from '../components/layout/Header2';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export class Contact_02 extends Component {
        render() {
            var slick_slider = {
              dots: false,
              arrow: false,
              autoplay: true,
              infinite: true,
              speed: 1000,
              slidesToShow: 4,
              slidesToScroll: 1,
              rows: 1,
      
              responsive: [{
          
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
            };
              var slick_slider_ltr = {
              infinite: true,
              arrows: false,
              dots: false,                   
              autoplay: true,
              centerMode : false,
              autoplaySpeed: 0,
              speed: 9000,
              cssEase: "linear",
              pauseOnHover: true,
              slidesToShow: 3,
              slidesToScroll: 1,
              }
      
              var slick_slider_rtl = {
              infinite: true,
              arrows: false,
              dots: false,                   
              autoplay: true,
              centerMode : false,
              rtl: true,
              autoplaySpeed: 0,
              speed: 9000,
              cssEase: "linear",
              pauseOnHover: true,
              slidesToShow: 3,
              slidesToScroll: 1,
              };
        
        return (
            <div className="site-main">
                <Header2 />

                {/* grid-section */} 
                <section className="ttm-row padding_zero-section clearfix">
                    <div className="container-fluid">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="map">
                                    <iframe height="675" width="100%" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12609.73108660754!2d-122.47279800000001!3d37.803331!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808586e6302615a1%3A0x86bd130251757c00!2sStorey%20Ave%2C%20San%20Francisco%2C%20CA%2094129!5e0!3m2!1sen!2sus!4v1591859311334!5m2!1sen!2sus" />
                                </div> 
                            </div>
                        </div>
                        {/* row end */}
                    </div>
                </section>
                {/* grid-section end */} 

                <section className="ttm-row padding_top_zero-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                <div className="featured-icon-box icon-align-before-content bg-theme-DarkColor style11">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl text-theme-SkinColor">
                                            <i className="flaticon-palm-islands"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Dubai</h3>
                                        </div>
                                    </div>
                                    <div className="featured-desc">
                                        <h3>Call us on: <a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                        <h3 className="mb-30">Email: <a href="#">info@laundrolift.com</a></h3>
                                        <h3 className="d-inline">Address: </h3> A-1, envanto headquarters melbourne, australia.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                <div className="featured-icon-box icon-align-before-content bg-theme-SkinColor style11">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl text-theme-WhiteColor">
                                            <i className="flaticon-toronto"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Canada</h3>
                                        </div>
                                    </div>
                                    <div className="featured-desc">
                                        <h3>Call us on: <a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                        <h3 className="mb-30">Email: <a href="#">info@laundrolift.com</a></h3>
                                        <h3 className="d-inline">Address: </h3> A-1, envanto headquarters melbourne, australia.
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                                <div className="featured-icon-box icon-align-before-content bg-theme-DarkColor style11 mt-lg-15">
                                    <div className="featured-icon">
                                        <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl text-theme-SkinColor">
                                            <i className="flaticon-london-eye"></i>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>London</h3>
                                        </div>
                                    </div>
                                    <div className="featured-desc">
                                        <h3>Call us on: <a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                        <h3 className="mb-30">Email: <a href="#">info@laundrolift.com</a></h3>
                                        <h3 className="d-inline">Address: </h3> A-1, envanto headquarters melbourne, australia.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row padding_top_zero-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title text-center">
                                    <div className="title-header">
                                        <h2 className="title">Let’s talk with <span className="webkit-content">[Expertise]</span></h2>
                                    </div>
                                    <div className="title-desc">
                                        <p>You need to offer something that makes you unique. You need to do something
                                        to make every customer experience remarkable,</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-8 align-self-center mt_140">
                                <span className="ttm-call-to-arrow-1">
                                    <h3>Get a free <br></br> quotes</h3>
                                    <img className="img-fluid" src="images/arrow-text.png" alt="product-icon" />
                                </span>
                                <div className="ttm-tabs ttm-tab-style-02">
                                    
                                    <Tabs>
                                        <div className="content-tab">                                
                                            <TabPanel>
                                                <form id="login_form" className="login_form wrap-form">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <input type="text" id="txtname" placeholder="Your Name" />
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <input type="email" id="txtemail" placeholder="Email Address" />
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <input type="password" id="password" placeholder="Phone Number"/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <input type="password" id="cpassword" placeholder="Zip Code *"/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <label>
                                                                <input type="tel" id="txtphone" placeholder="Services"/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <label>
                                                            <textarea name="message" rows="4" placeholder="YourMessage" required=""></textarea>
                                                            </label>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="text-left">
                                                                <button type="submit" id="submit" className="mt-10 mb-lg-30 ml-lg-10 submit ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" value>
                                                                    Submit Quote
                                                                </button>
                                                                <div className="text-form">
                                                                    <div className="query-text mt-md-0 pl-md-15 pb-md-30">Been here before? 
                                                                        <u>
                                                                            <a href="#" className="fw-700"> Check your query</a>
                                                                        </u>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </TabPanel>
                                            <TabPanel>
                                                <form id="login_form" className="login_form wrap-form">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <i className="ti ti-user"></i>
                                                                <input type="text" id="txtname" placeholder="Username" />
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <i className="ti ti-email"></i>
                                                                <input type="email" id="txtemail" placeholder="Email Address" />
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <i className="ti ti-lock"></i>
                                                                <input type="password" id="password" placeholder="Password"/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <label>
                                                                <i className="ti ti-lock"></i>
                                                                <input type="password" id="cpassword" placeholder="Confirm Password *"/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <label>
                                                                <i className="ti ti-mobile"></i>
                                                                <input type="tel" id="txtphone" placeholder="Phone Number"/>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <label>
                                                                <div className="d-md-flex justify-content-between">
                                                                    <div className="cookies-checkbox mt-5">
                                                                        <div className="d-flex flex-row align-items-center justify-content-start">
                                                                            <input className="w-auto mr-10" id="cookies-consent" name="cookies-consent" type="checkbox" value="yes" />
                                                                            <span>you accept our Terms and Conditions and Privacy Policy</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-15">
                                                                        <p>Already have an account?</p>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <label>
                                                                <button className="submit w-100 ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor" 
                                                                type="submit">Sign up</button>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </form>
                                            </TabPanel>
                                        </div>       
                                    </Tabs>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="">
                                    <div className="featured-icon-box icon-align-before-content style9">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-location"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Our location</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>9439 Sunset Lane Palm Beach Gardens, 6 Eagle St Oxnard, CA 93035</p>
                                            </div>
                                        </div>
                                        <div className="ttm-iconbox">
                                            <a href="Single-Case-Studies.html" />
                                        </div>
                                    </div>
                                    <div className="featured-icon-box icon-align-before-content style9">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-email"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Email us</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <a className="d-block" href="#">laundrolift.support@example.com</a>
                                                <a href="#">www.laundrolift.com</a>
                                            </div>
                                        </div>
                                        <div className="ttm-iconbox">
                                            <a href="Single-Case-Studies.html" />
                                        </div>
                                    </div>
                                    <div className="featured-icon-box icon-align-before-content style9">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-operator"></i>
                                            </div>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Phone number</h3>
                                            </div>
                                            <div className="featured-desc">
                                                <p>+18 - 2222 - 3555</p>
                                                <p>+18 - 2222 - 3555</p>
                                            </div>
                                        </div>
                                        <div className="ttm-iconbox">
                                            <a href="Single-Case-Studies.html" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row contact_2_section bg-theme-GreyColor clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="ttm-single-img-1 pb-md-50">
                                    <div className="ttm_single_image-wrapper">
                                        <img src="./images/single-img-6-503x413.png" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>What We’re Offering</h3>
                                        <h2 className="title">How we <span className="webkit-content">[Work]</span></h2>
                                    </div>
                                </div>
                                <h3 className="fs-20">Sed do eiusmod tempor incididunt ut labore et dolore.</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua quis ipsum suspendisse. ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                <div className="separator"><div className="sep-line mt-4 mb-4"></div></div>
                                <h3 className="fs-20">Sed do eiusmod tempor incididunt ut labore et dolore.</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                                aliqua quis ipsum suspendisse. ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. </p>
                                <div className="separator"><div className="sep-line mt-4 mb-4"></div></div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row padding_top_zero-section marque-section overflow-hidden">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 ttm-marquebox-wrapper">
                                <ul role="list" className="marquebox-list">
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Dry cleaning</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Alterations & repairs</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Commercial laundry</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Laundry services</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Alterations & repairs</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Laundry services</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Commercial laundry</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                

                <Footer/>

            </div>
        )
    }
}


export default Contact_02;