import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Slider from 'react-slick';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import Header2 from '../components/layout/Header2';
import CountUp from 'react-countup';
import Modal from '../components/modal/Modal';
import api from '../api';
// import StripeCheckOut from './checkout';
import { loadStripe } from '@stripe/stripe-js';
import { withRouter } from 'react-router-dom';
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardHeader,
    MDBCardFooter,
    MDBBtn
} from 'mdbreact';

import {
    db,
    auth,
    createUser,
    login,
    signout,
    resetPwd,
    onAuthStateChanged,
    addOrUpdateDoc,
    getDocument,
    getDocumentWhere,
    getAllDocuments
} from '../firebase';
import templates from '../emailTemplates.js'



export class Laundry_services extends Component {

    constructor() {
        super();
        this.state = {
            name: "React",
            user: null,
            view: 1,
            ordersView: 1,
            isSameAddress: true,
            existingOrders: [],
            awaitingConfirmation: [],
            awaitingPickup: [],
            inProgress: [],
            complete: [],
            cancelled: [],
            modalData: {},
            showModal: false,
            showOrderUpdateModal: false,
            newOrder: {
                date: new Date(),
                time: "",
                address: "",
                status: "",
                orderNumber: "",
                weight: "",
                pricePerLbs: "",
                roundLiftFee: "",
                convenienceFee: ""
            },
            timeOptions: [],
            idToken: null
        };
        this.onChangeValue = this.onChangeValue.bind(this);

    }

    componentDidMount() {
        this.unsubscribeAuth = onAuthStateChanged(auth, (user) => {

            console.log("user: ", user)

            if (user) {

                const uid = user.uid;

                user.getIdToken().then((idTk) => {
                    this.setState({ idToken: idTk });
                })

                getDocumentWhere("users", "uid", uid).then((uDataRes) => {
                    console.log("uDataRes: ", uDataRes)
                    if (uDataRes.status) {
                        this.setState({ user: uDataRes.data[0], view: 1 }, () => this.getUserOrders());
                    }
                })
            }
        });
    }



    checkStatus() {
        const params = new URLSearchParams(window.location.search);
        const status = params.get('status');
        let transactionNumber = params.get('transactionNumber');
        if (status === '1') {
            this.setState({
                status,
                modalData: {
                    toggleModal: () => { },
                    title: "Payment Submitted!",
                    htmlBody: `<div style="display: flex; flex-direction: column;">
                                <div style="margin: 15px">
                                    We are proccessing your payment. 
                                </div>
                                <div style="margin: 15px">Your Transaction Number Is : ${transactionNumber}
                                <div style="margin: 15px">
                                    Thank You For Using LaundroLift!
                                </div>
                            </div>`,
                    onNo: () => { },
                    onOk: () => {
                        const { history } = this.props;
                        const cleanUrl = window.location.pathname;
                        history.replace(cleanUrl);
                    },
                    noText: "",
                    okText: "Ok"
                }, showModal: true
            });
        } else if (status === '0') {
            this.setState({
                status,
                modalData: {
                    toggleModal: () => { },
                    title: "Ooops!",
                    htmlBody: `<div style="display: flex; flex-direction: column;">
                                <div style="margin: 15px">
                                    We could not proccess your payment. 
                                </div>
                                <div style="margin: 15px">
                                    Please try again!
                                </div>
                                <div style="margin: 15px">
                                    If You need assistance pleace contact us at 
                                    <a href="tel:+19085296733">+1 (908) 529-6733</a> 
                                    or via email at 
                                    <a href="mailto:info@laundrolift.com">info@laundrolift.com</a>
                                </div>
                            </div>`,
                    onNo: () => { },
                    onOk: () => {
                        const { history } = this.props;
                        const cleanUrl = window.location.pathname;
                        history.replace(cleanUrl);
                    },
                    noText: "",
                    okText: "Ok"
                }, showModal: true
            });
        }
    }

    handleDateChange = (event) => {
        const newDate = event.target.value;
        let [year, month, day] = newDate.split('-');
        const selectedDate = new Date(`${year}/${month}/${day}`);
        const today = new Date();
        const timeInput = document.getElementById('time');
        console.log("formatted d: ", `${year}-${month}-${day}T00:00:00Z`, "newDate: ", newDate, "selectedDate ", selectedDate.toDateString(), "today ", today.toDateString())

        const timeOptions = timeInput.options;
        var currentTimes = this.state.timeOptions;

        if (selectedDate.toDateString() === today.toDateString()) {

            const now = today.getHours() * 60 + today.getMinutes() + 60;

            for (let i = 0; i < currentTimes.length; i++) {
                var tOption = currentTimes[i];
                const time = tOption.value.split(':');
                const timeInMinutes = parseInt(time[0]) * 60 + parseInt(time[1]);
                if (timeInMinutes < now) {
                    tOption.available = false;
                } else {
                    tOption.available = true;
                }
            }
        } else {
            for (let i = 0; i < currentTimes.length; i++) {
                var tOption = currentTimes[i];
                tOption.available = true;
            }
        }

        this.setState({ timeOptions: currentTimes });
    };

    updateTimeOptions = (minTime) => {
        setTimeout(() => {
            const timeInput = document.getElementById('time');
            const dateInput = document.getElementById('date');
            const today = new Date().toISOString().split('T')[0];
            console.log("today: ", new Date().toISOString());
            if (dateInput?.min) {
                dateInput.min = today;
            }

            // Array of excluded times in HH:mm format
            // const excludedTimes = ['07:00', '12:00', '15:30'];

            // // Function to check if a time is excluded
            // const isExcludedTime = (time) => excludedTimes.includes(time);

            const options = [];

            // Generate options for times between 6am and 5pm
            for (let hour = 6; hour <= 17; hour++) {
                for (let minute = 0; minute < 60; minute += 30) {
                    const time = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
                    // if (!isExcludedTime(time)) {
                    options.push(time);
                    // }
                }
            }

            var fOptions = [];
            options.forEach(option => {
                fOptions.push({ value: option, available: true })
            });
            this.setState({ timeOptions: fOptions });

        }, 2000)
    }

    onChangeValue = (event) => {
        console.log(event.target.value);
    }

    login = (event) => {
        event.preventDefault();

        var data = {
            email: event.target.elements.email.value,
            password: event.target.elements.password.value
        }

        login(data.email, data.password).then((res) => {
            console.log("loginRes: ", res);
            if (res.status) {
                getDocumentWhere("users", "uid", res.data.uid).then((uDataRes) => {
                    console.log("uDataRes: ", uDataRes)
                    if (uDataRes.status) {
                        this.setState({ user: uDataRes.data[0], view: 1 }, () => this.getUserOrders());
                    }
                })
            }
        })
    }


    getUserOrders() {
        console.log("user: ", this.state.user);
        getDocumentWhere("orders", "clientId", this.state.user.uid).then((res) => {
            console.log("getDocumentWhere('orders'): ", res);
            if (res.status && res.data?.length > 0) {
                var aConfif = [];
                var aPickup = [];
                var inProgress = [];
                var completed = [];
                var cancelled = [];
                res.data.forEach((el) => {
                    if (el.status == "Awaiting Confirmation") {
                        aConfif.push(el);
                    }
                    if (el.status == "Awaiting Pickup") {
                        aPickup.push(el);
                    }
                    if (el.status == "In Progress") {
                        inProgress.push(el);
                    }
                    if (el.status == "Completed") {
                        completed.push(el);
                    }
                    if (el.status == "Cancelled") {
                        cancelled.push(el);
                    }
                });

                this.setState({
                    existingOrders: res.data,
                    awaitingConfirmation: aConfif,
                    awaitingPickup: aPickup,
                    inProgress: inProgress,
                    complete: completed,
                    cancelled: cancelled
                }, () => {
                    this.checkStatus();
                    console.log(this.state.existingOrders);
                });


            }
        })
    }

    createAccount = async (event) => {
        event.preventDefault();

        try {
            var data = {
                email: event.target.elements.email.value,
                password: event.target.elements.password.value,
                confirmpassword: event.target.elements.confirmpassword.value,
                lastname: event.target.elements.lastname.value,
                firstname: event.target.elements.firstname.value,
                street: event.target.elements.street.value,
                city: event.target.elements.city.value,
                zip: event.target.elements.zip.value,
                state: 'PA',
                type: 1
            }

            createUser(data.email, data.password).then((res) => {
                console.log("createAccountRes: ", res);
                if (res.status) {
                    addOrUpdateDoc('users', res.data.uid, { ...data, uid: res.data.uid, accessToken: res.data.accessToken }).then((docres) => {
                        if (docres.status) {
                            console.log("data: ", data);
                            this.setState({ user: { ...data, uid: res.data.uid, accessToken: res.data.accessToken }, view: 2 });
                            this.updateTimeOptions();
                        }
                    })
                }
            });
        } catch (error) {
            console.error('Error signing up: ', error);
            alert(error.message);
        }
    }

    saveNewOrder = (event) => {
        event.preventDefault();
        if (this.state.user) {

            var street = this.state.isSameAddress ? this.state.user?.street : event.target.elements.street.value
            var city = this.state.isSameAddress ? this.state.user?.city : event.target.elements.city.value
            var zip = this.state.isSameAddress ? this.state.user?.zip : event.target.elements.zip.value

            var data = {
                date: event.target.elements.date.value,
                time: event.target.elements.time.value,
                note: event.target.elements.note.value,
                lastname: this.state.user?.lastname,
                firstname: this.state.user?.firstname,
                email: this.state.user?.email,
                clientId: this.state.user?.uid,
                address: street + ", " + city + ", " + zip,
                status: "Awaiting Confirmation",
                orderNumber: this.generateRandomCode(),
                weight: 1,
                pricePerLbs: 1.75,
                roundLiftFee: 8.25,
                convenienceFee: 0.00
            }

            var emailBody = templates.orderUpdate;
            emailBody = emailBody.replaceAll(`{{Status}}`, ` Placed`);
            emailBody = emailBody.replaceAll(`{{clientName}}`, ` ${data.firstname} ${data.lastname}`);
            emailBody = emailBody.replaceAll(`{{orderDateTime}}`, ` ${data.date} ${data.time}`);
            emailBody = emailBody.replaceAll(`{{weight}}`, ` TBD`);
            emailBody = emailBody.replaceAll(`{{address}}`, ` ${data.address}`);
            emailBody = emailBody.replaceAll(`{{OrderNumber}}`, ` ${data.orderNumber}`);

            console.log("data: ", data);

            addOrUpdateDoc('orders', data.orderNumber, { ...data }).then((docres) => {
                if (docres.status) {
                    console.log("data: ", data);
                    this.getUserOrders();
                    this.setState({ view: 1 });
                    api.sendEmail({ subject: "LaundroLift: Order Placed", body: emailBody, to: `${data.email}` }, this.state.idToken).then((res) => {
                        console.log("sendEmailRes: ", res);
                    });

                    this.setState({
                        modalData: {
                            toggleModal: () => { },
                            title: "Thank You!",
                            htmlBody: `<div style="display: flex; flex-direction: column;">
                            <div style="margin: 15px">
                              Your pickup order has been placed!
                            </div>
                            <div style="margin: 15px">
                              We will contact you as soon as possible.
                            </div>
                            <div style="margin: 15px">
                              Please check your email inbox and spam folder for order updates.
                            </div>
                            <div style="margin: 15px">
                              If you need assistance, please contact us at
                              <a href="tel:+19085296733">+1 (908) 529-6733</a> or via email at
                              <a href="mailto:info@laundrolift.com">info@laundrolift.com</a>.
                            </div>
                          </div>
                          `,
                            onNo: () => { },
                            onOk: () => {
                                const { history } = this.props;
                                const cleanUrl = window.location.pathname;
                                history.replace(cleanUrl);
                            },
                            noText: "",
                            okText: "Ok"
                        }, showModal: true
                    });
                }
            })
        } else {
            //TODO: handle no user error
        }
    }

    generateRandomCode() {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        const seconds = String(today.getSeconds());

        const randomDigits = String(Math.floor(10000 + Math.random() * 90000)); // Generates random 5-digit number
        const code = `${year}${month}${day}${seconds}-${randomDigits}`;

        return code;
    }

    convertTo12HourFormat(time) {
        // Split the input time into hours and minutes
        const [hours, minutes] = time.split(':').map(Number);

        // Determine AM or PM
        const period = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour format to 12-hour format
        const hours12 = hours % 12 || 12; // Modulus operator to convert 24-hour to 12-hour, ensuring 12:00 is correctly handled

        // Return the formatted time
        return `${hours12}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
    }

    payInvoice = async (isAll, ord) => {
        api.getStripeK(this.state.idToken).then(async (kRes) => {
            console.log("kRes: ", kRes);
            if (kRes.data?.status) {
                var k = kRes.data.data;
                var stripe = await loadStripe(k);
                console.log("stripe: ", stripe)
                if (!isAll) {
                    var amount = Number(Number(ord.pricePerLbs * ord.weight + (ord.roundLiftFee + ord.convenienceFee))?.toFixed(2));
                    console.log("amount: ", amount);
                    var l = "https://laundrolift.com/Laundry_services";
                    var sl = "https://laundrolift.com/Laundry_services?status=1&transactionNumber=";
                    var cl = "https://laundrolift.com/Laundry_services?status=0&transactionNumber=";
                    api.getStripeS({ orders: [ord], sUrl: sl, cUrl: cl, email: this.state.user?.email }, this.state.idToken).then(sRes => {
                        console.log("session Res: ", sRes);
                        // getDocumentWhere('checkouts', 'orderNumber', ord.orderNumber).then(async (existingSessions) => {
                        // })
                        if (sRes.data?.status) {
                            var newSession = {
                                orderNumber: ord.orderNumber,
                                sessionId: sRes.data.id,
                                status: 1,
                                uid: this.state.user?.uid,
                                creadtedAt: new Date().toLocaleDateString(),
                                transactionNumber: sRes.data.transactionNumber
                            }
                            addOrUpdateDoc('checkouts', sRes.data.id, newSession).then((docres) => {
                                if (docres.status) {
                                    const result = stripe.redirectToCheckout({
                                        sessionId: sRes.data.id
                                    });

                                    if (result.error) {
                                        console.log(result.error);
                                    }
                                } else {
                                    console.log("adding checkout res: ", docres)
                                }
                            })
                        }
                    })
                }
            }
        })
    }

    closeModal = (val) => {
        console.log("clicked: ", val);

        const { history } = this.props;
        const cleanUrl = window.location.pathname;
        history.replace(cleanUrl);
        this.setState({ showModal: false });
    }

    cancelOrder = (o) => {
        // var eO = this.state.existingOrders;
        var ord = { ...o }
        ord.status = "Cancelled";
        console.log("order: ", ord);
        var msg = `<div style={{display: 'flex', flexDirection: 'column', padding: '15px'}}>
        <div style={{margin: '7px'}}>Order Placed By: ${ord.firstname} ${ord.lastname}</div>
        <div style={{margin: '7px'}}>Date Time: ${ord.date} ${ord.time}</div>
        <div style={{margin: '7px'}}>Address: ${ord.address}</div>
        <div style={{margin: '7px'}}>Email: ${ord.email}</div>
        <div style={{margin: '7px'}}>Note: ${ord.note}</div>
        <div style={{margin: '7px'}}>Order Number: ${ord.orderNumber}</div>
        </div>`
        var emailBody = templates.orderUpdate;
        emailBody = emailBody.replaceAll(`{{Status}}`, ` Cancelled`);
        emailBody = emailBody.replaceAll(`{{clientName}}`, ` ${ord.firstname} ${ord.lastname}`);
        emailBody = emailBody.replaceAll(`{{orderDateTime}}`, ` ${ord.date} ${ord.time}`);
        emailBody = emailBody.replaceAll(`{{weight}}`, ` ${ord.weight}`);
        emailBody = emailBody.replaceAll(`{{address}}`, ` ${ord.address}`);
        emailBody = emailBody.replaceAll(`{{OrderNumber}}`, ` ${ord.orderNumber}`);

        this.setState({
            modalData: {
                toggleModal: () => { this.setState({ showOrderUpdateModal: false }) },
                title: "Cancelled This Order?",
                htmlBody: msg,
                onNo: () => { },
                onOk: () => {
                    addOrUpdateDoc('orders', ord.orderNumber, { ...ord }).then((docres) => {
                        if (docres.status) {
                            console.log("addOrUpdateDoc('orders'): ", docres);
                            api.sendEmail({ subject: "LaundroLift: Order Cancelled", body: emailBody, to: `${ord.email}` }, this.state.idToken).then((res) => {
                                console.log("sendEmailRes: ", res);
                            })
                            this.getUserOrders();
                            this.setState({ showOrderUpdateModal: false });
                        }
                    })
                },
                noText: "No",
                okText: "Yes"
            }, showOrderUpdateModal: true
        })
    }

    render() {
        var slick_slider = {
            dots: false,
            arrow: false,
            autoplay: true,
            infinite: true,
            speed: 1000,
            slidesToShow: 4,
            slidesToScroll: 1,
            rows: 1,

            responsive: [{

                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
        };


        const openPopup = (url) => {
            window.open(url, 'popup', 'width=600,height=600,scrollbars=yes,resizable=yes');
        };



        return (

            <div className="site-main">

                <Header2 />

                {this.state.showModal ? (<Modal
                    toggleModal={this.closeModal}
                    title={this.state.modalData.title}
                    htmlBody={this.state.modalData.htmlBody}
                    onNo={this.closeModal}
                    onOk={this.closeModal}
                    noText={this.state.modalData.noText}
                    okText={this.state.modalData.okText} />) : null}

                {this.state.showOrderUpdateModal ? (<Modal
                    toggleModal={() => this.setState({ showOrderUpdateModal: false })}
                    title={this.state.modalData.title}
                    htmlBody={this.state.modalData.htmlBody}
                    onNo={() => this.setState({ showOrderUpdateModal: false })}
                    onOk={this.state.modalData.onOk}
                    noText={this.state.modalData.noText}
                    okText={this.state.modalData.okText} />) : null}

                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title-box text-center">
                                    <div className="page-title-heading">
                                        <h2 className="title">Laundry services</h2>
                                    </div>{/* /.page-title-captions */}
                                    <div className="breadcrumb-wrapper">
                                        <span>
                                            <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                        </span>
                                        <span>
                                            <a title="Homepage" href={'/'} >&nbsp;&nbsp;/ Services</a>
                                        </span>
                                        <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                        <span>Laundry services</span>
                                    </div>
                                </div>
                            </div>{/* /.col-md-12 */}
                        </div>{/* /.row */}
                    </div>{/* /.container */}
                </div>

                <div className="sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left border-right pr-45 pr-lg-15">
                                {!this.state.user ? (
                                    <aside className="widget widget-nav-menu">
                                        <h3 className="widget-title">How It Works</h3>
                                        <ul>
                                            <li className="d-flex">You schedule the pickup Time</li>
                                            <li className="d-flex">At pickup, we wight your garments to give you an Idea of the cost</li>
                                            <li className="d-flex">With your approval and after payment, we take your garments to the washing center</li>
                                            <li className="d-flex">We have your garments wash, dry, fold</li>
                                            <li className="d-flex">And deliver it back to you.</li>
                                            {/* <li className="d-flex">Steam iron</li> */}
                                        </ul>
                                    </aside>
                                ) : null}
                                <aside className="widget image-box">
                                    <div className="widget_img">
                                        <img src="./images/single-img-8-369x154.jpg" />
                                    </div>
                                    <div className="featured-imagebox featured-imagebox-service style2">
                                        <div className="featured-image-box-wrapper">
                                            <div className="featured-thumbnail">
                                                <img width="41" height="41" src="./images/whats-up.png" />
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href="tel:+19085296733">+1 908 529-6733</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <h2>Call us for all your laundry needs</h2>
                                    </div>
                                </aside>
                                <aside className="widget widget-download">
                                    <h3 className="widget-title">Download Our App</h3>
                                    <ul className="download">
                                        <li>
                                            <a><h3>App Store</h3>
                                                <i className="far fa-apple" /></a>
                                        </li>
                                        <li>
                                            <a><h3>PlayStore</h3>
                                                <i className="far fa-android" /></a>
                                        </li>
                                    </ul>
                                    <h3 className="widget-title">Follow Us</h3>
                                    <div className="ttm-social-links-wrapper mt-20">
                                        <ul className="social-icons">
                                            <li className="social-facebook">
                                                <a target="_blank" href="https://www.facebook.com/profile.php?id=61559193497510"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-lg-8 widget-area sidebar-right pl-45 pl-lg-15">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        {!this.state.user ? (
                                            <div className="col-lg-12 col-md-12">

                                                <div className="ttm-service-classic-content">
                                                    <h2 className="pt-0">Why choose this service</h2>
                                                    <p>
                                                        Clients choose our service for the ultimate convenience and quality. With our seamless process, you can say goodbye to laundry day stress and hello to extra time for yourself. Trust us to deliver meticulously cleaned and neatly folded clothes right to your doorstep, every time.
                                                    </p>
                                                </div>
                                                <div className="mt-40 mb-40" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
                                                    <form onSubmit={this.login} className="row" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                        <h6 className='col-12'>Have An Account? Login Now</h6>
                                                        {/* Login Now */}
                                                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                                                            <div className="col-lg-6 col-md-10" style={{ margin: "10px" }}>
                                                                <div className="form-outline" data-mdb-input-init>
                                                                    <label className="form-label" htmlFor="email">Email</label>
                                                                    <input required type="text" id="email" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-10" style={{ margin: "10px" }}>
                                                                <div className="form-outline" data-mdb-input-init>
                                                                    <label className="form-label" htmlFor="password">Password</label>
                                                                    <input required type="password" id="password" className="form-control" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-35">Log In</button>
                                                        </div>
                                                    </form>
                                                    <form onSubmit={this.createAccount} className="row">
                                                        <h6 className='col-12'> - Or Get Started Below - </h6>
                                                        <h6 className='mt-25 mb-10'>Please Enter Your Info</h6>
                                                        <div className="col-lg-6">
                                                            <div className="form-outline" data-mdb-input-init>
                                                                <label className="form-label" htmlFor="firstname">FirstName</label>
                                                                <input required type="text" id="firstname" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-outline" data-mdb-input-init>
                                                                <label className="form-label" htmlFor="lastname">LastName</label>
                                                                <input required type="text" id="lastname" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-outline" data-mdb-input-init>
                                                                <label className="form-label" htmlFor="email">Email</label>
                                                                <input required type="text" id="email" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-outline" data-mdb-input-init>
                                                                <label className="form-label" htmlFor="password">Create Password</label>
                                                                <input required type="password" id="password" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <div className="form-outline" data-mdb-input-init>
                                                                <label className="form-label" htmlFor="confirmpassword">Confirm Password</label>
                                                                <input required type="password" id="confirmpassword" className="form-control" />
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <h6 className='mt-25 mb-10'>Your Pennesylvania Address</h6>
                                                            <div className="col-lg-12">
                                                                <div className="form-outline" data-mdb-input-init>
                                                                    <label className="form-label" htmlFor="street">Street</label>
                                                                    <input required type="text" id="street" className="form-control" />
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6">
                                                                <div className="form-outline" data-mdb-input-init>
                                                                    <label className="form-label" htmlFor="city">City</label>
                                                                    <input required type="text" id="city" className="form-control" />
                                                                    <small>*We serve citys in Delaware County and Phyladelphia</small>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6">
                                                                <div className="form-outline" data-mdb-input-init>
                                                                    <label className="form-label" htmlFor="zip">Zip</label>
                                                                    <input required type="number" id="zip" className="form-control" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            {/* /policy */}
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="differentLocation"
                                                                    required
                                                                />
                                                                {/* <label className="form-check-label" htmlFor="differentLocation">
                                                                    
                                                                </label> */}
                                                                <div>
                                                                    By creating your account, you agree to our terms and conditions of use.
                                                                    <a href="/policy" onClick={(e) => { e.preventDefault(); openPopup('/policy'); }}> Read terms and conditions</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-25">Next</button>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="col-lg-12 col-md-12">
                                                    <img src="./images/single-img-14-836x399.png" />
                                                </div>
                                            </div>
                                        ) : (
                                            <div className='row'>
                                                <aside>
                                                    {/* <h3 className="widget-title">Menu</h3> */}
                                                    <ul style={{ display: "flex", flexDirection: "row" }}>
                                                        <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => this.setState({ view: 1 })}>
                                                            {this.state.view == 1 ? (
                                                                <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>Orders</div>
                                                            ) : "Orders"}
                                                        </li>
                                                        <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => { this.setState({ view: 2 }); this.updateTimeOptions(); }}>
                                                            {this.state.view == 2 ? (
                                                                <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>New Order</div>
                                                            ) : "New Order"}
                                                        </li>
                                                        <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => this.setState({ view: 3 })}>
                                                            {this.state.view == 3 ? (
                                                                <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>Account Info</div>
                                                            ) : "Account Info"}
                                                        </li>
                                                    </ul>
                                                </aside>
                                                {this.state.view == 1 && (
                                                    <div style={{ display: "flex", flexDirection: "column", padding: "15px" }}>

                                                        <ul style={{ display: "flex", flexDirection: "row" }}>
                                                            <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => this.setState({ ordersView: 1 })}>
                                                                {this.state.ordersView == 1 ? (
                                                                    <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>Awaiting Confirmation</div>
                                                                ) : "Awaiting Confirmation"}
                                                            </li>
                                                            <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => { this.setState({ ordersView: 2 }) }}>
                                                                {this.state.ordersView == 2 ? (
                                                                    <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>Awaiting Pickup</div>
                                                                ) : "Awaiting Pickup"}
                                                            </li>
                                                            <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => this.setState({ ordersView: 3 })}>
                                                                {this.state.ordersView == 3 ? (
                                                                    <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>In Progress</div>
                                                                ) : "In Progress"}
                                                            </li>
                                                            <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => this.setState({ ordersView: 5 })}>
                                                                {this.state.ordersView == 5 ? (
                                                                    <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>Completed</div>
                                                                ) : "Completed"}
                                                            </li>
                                                            <li className="d-flex mr-2" style={{ cursor: "pointer", padding: '10px 14px', borderRadius: "4px", marginRight: "15px", backgroundColor: "lightgray" }} onClick={() => this.setState({ ordersView: 4 })}>
                                                                {this.state.ordersView == 4 ? (
                                                                    <div style={{ textDecoration: "underline", color: "blue", fontSize: "1.2rem" }}>Cancelled</div>
                                                                ) : "Cancelled"}
                                                            </li>
                                                        </ul>

                                                        <h6>
                                                            {this.state.existingOrders.length < 1 ? (
                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                    <h6 style={{ marginTop: '20px' }}>You do not have any order yet!</h6>
                                                                    <button type='button' onClick={() => { this.setState({ view: 2 }); this.updateTimeOptions(); }} className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-25">Order New Pickup</button>
                                                                </div>
                                                            ) : (
                                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                                    {this.state.ordersView == 1 && (
                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                            {this.state.awaitingConfirmation.length < 1 && (
                                                                                <h6 style={{ marginTop: '20px' }}>No 'Awaiting Confirmation' Orders!</h6>
                                                                            )}
                                                                            {this.state.awaitingConfirmation.map((ord) => {
                                                                                if (ord.status == "Awaiting Confirmation") {
                                                                                    return (
                                                                                        <MDBCard alignment='center' className='mb-10 mt-15'>
                                                                                            <MDBCardHeader>Ref #: {ord.orderNumber}</MDBCardHeader>
                                                                                            <MDBCardBody>
                                                                                                <MDBCardText>
                                                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="far fa-clock" aria-hidden="true"></i>
                                                                                                            Pickup Date Time: {ord.date + " at " + ord.time}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-dollar-sign" aria-hidden="true"></i>
                                                                                                            Prices: ${ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : Number(ord.pricePerLbs * ord.weight + (ord.roundLiftFee + ord.convenienceFee))?.toFixed(2)}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-funnel-dollar" aria-hidden="true"></i>
                                                                                                            Weight: {ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : ord.weight} lbs
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-directions" aria-hidden="true"></i>
                                                                                                            Address: {ord.address}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-exclamation-circle" aria-hidden="true"></i>
                                                                                                            Note: {ord.note}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <h6>
                                                                                                                <i className="fas fa-hourglass-half" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                                                                                Status:
                                                                                                                <span style={{ color: "blue", marginLeft: '15px' }}>{ord.status}</span></h6>
                                                                                                        </div>

                                                                                                        <div onClick={() => this.cancelOrder(ord)} style={{ textAlign: 'center', margin: '7px', borderRadius: '5px', width: '100px', border: '1px gray solid', padding: '7px', cursor: 'pointer' }}>
                                                                                                            Cancel Order
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </MDBCardText>
                                                                                                {/* <MDBBtn href='#'>Button</MDBBtn> */}
                                                                                            </MDBCardBody>
                                                                                            <MDBCardFooter className='text-muted'></MDBCardFooter>
                                                                                        </MDBCard>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                    {this.state.ordersView == 2 && (
                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                            {this.state.awaitingPickup.length < 1 && (
                                                                                <h6 style={{ marginTop: '20px' }}>No 'Awaiting Pickup' Orders!</h6>
                                                                            )}
                                                                            {this.state.awaitingPickup.map((ord) => {
                                                                                if (ord.status == "Awaiting Pickup") {
                                                                                    return (
                                                                                        <MDBCard alignment='center' className='mb-10 mt-15'>
                                                                                            <MDBCardHeader>Ref #: {ord.orderNumber}</MDBCardHeader>
                                                                                            <MDBCardBody>
                                                                                                <MDBCardText>
                                                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="far fa-clock" aria-hidden="true"></i>
                                                                                                            Pickup Date Time: {ord.date + " at " + ord.time}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-dollar-sign" aria-hidden="true"></i>
                                                                                                            Prices: ${ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : Number(ord.pricePerLbs * ord.weight + (ord.roundLiftFee + ord.convenienceFee))?.toFixed(2)}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-funnel-dollar" aria-hidden="true"></i>
                                                                                                            Weight: {ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : ord.weight} lbs
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-directions" aria-hidden="true"></i>
                                                                                                            Address: {ord.address}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-exclamation-circle" aria-hidden="true"></i>
                                                                                                            Note: {ord.note}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <h6>
                                                                                                                <i className="fas fa-hourglass-half" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                                                                                Status:
                                                                                                                <span style={{ color: "blue", marginLeft: '15px' }}>{ord.status}</span></h6>
                                                                                                        </div>

                                                                                                        <div onClick={() => this.cancelOrder(ord)} style={{ textAlign: 'center', margin: '7px', borderRadius: '5px', width: '100px', border: '1px gray solid', padding: '7px', cursor: 'pointer' }}>
                                                                                                            Cancel Order
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </MDBCardText>
                                                                                                {/* <MDBBtn href='#'>Button</MDBBtn> */}
                                                                                            </MDBCardBody>
                                                                                            <MDBCardFooter className='text-muted'></MDBCardFooter>
                                                                                        </MDBCard>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                    {this.state.ordersView == 3 && (
                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                            {this.state.inProgress.length < 1 && (
                                                                                <h6 style={{ marginTop: '20px' }}>No 'In Progress' Orders!</h6>
                                                                            )}
                                                                            {this.state.inProgress.map((ord) => {
                                                                                if (ord.status == "In Progress") {
                                                                                    return (
                                                                                        <MDBCard alignment='center' className='mb-10 mt-15'>
                                                                                            <MDBCardHeader>Ref #: {ord.orderNumber}</MDBCardHeader>
                                                                                            <MDBCardBody>
                                                                                                <MDBCardText>
                                                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="far fa-clock" aria-hidden="true"></i>
                                                                                                            Pickup Date Time: {ord.date + " at " + ord.time}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-dollar-sign" aria-hidden="true"></i>
                                                                                                            Prices: ${ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : Number(ord.pricePerLbs * ord.weight + (ord.roundLiftFee + ord.convenienceFee))?.toFixed(2)}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-funnel-dollar" aria-hidden="true"></i>
                                                                                                            Weight: {ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : ord.weight} lbs
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-directions" aria-hidden="true"></i>
                                                                                                            Address: {ord.address}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-exclamation-circle" aria-hidden="true"></i>
                                                                                                            Note: {ord.note}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <h6>
                                                                                                                <i className="fas fa-hourglass-half" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                                                                                Status:
                                                                                                                <span style={{ color: "blue", marginLeft: '15px' }}>{ord.status}</span></h6>
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <h6>
                                                                                                                {ord.paymentStatus == 'Unpaid' && (
                                                                                                                    <i className="fas fa-exclamation-triangle" aria-hidden="true" style={{ marginRight: "5px", color: 'red' }}></i>
                                                                                                                )}
                                                                                                                {ord.paymentStatus == 'Paid' && (
                                                                                                                    <i className="fas fa-check-circle" aria-hidden="true" style={{ marginRight: "5px", color: 'green' }}></i>
                                                                                                                )}
                                                                                                                Payment Status:
                                                                                                                <span style={{ color: "blue", marginLeft: '15px' }}>{ord.paymentStatus || 'Unpaid'}</span></h6>
                                                                                                        </div>
                                                                                                        {ord.paymentStatus == 'Unpaid' && (
                                                                                                            <div className='mb-2'>
                                                                                                                <h6>
                                                                                                                    <i className="fas fa-exclamation-triangle" aria-hidden="true" style={{ marginRight: "10px", color: 'red' }}></i>
                                                                                                                    Please note that payment is due before we wash and return your clothes!
                                                                                                                </h6>
                                                                                                            </div>
                                                                                                        )}
                                                                                                        {ord.paymentStatus == 'Paid' && (

                                                                                                            <div className='mb-2'>
                                                                                                                <h6>
                                                                                                                    <i className="fa fa-hashtag" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                                                                                    Transaction Number:
                                                                                                                    <span style={{ color: "blue", marginLeft: '15px' }}>{ord.transactionNumber}</span></h6>
                                                                                                            </div>
                                                                                                        )}
                                                                                                        {ord.paymentStatus == 'Unpaid' && (
                                                                                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                                                                <div onClick={() => this.payInvoice(false, ord)} style={{ textAlign: 'center', margin: '7px', borderRadius: '5px', width: '100px', border: '1px gray solid', padding: '7px', cursor: 'pointer' }}>
                                                                                                                    Pay invoice ${Number(ord.pricePerLbs * ord.weight + (ord.roundLiftFee + ord.convenienceFee))?.toFixed(2)}
                                                                                                                </div>
                                                                                                                <div style={{ margin: '15px 7px' }}>
                                                                                                                    All transactions are securely processed by
                                                                                                                    <a href='https://stripe.com/privacy' target='_blank'>
                                                                                                                        <i className="fab fa-cc-stripe" aria-hidden="true" style={{ marginLeft: "17px", transform: 'scale(2.5)', color: 'blue' }}></i>
                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </MDBCardText>
                                                                                                {/* <MDBBtn href='#'>Button</MDBBtn> */}
                                                                                            </MDBCardBody>
                                                                                            <MDBCardFooter className='text-muted'></MDBCardFooter>
                                                                                        </MDBCard>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                    {this.state.ordersView == 5 && (
                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                            {this.state.complete.length < 1 && (
                                                                                <h6 style={{ marginTop: '20px' }}>No Completed Orders!</h6>
                                                                            )}
                                                                            {this.state.complete.map((ord) => {
                                                                                if (ord.status == "Completed") {
                                                                                    return (
                                                                                        <MDBCard alignment='center' className='mb-10 mt-15'>
                                                                                            <MDBCardHeader>Ref #: {ord.orderNumber}</MDBCardHeader>
                                                                                            <MDBCardBody>
                                                                                                <MDBCardText>
                                                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="far fa-clock" aria-hidden="true"></i>
                                                                                                            Pickup Date Time: {ord.date + " at " + ord.time}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-dollar-sign" aria-hidden="true"></i>
                                                                                                            Prices: ${ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : Number(ord.pricePerLbs * ord.weight + (ord.roundLiftFee + ord.convenienceFee))?.toFixed(2)}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-funnel-dollar" aria-hidden="true"></i>
                                                                                                            Weight: {ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : ord.weight} lbs
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-directions" aria-hidden="true"></i>
                                                                                                            Address: {ord.address}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-exclamation-circle" aria-hidden="true"></i>
                                                                                                            Note: {ord.note}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <h6>
                                                                                                                <i className="fas fa-hourglass-half" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                                                                                Status:
                                                                                                                <span style={{ color: "blue", marginLeft: '15px' }}>{ord.status}</span></h6>
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <h6>
                                                                                                                {ord.paymentStatus == 'Unpaid' && (
                                                                                                                    <i className="fas fa-exclamation-triangle" aria-hidden="true" style={{ marginRight: "5px", color: 'red' }}></i>
                                                                                                                )}
                                                                                                                {ord.paymentStatus == 'Paid' && (
                                                                                                                    <i className="fas fa-check-circle" aria-hidden="true" style={{ marginRight: "5px", color: 'green' }}></i>
                                                                                                                )}
                                                                                                                Payment Status:
                                                                                                                <span style={{ color: "blue", marginLeft: '15px' }}>{ord.paymentStatus || 'Unpaid'}</span></h6>
                                                                                                        </div>
                                                                                                        {ord.paymentStatus == 'Paid' && (

                                                                                                            <div className='mb-2'>
                                                                                                                <h6>
                                                                                                                    <i className="fa fa-hashtag" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                                                                                    Transaction Number:
                                                                                                                    <span style={{ color: "blue", marginLeft: '15px' }}>{ord.transactionNumber}</span></h6>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </MDBCardText>
                                                                                                {/* <MDBBtn href='#'>Button</MDBBtn> */}
                                                                                            </MDBCardBody>
                                                                                            <MDBCardFooter className='text-muted'></MDBCardFooter>
                                                                                        </MDBCard>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </div>
                                                                    )}
                                                                    {this.state.ordersView == 4 && (
                                                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                                                            {/* <h6 className='mt-10 mb-15'>Cancelled</h6> */}
                                                                            {this.state.cancelled.length < 1 && (
                                                                                <h6 style={{ marginTop: '20px' }}>No Cancelled Orders!</h6>
                                                                            )}
                                                                            {this.state.cancelled.map((ord) => {
                                                                                if (ord.status == "Cancelled") {
                                                                                    return (
                                                                                        <MDBCard alignment='center' className='mb-10 mt-15'>
                                                                                            <MDBCardHeader>Ref #: {ord.orderNumber}</MDBCardHeader>
                                                                                            <MDBCardBody>
                                                                                                <MDBCardText>
                                                                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="far fa-clock" aria-hidden="true"></i>
                                                                                                            Pickup Date Time: {ord.date + " at " + ord.time}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-dollar-sign" aria-hidden="true"></i>
                                                                                                            Prices: ${ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : Number(ord.pricePerLbs * ord.weight + (ord.roundLiftFee + ord.convenienceFee))?.toFixed(2)}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-funnel-dollar" aria-hidden="true"></i>
                                                                                                            Weight: {ord.status == "Awaiting Pickup" || ord.status == "Awaiting Confirmation" ? "TBD" : ord.weight} lbs
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-directions" aria-hidden="true"></i>
                                                                                                            Address: {ord.address}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <i style={{ marginRight: "5px" }} className="fas fa-exclamation-circle" aria-hidden="true"></i>
                                                                                                            Note: {ord.note}
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <h6>
                                                                                                                {ord.paymentStatus == 'Unpaid' && (
                                                                                                                    <i className="fas fa-exclamation-triangle" aria-hidden="true" style={{ marginRight: "5px", color: 'red' }}></i>
                                                                                                                )}
                                                                                                                {ord.paymentStatus == 'Paid' && (
                                                                                                                    <i className="fas fa-check-circle" aria-hidden="true" style={{ marginRight: "5px", color: 'green' }}></i>
                                                                                                                )}
                                                                                                                Payment Status:
                                                                                                                <span style={{ color: "blue", marginLeft: '15px' }}>{ord.paymentStatus || 'Unpaid'}</span></h6>
                                                                                                        </div>
                                                                                                        <div className='mb-2'>
                                                                                                            <h6>
                                                                                                                <i className="fas fa-hourglass-half" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                                                                                Status:
                                                                                                                <span style={{ color: "blue", marginLeft: '15px' }}>{ord.status}</span></h6>
                                                                                                        </div>
                                                                                                        {ord.paymentStatus == 'Paid' && (

                                                                                                            <div className='mb-2'>
                                                                                                                <h6>
                                                                                                                    <i className="fa fa-hashtag" aria-hidden="true" style={{ marginRight: "5px" }}></i>
                                                                                                                    Transaction Number:
                                                                                                                    <span style={{ color: "blue", marginLeft: '15px' }}>{ord.transactionNumber}</span></h6>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </MDBCardText>
                                                                                                {/* <MDBBtn href='#'>Button</MDBBtn> */}
                                                                                            </MDBCardBody>
                                                                                            <MDBCardFooter className='text-muted'></MDBCardFooter>
                                                                                        </MDBCard>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </div>
                                                                    )}

                                                                </div>
                                                            )}
                                                        </h6>
                                                    </div>
                                                )}

                                                {this.state.view == 2 && (
                                                    <form onSubmit={this.saveNewOrder} style={{ display: "flex", flexDirection: "column", padding: "15px" }}>

                                                        <div className='row'>
                                                            <h6 className='mt-25 mb-10'>New Pickup Order</h6>

                                                            <div className="mt-2 mb-2 col-lg-6">
                                                                <div className="form-outline">
                                                                    <label className="form-label" htmlFor="date">Date</label>
                                                                    <input required onChange={this.handleDateChange} type="date" id="date" className="form-control" />
                                                                </div>
                                                            </div>

                                                            <div className="mt-2 mb-2 col-lg-3">
                                                                <div className="form-outline">
                                                                    <label className="form-label" htmlFor="time">Time</label>
                                                                    <select id="time" className="form-control" required>

                                                                        {this.state.timeOptions.map((opt) => {
                                                                            if (opt.available) {
                                                                                return (<option value={opt.value}>{this.convertTo12HourFormat(opt.value)}</option>)
                                                                            }
                                                                        })}

                                                                    </select>
                                                                    <small className="form-text text-muted">*Your driver may arrive 15min earlier or later due to other pickups and traffic.</small>
                                                                </div>
                                                            </div>
                                                            {/* </div> */}

                                                            <div className="mt-2 mb-2 col-lg-6">
                                                                <div className="form-outline">
                                                                    <label className="form-label" htmlFor="note">Note</label>
                                                                    <textarea id="note" className="form-control"> </textarea>
                                                                </div>
                                                            </div>

                                                            <div className="mt-2 mb-2 col-lg-6">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        id="differentLocation"
                                                                        checked={this.state.isSameAddress}
                                                                        onChange={(e) => this.setState({ isSameAddress: !this.state.isSameAddress })}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="differentLocation">
                                                                        Pickup is at my address
                                                                    </label>
                                                                </div>
                                                                <p>{this.state.user.street}, {this.state.user.city}, PA {this.state.user.zip}</p>
                                                            </div>

                                                            {!this.state.isSameAddress && (
                                                                <div className='row'>
                                                                    <h6 className='mt-25 mb-10'>Pickup Address</h6>
                                                                    <small className="form-text text-muted">*We serve in Philadelphia and nearing Delaware County Cities.</small>
                                                                    <div className="mt-2 mb-2 col-lg-12">
                                                                        <div className="form-outline" data-mdb-input-init>
                                                                            <label className="form-label" htmlFor="street">Street</label>
                                                                            <input required type="text" id="street" className="form-control" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="mt-2 mb-2 col-lg-6">
                                                                        <div className="form-outline" data-mdb-input-init>
                                                                            <label className="form-label" htmlFor="city">City</label>
                                                                            <input required type="text" id="city" className="form-control" />
                                                                            <small>*We serve citys in Delaware County and Phyladelphia</small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="mt-2 mb-2 col-lg-4 col-md-6">
                                                                        <div className="form-outline" data-mdb-input-init>
                                                                            <label className="form-label" htmlFor="zip">Zip</label>
                                                                            <input required type="text" id="zip" className="form-control" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            
                                                        <div className='row'>
                                                            {/* /policy */}
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="differentLocation"
                                                                    required
                                                                />
                                                                {/* <label className="form-check-label" htmlFor="differentLocation">
                                                                    
                                                                </label> */}
                                                                <div>
                                                                    By placing the pickup request, you agree to our terms and conditions of use.
                                                                    <a href="/policy" onClick={(e) => { e.preventDefault(); openPopup('/policy'); }}> Read terms and conditions</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                            <button type='submit' className="ttm-btn ttm-btn-size-sm ttm-btn-shape-rounded ttm-btn-style-fill ttm-icon-btn-right ttm-btn-color-skincolor mt-15 mb-25">Order New Pickup</button>

                                                        </div>
                                                    </form>
                                                )}

                                                {this.state.view == 3 && (
                                                    <div style={{ display: "flex", flexDirection: "column", padding: "15px" }}>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <div className="col-lg-12 col-md-12">

                                            <div className="ttm-service-classic-content">
                                                <h2>Service advantages</h2>
                                                <p>Order for convenient and free shipping on specific days in your area. A minimum order of $100 is
                                                    required to qualify for free shipping, email alert will let you know when free shipping is approaching,
                                                    and dont miss it.</p>
                                            </div>
                                            <div className="row mt-20">
                                                <div className="col-md-5">
                                                    <div className="featured-icon-box style4">
                                                        <div className="featured-icon">
                                                            <i className="flaticon-steam-iron"></i>
                                                        </div>
                                                        <div className="featured-content">
                                                            <div className="featured-title">
                                                                <h3>24/7 Supports</h3>
                                                            </div>
                                                            <div className="featured-desc">
                                                                <p>Contact us now. Don't miss out on free shipping!</p>
                                                            </div>
                                                            <div className="ttm-iconbox right-140">
                                                                <a href={'/Leather_cleaning'} className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                                                    <i className="flaticon-up-right-arrow"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-7">
                                                    <img className="border-radius-8 mt-15" src="./images/single-img-15-505x299.png" />
                                                </div>
                                                <p className="mt-20">We believe in vertically integrating as many cleaning processes as possible in order to achieve the high
                                                    standards set by ourselves and ultimately by our customer base. We own and operate a state-of-the-art van
                                                    that we use to personally collect and deliver your garments in pristine condition, pick up a rag during
                                                    business hours.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>

                <Footer />

            </div>
        )
    }
}

export default Laundry_services;