import React, { Component, useEffect, useState } from 'react';


import {
    db,
    auth,
    createUser,
    login,
    signout,
    resetPwd,
    onAuthStateChanged,
    addOrUpdateDoc,
    getDocument,
    getDocumentWhere,
    getAllDocuments
} from '../../firebase';
import Modal from '../../components/modal/Modal';
import Menu from './Menu';
import Mobile_menu from './Mobile_menu';
import Logo from './Logo'
import Header_search from './Header_search'


export class Header2 extends Component {


    constructor() {
        super();
        this.state = {
            user: null,
            showModal: false,
            modalData: "",
            firebaseUser: null,
            idToken: null
        };

    }


    componentDidMount() {
        window.addEventListener('scroll', this.isSticky);


        this.unsubscribeAuth = onAuthStateChanged(auth, (user) => {

            console.log("user: ", user)

            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                const uid = user.uid;

                user.getIdToken().then((idTk) => {
                    this.setState({ idToken: idTk });
                })

                getDocumentWhere("users", "uid", uid).then((uDataRes) => {
                    console.log("uDataRes: ", uDataRes);
                    if (uDataRes.status) {
                        this.setState({ user: uDataRes.data[0] });

                    }
                })
            } else {
                // User is signed out
                // ...
                this.setState({ idToken: null, user: null });
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.isSticky);
    }

    isSticky = (e) => {
        const header = document.querySelector('header');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('is-Sticky') : header.classList.remove('is-Sticky');
    };

    logout = () => {
        signout().then((res) => {
            console.log("Signing Out... ", res);
        })
    }

    render() {
        return (
            <header id="masthead" className="header ttm-header-style-03">
                {/* ttm-topbar-wrapper */}
                <div className="ttm-topbar-wrapper bg-theme-DarkColor clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-5">
                                <ul className="top-contact text-left">
                                    <li><i className="fa fa-map-marker" />Delaware & Philadelphia County, PA</li>
                                    <li><i className="ti-time" />&nbsp;&nbsp;From 6AM - 8PM</li>
                                </ul>
                            </div>
                            <div className="col-lg-7">
                                <div className="topbar-right text-right d-flex justify-content-end">
                                    <div className="ttm-social-links-wrapper list-inline">
                                        <ul className="social-icons">
                                            <li className="ttm-social-facebook"><a href="https://www.facebook.com/profile.php?id=61559193497510" rel="noopener" aria-label="facebook"><i className="icon-facebook"></i>facebook</a>
                                            </li>
                                            {/* <li className="ttm-social-twitter"><a href="https://twitter.com/PreyanTechnosys" rel="noopener" aria-label="twitter"><i className="icon-twitter"></i>twitter</a>
                                            </li> */}
                                            {/* <li className="ttm-social-instagram"><a href="https://www.instagram.com/preyan_technosys/" rel="noopener" aria-label="instagram"><i className="icon-instagram"></i>instagram</a>
                                            </li>
                                            <li className="ttm-social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/" rel="noopener" aria-label="linkedin"><i className="icon-linkedin"></i>linkedin</a>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ttm-topbar-wrapper end */}
                {/* site-header-menu */}
                <div id="site-header-menu" className="site-header-menu bg-theme-WhiteColor">
                    <div className="site-header-menu-inner ttm-stickable-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* site-navigation */}
                                    <div className="site-navigation d-flex flex-row align-items-center justify-content-between">
                                        {/* site-branding */}
                                        <div className="site-branding">
                                            <a className="home-link" href={'/'} title="Laundrolift" rel="home">
                                                <img width="185" height="50" id="logo-img" className="img-fluid" alt="logo" src="images/logo-img-white.svg" />
                                            </a>
                                        </div>
                                        {/* site-branding end */}
                                        <div className="border-box-block">
                                            <div className=" d-flex align-items-center">
                                                {/* menu */}
                                                <Menu user={this.state.user} logout={this.logout} />
                                                <div className="mobile-menu"><Mobile_menu user={this.state.user} logout={this.logout} /></div>
                                                {/* menu end */}
                                                {/* <div className="ttm-widget_header d-flex flex-row">
                                                    <div className="widget_info d-flex flex-row align-items-center justify-content-end">
                                                    </div>
                                                    <div className="header_btn ttm-btn ttm-btn-style-border text-theme-WhiteColor ml-30">

                                                            <a href="/Laundry_services" className="">
                                                                Order service now
                                                            </a>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>{/* site-navigation end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* site-header-menu end */}
            </header>

        )
    }
}

export default Header2;