import React, { Component } from 'react';
import Header from '../components/layout/Header';
import PageHeader from "../components/layout/PageHeader";
import ProgressBar from 'react-animated-progress-bar';
import Slider from 'react-slick';
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

export class Team_Details extends Component {
    render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [ {
  
              breakpoint: 1024,
              settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
              }
          },{
      
            breakpoint: 778,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        
        return (
            <div className="site-main">
                <Header />

                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Team Details</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Team Details</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>

                <section className="ttm-row team-details-member-info-section clearfix">
                    <div className="container">
                        <div className="row" data-aos="zoom-in" data-aos-duration="2000">
                            <div className="col-lg-10 m-auto">
                                <div className="ttm-team-member-single-content bg-theme-GreyColor p-lg-15 p-70">
                                    <div className="ttm-team-member-single-content-area">
                                        <div className="ttm-bg ttm-col-bgcolor-yes bg-base-grey overflow-visible">
                                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                                            <div className="layer-content">
                                                <div className="row g-0">
                                                    <div className="col-lg-6">
                                                        <div className="ttm-featured-wrapper"> 
                                                            <div className="featured-thumbnail pr-xl-15 pr-sm-0">
                                                            <img src="./images/single-img-7-385x462.png" />
                                                            </div> 
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="ttm-team-member-detail">
                                                            <div className="ttm-team-member-single-list pt-lg-30 pb-lg-30">
                                                                <h2 className="ttm-team-member-single-title">Joel Lou</h2>
                                                                <span className="ttm-team-member-single-position">Team lead</span>
                                                                <div className="ttm-short-desc">A team leader reporting to the operations manager constantly
                                                                monitors the work of team members at the project site to ensure that the work is being 
                                                                performed very satisfactorily</div>
                                                                <div className="ttm-team-data">
                                                                    <div className="ttm-team-details-wrapper">
                                                                        <ul className="ttm-team-details-list clearfix">
                                                                            <li>
                                                                                <div className="ttm-team-list-title">Email : </div>
                                                                                <div className="ttm-team-list-value"><a href="mailto:example@gmail.com"> Info@gmail.com</a></div>
                                                                            </li>
                                                                            <li>
                                                                                <div className="ttm-team-list-title">Call : </div>
                                                                                <div className="ttm-team-list-value"><a href="tel:2 (800) 666 6520"> 2 (800) 666 6520</a></div>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="ttm-team-details-list clearfix">
                                                                            <li>
                                                                                <div className="ttm-team-list-title">Address : </div>
                                                                                <div className="ttm-team-list-value">85 Road Broklyn Street, 600 New York, USA</div>
                                                                            </li>
                                                                        </ul>
                                                                        <ul className="ttm-team-details-list clearfix">
                                                                            <li>
                                                                                <div className="ttm-team-list-title">Experience : </div>
                                                                                <div className="ttm-team-list-value"> 8 year of experience</div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div className="ttm-team-member-social-contact">
                                                                    <div className="ttm-social-links-wrapper mt-20">
                                                                        <ul className="social-icons">
                                                                            <li className="social-facebook">
                                                                                <a target="_blank" href="https://www.facebook.com/preyantechnosys19"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                                                            </li>
                                                                            <li className="social-twitter">  
                                                                                <a target="_blank" href="https://twitter.com/PreyanTechnosys"><i className="fab fa-twitter" aria-hidden="true"></i></a>
                                                                            </li>
                                                                            <li className="social-pinterest">
                                                                                <a target="_blank" href="https://dribbble.com/PreyanTechnosys"><i className="fab fa-dribbble" aria-hidden="true"></i></a>
                                                                            </li>
                                                                            <li className="social-instagram">
                                                                                <a target="_blank" href="https://www.instagram.com/preyan_technosys/"><i className="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>                                                                
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row team-details-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 m-auto">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <div className="ttm-team-member-single-content pb-lg-30">
                                            <h3>Personal experience</h3>
                                            <p>In the age of the on-demand economy, almost every service imaginable is available
                                            through an app, whether its food, transportation, or groceries, now entering the space
                                            are apps that allow you to outsource like cleaning and even laundry.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-6">
                                        <div className="ttm-progress-bar style1">
                                            <h3 className="progressbar-title">Satisfied clients</h3>
                                            <ProgressBar  rect  percentage="88" />
                                        </div> 
                                        <div className="ttm-progress-bar style1">
                                            <h3 className="progressbar-title">Eco friendly</h3>
                                            <ProgressBar  rect  percentage="72" />
                                        </div> 
                                        <div className="ttm-progress-bar style1">
                                            <h3 className="progressbar-title">Great efficiency</h3>
                                            <ProgressBar  rect  percentage="80" />
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row bottom-zero-padding-section fid-section bg-theme-SkinColor bg-img1 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
                    
                    <div className="container-fluid">
                        <div className="col-md-12">
                            <div className="section-title title-style-center_text mb-0">
                                <div className="title-header">
                                    <h3>Experts in their field</h3>
                                    <h2 className="title">Awesome team <span className='webkit-content'>[Member]</span></h2>
                                </div>
                            </div>
                        </div>
                        <Slider className="row pl-60 pr-60 slick_slider ttm-boxes-spacing-10px pl-xl-0 pr-xl-0" {...slick_slider} slidesToShow={5}arrows={false}
                        autoplay={false} responsive= { [{ breakpoint: 1199, settings: {slidesToShow: 4,slidesToScroll: 2 }},
                            {breakpoint: 991, settings: { slidesToShow: 3, slidesToScroll: 1 }},
                            {breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 }},
                            {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-team style2">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-1-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>manager</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details.js'}>Juliana SIlva</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-team style2">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-2-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>Team lead</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details.js'}>Joel Lou</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-team style2">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-8-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>Manager</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details.js'}>Jack Martin</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-team style2">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-10-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>manager</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details.js'}>Jessica Brown</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-team style2">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-9-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>manager</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details.js'}>Charles Altman</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                            <div className="ttm-box-col-wrapper">
                                <div className="featured-imagebox featured-imagebox-team style2">
                                    <div className="featured-thumbnail">
                                        <img src="./images/team/team-3-307x368.jpg" />
                                    </div>
                                    <div className="social-icon-wrapper">
                                        <ul className="social-icons list-inline">
                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-content-box-inner">
                                            <p>manager</p>
                                            <div className="featured-title">
                                                <h3><a href={'/Team_details.js'}>Charles Altman</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <p className="mb-0 text-theme-WhiteColor">Meet our trusted professionals with great years of experience.
                                <strong><u><a href={'/Team_details.js'}> View More Team</a></u></strong></p>
                            </div>
                        </div>
                    </div>
                </section> 
                
            <Footer/>
                        
            </div>
          )
      }
   }

export default Team_Details;