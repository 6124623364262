import React, { Component } from 'react';
import Slider from 'react-slick';
import Header from '../components/layout/Header';
import { Banner } from '../components/banner/Home1_banner';
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';

export class Home extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    render() {
      var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [{
    
          breakpoint: 768,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 575,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }]
      };
        var slick_slider_ltr = {
        infinite: true,
        arrows: false,
        dots: false,                   
        autoplay: true,
        centerMode : false,
        autoplaySpeed: 0,
        speed: 9000,
        cssEase: "linear",
        pauseOnHover: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        }

        var slick_slider_rtl = {
        infinite: true,
        arrows: false,
        dots: false,                   
        autoplay: true,
        centerMode : false,
        rtl: true,
        autoplaySpeed: 0,
        speed: 9000,
        cssEase: "linear",
        pauseOnHover: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        };
        

        return (       
          <div className="site-main">

            <Header/>
            
            {/* Banner */} 
            <Banner/>
            {/* Banner end */} 

            <section className="ttm-row clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7">
                            <div className="section-title">
                                <div className="title-header">
                                    <h3>We Are Committed To Cleaning Excellence!</h3>
                                    <h2 className="title">Our cleaning experts deliver the <span className="webkit-content">[Clean]</span></h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="ttm-fid inside style1 text-theme-WhiteColor">
                                        <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                        <h3 className="ttm-fid-title">Year <br /> experience</h3>
                                            <h4 className="ttm-fid-inner">
                                                <CountUp start={0} end={25} duration={5} />
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-8">
                                    <p className='position-relative mr_20 right'>We prioritize speed and efficiency to ensure you get your clothes back on time.
                                    Once we pick up your items, we clean them as soon as possible. 
                                    Your clothes use a separate machine, work based on your preferences.</p>
                                </div>
                            </div>
                            <div className="separator">
                                <div className="sep-line mt-4 mb-40"></div>
                            </div>
                            <ul className="ttm-list style1">
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span className="ttm-list-li-content">24/7 Support</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span className="ttm-list-li-content">Offers & cares</span>
                                </li>
                                <li>
                                    <i className="fa fa-check"></i>
                                    <span className="ttm-list-li-content">Expert teams</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-5">
                            <div className="ttm-single-img-1">
                                <div className="ttm_single_image-wrapper">
                                    <img src="./images/single-img-523x443.png" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row portfolio-section bg-theme-DarkColor bg-img1 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-2.png")' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="section-title">
                                <div className="title-header">
                                    <h2 className="title">Successfully completed projects.</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="section-title style5">
                                <div className="title-header">
                                    <h2>250+</h2>
                                </div>
                                <div className="title-desc">
                                    <p>Completed Projects</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0">
                    <Slider className="row g-0 mt-60 slick_slider ttm-boxes-spacing-30px mt-md-0" {...slick_slider} slidesToShow={4}arrows={false}
                    autoplay={false} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 4,slidesToScroll: 2 }},
                        {breakpoint: 991, settings: { slidesToShow: 3, slidesToScroll: 1 }} ,
                        {breakpoint: 767, settings: { slidesToShow: 2, slidesToScroll: 1 }} ,
                        {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                        <div className="col-lg-4 col-md-6">
                            <div className="featured-imagebox featured-imagebox-portfolio style3">
                                <div className="ttm-post-item">
                                    <div className="item-figure">
                                        <div className="featured-thumbnail">
                                            <a href="single-style-1.html">
                                                <img className="img-fluid" src="images/portfolio/portfolio-10-484x565.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="readmore_btn">
                                            <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-darkgrey" href={'/Steam_iron'}>View More</a>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Clear the clutter</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Before you even start cleaning, go room to room and pick up the clutter.</p>
                                        </div>
                                        <div className="ttm-icon-box">
                                            <a href={'/Steam_iron'}><i className="flaticon-right-down"></i></a>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="featured-imagebox featured-imagebox-portfolio style3">
                                <div className="ttm-post-item">
                                    <div className="item-figure">
                                        <div className="featured-thumbnail">
                                            <a href="single-style-1.html">
                                                <img className="img-fluid" src="images/portfolio/portfolio-10-484x565.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="readmore_btn">
                                            <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-darkgrey" href={'/Commercial_laundry'}>View More</a>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Dust and vacuum</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p> Concentrate your dusting on the tops of furniture and the undersides of shelves.</p>
                                        </div>
                                        <div className="ttm-icon-box">
                                            <a href={'/Commercial_laundry'}><i className="flaticon-right-down"></i></a>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="featured-imagebox featured-imagebox-portfolio style3">
                                <div className="ttm-post-item">
                                    <div className="item-figure">
                                        <div className="featured-thumbnail">
                                            <a href="single-style-1.html">
                                                <img className="img-fluid" src="images/portfolio/portfolio-10-484x565.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="readmore_btn">
                                            <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-darkgrey" href={'/Steam_iron'}>View More</a>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Wipe mirrors and glass</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Use one damp microfiber cloth, in wiping clean all the and glass surfaces.</p>
                                        </div>
                                        <div className="ttm-icon-box">
                                        <a href={'/Steam_iron'}><i className="flaticon-right-down"></i></a>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="featured-imagebox featured-imagebox-portfolio style3">
                                <div className="ttm-post-item">
                                    <div className="item-figure">
                                        <div className="featured-thumbnail">
                                            <a href="single-style-1.html">
                                                <img className="img-fluid" src="images/portfolio/portfolio-10-484x565.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="readmore_btn">
                                            <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-darkgrey" href={'/Laundry_services'}>View More</a>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Sweep, then mop</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Start mopping from the farthest corner of the room and move backwards towards the doorway.</p>
                                        </div>
                                        <div className="ttm-icon-box">
                                            <a href={'/Laundry_services'}><i className="flaticon-right-down"></i></a>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="featured-imagebox featured-imagebox-portfolio style3">
                                <div className="ttm-post-item">
                                    <div className="item-figure">
                                        <div className="featured-thumbnail">
                                            <a href="single-style-1.html">
                                                <img width="446" height="521" className="img-fluid" src="images/portfolio/portfolio-10-484x565.jpg" alt="" />
                                            </a>
                                        </div>
                                        <div className="readmore_btn">
                                            <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-darkgrey" href={'/Dry_cleaning'}>View More</a>
                                        </div>
                                    </div>
                                    <div className="featured-content">
                                        <div className="featured-title">
                                            <h3>Clean the whole house</h3>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Pick one task and do it in every room in the house feeling like you’re in an endless cleaning cycle.</p>
                                        </div>
                                        <div className="ttm-icon-box">
                                            <a href={'/Dry_cleaning'}><i className="flaticon-right-down"></i></a>
                                        </div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
           
            <section className="ttm-row padding_bottom_zero-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-duration="1000">
                            {/* section title */}
                            <div className="section-title style1 pt-50 pt-lg-0">
                                <div className="title-header">
                                    <h3>Cleaning Excellence!</h3>
                                    <h2 className="title">Offering expanded disinfection services</h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                        <div className="col-xl-2 col-md-6" data-aos="fade-up" data-aos-duration="1000">
                            <div className="ttm_single_image-wrapper">
                                <img src="./images/single-img-189x185.png" alt="" />
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 pt-50 pl-30 mt-lg-0" data-aos="fade-up" data-aos-duration="1000">
                            <p>Providing the best possible approach to garment for care and superior service by using 
                               the latest dry cleaning and drying technology.</p>
                        </div>
                        <div className="col-xl-2 col-md-6" data-aos="fade-up" data-aos-duration="1000">
                            <div className="text-center">
                                <a href={'/About_us'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark mt-50"> Explore offers </a>
                            </div>
                        </div>
                    </div>{/* row end */}
                    <div className="separator"><div className="sep-line mt-4 mb-0"></div></div>
                </div>
            </section>

            <section className="ttm-row padding_top_zero-section marque-section overflow-hidden">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 ttm-marquebox-wrapper">
                            <ul role="list" className="marquebox-list">
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Dry cleaning</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Alterations & repairs</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Commercial laundry</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Laundry services</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Alterations & repairs</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Laundry services</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Commercial laundry</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row bottom-zero-padding-section fid-section bg-theme-SkinColor clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="section-title style2 clearfix">
                                <div className="title-header">
                                    <h2>Domestic cleaning </h2>
                                </div>
                                <div className="title-desc">
                                    <p>One prevent answer for all of your dry cleaning, laundry and stitching needs</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            {/*ttm-fid*/}
                            <div className="ttm-fid inside style2 text-theme-WhiteColor">
                                <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                <h3 className="ttm-fid-title">since</h3>{/*ttm-fid-title*/}
                                    <h4 className="ttm-fid-inner">
                                        <CountUp start={0} end={1994} duration={500} />
                                    </h4>
                                </div>
                            </div>{/* ttm-fid end*/}
                        </div>
                        <div className="col-md-4 col-sm-4">
                            {/*ttm-fid*/}
                            <div className="ttm-fid inside style2 text-theme-WhiteColor">
                                <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                <h3 className="ttm-fid-title">Design</h3>{/*ttm-fid-title*/}
                                    <h4 className="ttm-fid-inner">
                                        <CountUp start={0} end={300} duration={100} />
                                    </h4>
                                </div>
                            </div>{/* ttm-fid end*/}
                        </div>
                    </div>
                    <div className="separator">
                        <div className="sep-line mt-4 mb-4" />
                    </div>
                </div>
            </section>

            <section className="ttm-row padding_top_zero-section bg-theme-SkinColor clearfix">
                <div className="container">              
                    <Slider className="row slick_slider slick-arrows-style1" {...slick_slider} slidesToShow={3}arrows={true}
                    autoplay={false} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 2,slidesToScroll: 2 }},
                        {breakpoint: 767, settings: { slidesToShow: 1, slidesToScroll: 1 }},
                        {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-portfolio style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img src="./images/portfolio/portfolio-5-1200x800.jpg" alt="" />
                                </div>{/* featured-thumbnail end*/}
                                {/* ttm-box-view-overlay */}
                                <div className="ttm-box-view-overlay">
                                    <div className="featured-content">
                                        <div className="featured-title">{/* featured-title */}
                                        <p>Laundry cleaning</p>
                                            <h3>
                                                <a href={'/Laundry_services'}>Laundry services</a>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Laundry_services'}
                                            className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-portfolio style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                <img src="./images/portfolio/portfolio-5-1200x800.jpg" alt="" />
                                </div>{/* featured-thumbnail end*/}
                                {/* ttm-box-view-overlay */}
                                <div className="ttm-box-view-overlay">
                                    <div className="featured-content">
                                        <div className="featured-title">{/* featured-title */}
                                        <p>Fabric softeners</p>
                                            <h3>
                                                <a href={'/Steam_iron'}>Steam iron</a>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Steam_iron'}
                                            className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-portfolio style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                <img src="./images/portfolio/portfolio-5-1200x800.jpg" alt="" />
                                </div>{/* featured-thumbnail end*/}
                                {/* ttm-box-view-overlay */}
                                <div className="ttm-box-view-overlay">
                                    <div className="featured-content">
                                        <div className="featured-title">{/* featured-title */}
                                        <p>Dry cleaning</p>
                                            <h3>
                                                <a href={'/Leather_cleaning'}>Wet washing</a>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Leather_cleaning'}
                                            className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-portfolio style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img src="./images/portfolio/portfolio-5-1200x800.jpg" alt="" />
                                </div>{/* featured-thumbnail end*/}
                                {/* ttm-box-view-overlay */}
                                <div className="ttm-box-view-overlay">
                                    <div className="featured-content">
                                        <div className="featured-title">{/* featured-title */}
                                        <p>Office cleaning</p>
                                            <h3>
                                                <a href={'/Commercial_laundry'}>Stain removal</a>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Commercial_laundry'}
                                            className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-portfolio style1">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img src="./images/portfolio/portfolio-5-1200x800.jpg" alt="" />
                                </div>{/* featured-thumbnail end*/}
                                {/* ttm-box-view-overlay */}
                                <div className="ttm-box-view-overlay">
                                    <div className="featured-content">
                                        <div className="featured-title">{/* featured-title */}
                                        <p>Stain removal</p>
                                            <h3>
                                                <a href={'/Alterations_repairs'}>Dry clean services</a>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Alterations_repairs'}
                                            className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm">
                                            <i className="flaticon-up-right-arrow"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    <div className="row">
                        <div className="col-md-12 text-center mt-80 res-991-mt-30 mt-lg-40 text-bottom">
                            <p className="mb-0 text-theme-WhiteColor">Enjoy discounts, eBox, pickup and delivery with 24 hour support.
                            <strong><u><a href={'/Our_case_study'}> View More Case Study</a></u></strong></p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-12">
                            {/* section title */}
                            <div className="section-title title-style-center_text mb-0">
                                <div className="title-header">
                                    <h3>We Are Committed To Cleaning Excellence!</h3>
                                    <h2 className="title">Services plans &  <span className='webkit-content'>[Pricing]</span></h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6" data-aos="fade-right" data-aos-duration="1000">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="ttm-pricing-plan">
                                        <div className="ttm-p_table-head">
                                            <div className="ttm-p_table-main-icon">
                                                <div className="ttm-p_table-icon">
                                                    <i className="flaticon-blanket"></i>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3><a href={'/Contact_01'}></a>Dry cleaning</h3>
                                                    <p>Wash, Dry and Fold</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="pac_frequency">From </span>
                                                    <span className="cur_symbol">$</span>
                                                    <span className="ttm-ptablebox-price">22.00</span>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-footer">
                                                <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ttm-pricing-plan">
                                        <div className="ttm-p_table-head">
                                            <div className="ttm-p_table-main-icon">
                                                <div className="ttm-p_table-icon">
                                                    <i className="flaticon-dress"></i>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                <h3><a href={'/Contact_01'}></a>Dress service</h3>
                                                    <p>Dry Cleaning</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="pac_frequency">From </span>
                                                    <span className="cur_symbol">$</span>
                                                    <span className="ttm-ptablebox-price">31.00</span>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-footer">
                                                <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row border-top">
                                <div className="col-md-6">
                                    <div className="ttm-pricing-plan">
                                        <div className="ttm-p_table-head">
                                            <div className="ttm-p_table-main-icon">
                                                <div className="ttm-p_table-icon">
                                                    <i className="flaticon-iron"></i>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                <h3><a href={'/Contact_01'}></a>Ironing service</h3>
                                                    <p>Iron and Fold</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="pac_frequency">From </span>
                                                    <span className="cur_symbol">$</span>
                                                    <span className="ttm-ptablebox-price">17.00</span>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-footer">
                                                <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="ttm-pricing-plan">
                                        <div className="ttm-p_table-head">
                                            <div className="ttm-p_table-main-icon">
                                                <div className="ttm-p_table-icon">
                                                    <i className="flaticon-curtains"></i>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                <h3><a href={'/Contact_01'}></a>Curtains service</h3>
                                                    <p>Washed and Pressed</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="pac_frequency">From </span>
                                                    <span className="cur_symbol">$</span>
                                                    <span className="ttm-ptablebox-price">24.00</span>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-footer">
                                                <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6" data-aos="fade-left" data-aos-duration="1000">
                            <div className="ttm-single-img-1">
                                <div className="ttm_single_image-wrapper pt-40 pl-30">
                                    <img src="./images/single-img-634x543.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row client-section bg-theme-GreyColor clearfix">
              <div className="container-xl">
                <div className="row">
                  <div className="col-12 text-center">
                    <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={6}arrows={false}
                    autoplay={true} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 4,slidesToScroll: 2 }},
                        {breakpoint: 767, settings: { slidesToShow: 3, slidesToScroll: 1 }} ,
                        {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-1-163x31.png" alt="" />
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-2-128x25.png" alt="" />
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-1-163x31.png" alt="" />
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-2-128x25.png" alt="" />
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-1-163x31.png" alt="" />
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-2-128x25.png" alt="" />
                                </div>
                            </div>
                        </div>
                      </div>
                      <div className="ttm-box-col-wrapper">
                        <div className="client-box ttm-box-view-boxed-logo">
                            <div className="client">
                                <div className="ttm-client-logo-tooltip">
                                    <img src="./images/client/client-2-128x25.png" alt="" />
                                </div>
                            </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </section>

            {/* about-section */}
            <section className="ttm-row bg-theme-DarkColor padding_bottom_zero-section clearfix">
                <div className="container">
                    {/* row */}
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pr-md-0 pb-md-0">
                                {/* section title */}
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>Latest News & Articles</h3>
                                        <h2 className="title">Recent articles and <span className='webkit-content'>[News]</span></h2>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                        </div>
                    </div>
                    <Slider className="row slick_slider slick-arrows-style5" {...slick_slider} slidesToShow={3}arrows={true}
                    autoplay={false} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 2,slidesToScroll: 2 }},
                    {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-blog style1">
                                <div className="faetured-image-wrapper">
                                    <div className="featured-thumbnail">
                                        <img src="./images/blog/blog-4-650x433.jpg" alt="" />
                                    </div>
                                    <div className="ttm-box-post-date">
                                        <span className="ttm-entry-date">
                                            <time className="entry-date">09
                                            <span className="entry-month entry-year">Nov</span></time>
                                        </span>
                                    </div>
                                </div>
                                <div className="featured-title">
                                    <h3><a href={'/Blog_details'}>10 reasons to hire professional dry cleaner services</a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-blog style1">
                                <div className="faetured-image-wrapper">
                                    <div className="featured-thumbnail">
                                        <img src="./images/blog/blog-4-650x433.jpg" alt="" />
                                    </div>
                                    <div className="ttm-box-post-date">
                                        <span className="ttm-entry-date">
                                            <time className="entry-date">08
                                            <span className="entry-month entry-year">Nov</span></time>
                                        </span>
                                    </div>
                                </div>
                                <div className="featured-title">
                                    <h3><a href={'/Blog_details'}>We wash clothes with the best detergent</a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-blog style1">
                                <div className="faetured-image-wrapper">
                                    <div className="featured-thumbnail">
                                        <img src="./images/blog/blog-4-650x433.jpg" alt="" />
                                    </div>
                                    <div className="ttm-box-post-date">
                                        <span className="ttm-entry-date">
                                            <time className="entry-date">03
                                            <span className="entry-month entry-year">Dec</span></time>
                                        </span>
                                    </div>
                                </div>
                                <div className="featured-title">
                                    <h3><a href={'/Blog_details'}>We wash shirts and pantsdrying detergent</a></h3>
                                </div>
                            </div>
                        </div>
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-blog style1">
                                <div className="faetured-image-wrapper">
                                    <div className="featured-thumbnail">
                                        <img src="./images/blog/blog-4-650x433.jpg" alt="" />
                                    </div>
                                    <div className="ttm-box-post-date">
                                        <span className="ttm-entry-date">
                                            <time className="entry-date">09
                                            <span className="entry-month entry-year">Nov</span></time>
                                        </span>
                                    </div>
                                </div>
                                <div className="featured-title">
                                    <h3><a href={'/Blog_details'}>10 reasons to hire professional dry cleaner services</a></h3>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    <div className="separator">
                        <div className="sep-line-footer">
                        </div>
                    </div>
                </div>
            </section>
            {/* about-section */}

            <Footer/>
                        
          </div>
        )
    }
}


export default Home;
