import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Slider from 'react-slick';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import ProgressBar from 'react-animated-progress-bar';
import CountUp from 'react-countup';


export class Our_case_study extends Component {

    constructor() {
        super();
        this.state = {
          name: "React"
        };
        this.onChangeValue = this.onChangeValue.bind(this);

    }
    
    onChangeValue(event) {
        console.log(event.target.value);
    }

    render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [{
      
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        var slick_slider_ltr = {
            infinite: true,
            arrows: false,
            dots: false,                   
            autoplay: true,
            centerMode : false,
            autoplaySpeed: 0,
            speed: 9000,
            cssEase: "linear",
            pauseOnHover: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            }
    
            var slick_slider_rtl = {
            infinite: true,
            arrows: false,
            dots: false,                   
            autoplay: true,
            centerMode : false,
            rtl: true,
            autoplaySpeed: 0,
            speed: 9000,
            cssEase: "linear",
            pauseOnHover: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            };
        return (

            <div className="site-main">
                <Header/>
            
                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Our Portfolio</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;/ Services</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Our Portfolio</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>

                <section className="ttm-row clearfix">
                    <div className="container">              
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="ttm-box-col-wrapper mb-55">
                                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                                        {/* featured-thumbnail */}
                                        <div className="featured-thumbnail">
                                            <img src="./images/portfolio/portfolio-5-1200x800.jpg" />
                                        </div>{/* featured-thumbnail end*/}
                                        {/* ttm-box-view-overlay */}
                                        <div className="ttm-box-view-overlay">
                                            <div className="featured-content">
                                                <div className="featured-title">{/* featured-title */}
                                                <p>Residential service</p>
                                                    <h3>
                                                        <a href={'/Case_study_details'}>Drapery cleaning</a>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="ttm-iconbox">
                                            <a href={'/Steam_iron'}
                                                    className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                                    <i className="flaticon-up-right-arrow"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="ttm-box-col-wrapper mb-55">
                                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                                        {/* featured-thumbnail */}
                                        <div className="featured-thumbnail">
                                        <img src="./images/portfolio/portfolio-6-1200x800.jpg" />
                                        </div>{/* featured-thumbnail end*/}
                                        {/* ttm-box-view-overlay */}
                                        <div className="ttm-box-view-overlay">
                                            <div className="featured-content">
                                                <div className="featured-title">{/* featured-title */}
                                                <p>Office cleaning</p>
                                                    <h3>
                                                        <a href={'/Case_study_details'}>Stain removal</a>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="ttm-iconbox">
                                            <a href={'/Steam_iron'}
                                                    className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                                    <i className="flaticon-up-right-arrow"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="ttm-box-col-wrapper mb-55">
                                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                                        {/* featured-thumbnail */}
                                        <div className="featured-thumbnail">
                                        <img src="./images/portfolio/portfolio-5-1200x800.jpg" />
                                        </div>{/* featured-thumbnail end*/}
                                        {/* ttm-box-view-overlay */}
                                        <div className="ttm-box-view-overlay">
                                            <div className="featured-content">
                                                <div className="featured-title">{/* featured-title */}
                                                <p>Dry cleaning</p>
                                                    <h3>
                                                        <a href={'/Case_study_details'}>Wet washing</a>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="ttm-iconbox">
                                                <a href={'/Steam_iron'}
                                                    className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                                    <i className="flaticon-up-right-arrow"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="ttm-box-col-wrapper mb-md-55">
                                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                                        {/* featured-thumbnail */}
                                        <div className="featured-thumbnail">
                                        <img src="./images/portfolio/portfolio-6-1200x800.jpg" />
                                        </div>{/* featured-thumbnail end*/}
                                        {/* ttm-box-view-overlay */}
                                        <div className="ttm-box-view-overlay">
                                            <div className="featured-content">
                                                <div className="featured-title">{/* featured-title */}
                                                <p>Fabric softeners</p>
                                                    <h3>
                                                        <a href={'/Case_study_details'}>Steam iron</a>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="ttm-iconbox">
                                                <a href={'/Steam_iron'}
                                                    className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                                    <i className="flaticon-up-right-arrow"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="ttm-box-col-wrapper mb-md-55">
                                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                                        {/* featured-thumbnail */}
                                        <div className="featured-thumbnail">
                                        <img src="./images/portfolio/portfolio-5-1200x800.jpg" />
                                        </div>{/* featured-thumbnail end*/}
                                        {/* ttm-box-view-overlay */}
                                        <div className="ttm-box-view-overlay">
                                            <div className="featured-content">
                                                <div className="featured-title">{/* featured-title */}
                                                <p>Laundry cleaning</p>
                                                    <h3>
                                                        <a href={'/Case_study_details'}>Laundry services</a>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="ttm-iconbox">
                                                <a href={'/Steam_iron'}
                                                    className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                                    <i className="flaticon-up-right-arrow"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="ttm-box-col-wrapper mb-md-55">
                                    <div className="featured-imagebox featured-imagebox-portfolio style1">
                                        {/* featured-thumbnail */}
                                        <div className="featured-thumbnail">
                                        <img src="./images/portfolio/portfolio-6-1200x800.jpg" />
                                        </div>{/* featured-thumbnail end*/}
                                        {/* ttm-box-view-overlay */}
                                        <div className="ttm-box-view-overlay">
                                            <div className="featured-content">
                                                <div className="featured-title">{/* featured-title */}
                                                <p>Stain removal</p>
                                                    <h3>
                                                        <a href={'/Case_study_details'}>Dry clean services</a>
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className="ttm-iconbox">
                                                <a href={'/Steam_iron'}
                                                    className="ttm-icon ttm-icon_element-border ttm-icon_element-style-round ttm-icon_element-color-grey ttm-icon_element-size-sm" tabindex="0">
                                                    <i className="flaticon-up-right-arrow"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row padding_top_zero-section marque-section_2 bg-theme-GreyColor clearfix">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 ttm-marquebox-wrapper style4">
                                <ul role="list" className="marquebox-list">
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Dry cleaning</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Alterations & repairs</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Commercial laundry</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Laundry services</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Alterations & repairs</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Laundry services</div>
                                    </li>
                                    <li className="marquebox-item">
                                        <div className="ttm-marque-text">Commercial laundry</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="ttm-row bg-theme-DarkColor padding_bottom_zero-section clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title border p-70 border-radius-8 mb-0">
                                    <div className="title-header">
                                        <h2 className="title">Get in <span className="webkit-content">[Clean]</span></h2>
                                    </div>
                                    <div className="featured-icon-box text-theme-WhiteColor icon-align-before-title style6">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-xl">
                                            <a href="#" className="mega-menu-link">
                                                <i className="flaticon-up-right-arrow-1"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                
            <Footer/>
                        
            </div>
          )
      }
   }

export default Our_case_study;