import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Slider from 'react-slick';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


export class Register extends Component {

        render() {
            var slick_slider = {
              dots: false,
              arrow: false,
              autoplay: true,
              infinite: true,
              speed: 1000,
              slidesToShow: 4,
              slidesToScroll: 1,
              rows: 1,
      
              responsive: [{
          
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
            };
              var slick_slider_ltr = {
              infinite: true,
              arrows: false,
              dots: false,                   
              autoplay: true,
              centerMode : false,
              autoplaySpeed: 0,
              speed: 9000,
              cssEase: "linear",
              pauseOnHover: true,
              slidesToShow: 3,
              slidesToScroll: 1,
              }
      
              var slick_slider_rtl = {
              infinite: true,
              arrows: false,
              dots: false,                   
              autoplay: true,
              centerMode : false,
              rtl: true,
              autoplaySpeed: 0,
              speed: 9000,
              cssEase: "linear",
              pauseOnHover: true,
              slidesToShow: 3,
              slidesToScroll: 1,
              };
        return (

            <div className="site-main">
                <Header />
            
                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                                <div className="title-box text-center">
                                    <div className="page-title-heading">
                                        <h2 className="title">Pricing</h2>
                                    </div>{/* /.page-title-captions */}
                                    <div className="breadcrumb-wrapper">
                                        <span>
                                            <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                        </span>
                                        <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                        <span>Pricing</span>
                                    </div>  
                                </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>

                <section className="ttm-row clearfix">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 m-auto">
                                <div className="section-title title-style-center_text">
                                    <div className="title-header">
                                        <h3>We Are Committed To Cleaning Excellence!</h3>
                                        <h2 className="title">Our dry cleaning <span className='webkit-content'>[Prices]</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row" data-aos="zoom-out-up" data-aos-duration="2000">
                                <div className="col-xl-4 col-md-12">
                                    <ul className="ttm-pricing-plan style1">
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Arm/Leg Warmer</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$5.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Coat </h3>
                                                    <p> (Designer)</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$7.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Coat </h3>
                                                    <p> (Half)</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$6.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Coat Liner </h3>
                                                    <p> (Half)</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$8.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Dress </h3>
                                                    <p> (Children)</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$4.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Dress </h3>
                                                    <p> (Formal)</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$7.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Gloves</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$5.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Handkerchief</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$3.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-4 col-md-12">
                                    <ul className="ttm-pricing-plan style1">
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Handkerchief</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$6.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Hat</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$5.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Jacket </h3>
                                                    <p> (Down)</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$7.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Jacket </h3>
                                                    <p> (Leather)</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$8.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Jeans</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$6.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Jumpsuit</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$5.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Lab Coat</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$4.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Raincoat</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$7.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-xl-4 col-md-12">
                                    <ul className="ttm-pricing-plan style1">
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Robe</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$5.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Scarf</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$3.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Shawl</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$6.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Shorts</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$9.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Skirt</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$7.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Sweater</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$6.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Tie</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$3.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="ttm-p_table-content">
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Vests</h3>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="ttm-ptablebox-price">$5.00</span>
                                                    <a className="ttm-btn ttm-btn-size-md btn-inline ttm-btn-color-SkinColor" href="#">Order Now </a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row services-section-3 bg-theme-SkinColor clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row" data-aos="zoom-out" data-aos-duration="2000">
                            <div className="col-lg-7">
                                {/* section title */}
                                <div className="section-title style1">
                                    <div className="title-header">
                                        <h3>Cleaning Excellence!</h3>
                                        <h2 className="title">Book our laundrolift laundry<br/> services and get 
                                        <span className="fs-52"> 35% </span> discount</h2>
                                    </div>
                                </div>{/* section title end */}
                            </div>
                            <div className="col-lg-2">
                                <div className="ttm_single_image-wrapper">
                                    <img src="./images/single-img-189x185.png" />
                                </div>
                            </div>
                            <div className="col-lg-3">
                                <div className="pl-60 pl-lg-0">
                                    <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white mt-65">order service now</a>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </section>

                <section className="ttm-row clearfix">
                    <div className="container">
                        <div className="row" data-aos="fade-up" data-aos-duration="1000">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>We Are Committed To Cleaning Excellence!</h3>
                                        <h2 className="title">Services plans & <span className="webkit-content">[Pricing]</span></h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-3 col-md-6">
                                    <div className="ttm-pricing-plan">
                                        <div className="ttm-p_table-head">
                                            <div className="ttm-p_table-main-icon">
                                                <div className="ttm-p_table-icon">
                                                    <i className="flaticon-drying"></i>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Dry cleaning</h3>
                                                    <p>Wash, dry and fold</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="pac_frequency">From </span>
                                                    <span className="cur_symbol">$</span>
                                                    <span className="ttm-ptablebox-price">22.00</span>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-footer">
                                            <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="ttm-pricing-plan">
                                        <div className="ttm-p_table-head">
                                            <div className="ttm-p_table-main-icon">
                                                <div className="ttm-p_table-icon">
                                                    <i className="flaticon-dress"></i>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Day dress service</h3>
                                                    <p>Dry Cleaning</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="pac_frequency">From </span>
                                                    <span className="cur_symbol">$</span>
                                                    <span className="ttm-ptablebox-price">31.00</span>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-footer">
                                            <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="ttm-pricing-plan">
                                        <div className="ttm-p_table-head">
                                            <div className="ttm-p_table-main-icon">
                                                <div className="ttm-p_table-icon">
                                                    <i className="flaticon-iron"></i>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Ironing service</h3>
                                                    <p>Iron and Fold</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="pac_frequency">From </span>
                                                    <span className="cur_symbol">$</span>
                                                    <span className="ttm-ptablebox-price">17.00</span>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-footer">
                                            <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="ttm-pricing-plan">
                                        <div className="ttm-p_table-head">
                                            <div className="ttm-p_table-main-icon">
                                                <div className="ttm-p_table-icon">
                                                    <i className="flaticon-curtains"></i>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-heading">
                                                <div className="ttm-p_table-title">
                                                    <h3>Curtains service</h3>
                                                    <p>Washed and Pressed</p>
                                                </div>
                                                <div className="ttm-p_table-amount">
                                                    <span className="pac_frequency">From </span>
                                                    <span className="cur_symbol">$</span>
                                                    <span className="ttm-ptablebox-price">24.00</span>
                                                </div>
                                            </div>
                                            <div className="ttm-p_table-footer">
                                            <a href={'/Contact_01'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark w-auto"> Explore offers </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row services-details-section bg-theme-GreyColor bg-img1 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title title-style-center_text mb-0">
                                    <div className="title-header">
                                        <h3>How we work</h3>
                                        <h2 className="title">How clean world <span className='webkit-content'>[Works]</span></h2>
                                    </div>
                                </div>  
                            </div>
                        </div>
                        <div className="row" data-aos="zoom-in" data-aos-duration="1000">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="featured-icon-box style12">
                                    <div className="featured-num-box">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-booking"></i>
                                            </div>
                                        </div>
                                        <div className="process-num">
                                            <span className="number">1</span>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Free pickup</h3>
                                            </div>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Why pay for convenience a full bag of laundry or just one shirt, we pick-up for free!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="featured-icon-box style12">
                                    <div className="featured-num-box">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-washing-machine"></i>
                                            </div>
                                        </div>
                                        <div className="process-num">
                                            <span className="number">2</span>
                                        </div>
                                    
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>Reasonable price</h3>
                                            </div>
                                        </div>
                                        <div className="featured-desc">
                                            <p>Top quality service at a very affordable price. It doesn't get any better than that!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="featured-icon-box style12">
                                    <div className="featured-num-box">
                                        <div className="featured-icon">
                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                                <i className="flaticon-laundry-basket"></i>
                                            </div>
                                        </div>
                                        <div className="process-num">
                                            <span className="number">3</span>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-title">
                                                <h3>No cash, no problem</h3>
                                            </div>
                                        </div>
                                        <div className="featured-desc">
                                            <p>On delivery, you can pay through our secure payment gateway or simply make a payment</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="ttm-row padding_top_zero-section marque-section clearfix">
                    <div className="container-fluid">
                        <div className="row">
                            <Slider className="row slick_slider_ltr marque-box" {...slick_slider_ltr} slidesToShow={2} 
                            arrows={true} autoplay={true} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 2,slidesToScroll: 2 }},
                                {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] }>
                                <div className="ttm-marquee-box-wrapper">
                                    <div className="ttm-marquee-box-content">
                                        <div className="ttm-box-title">
                                            <h3 className="marque-text">Alterations & repairs</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-marquee-box-wrapper">
                                    <div className="ttm-marquee-box-content">
                                        <div className="ttm-box-title">
                                            <h3 className="marque-text">Commercial laundry</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-marquee-box-wrapper">
                                    <div className="ttm-marquee-box-content">
                                        <div className="ttm-box-title">
                                            <h3 className="marque-text">Dry cleaning</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-marquee-box-wrapper">
                                    <div className="ttm-marquee-box-content">
                                        <div className="ttm-box-title">
                                            <h3 className="marque-text">Laundry services</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-marquee-box-wrapper">
                                    <div className="ttm-marquee-box-content">
                                        <div className="ttm-box-title">
                                            <h3 className="marque-text">Commercial laundry</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-marquee-box-wrapper">
                                    <div className="ttm-marquee-box-content">
                                        <div className="ttm-box-title">
                                            <h3 className="marque-text">Dry cleaning</h3>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </section>
                
            <Footer/>
                        
            </div>
          )
      }
   }

export default Register;