import React, { Component, useEffect, useState } from 'react';
import Menu from './Menu';
import Mobile_menu from './Mobile_menu';
import Logo from './Logo'


export class Header extends Component {

    componentDidMount() {
        window.addEventListener('scroll', this.isSticky);


    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.isSticky);
    }

    isSticky = (e)=>{
        const header = document.querySelector('header');
        const scrollTop = window.scrollY;
        scrollTop >= 250 ? header.classList.add('is-Sticky') : header.classList.remove('is-Sticky');
    };

    render() {        
        return (
                
            <header id="masthead" className="header ttm-header-style-01">
                {/* ttm-topbar-wrapper */}
                <div className="ttm-topbar-wrapper bg-theme-SkinColor text-theme-WhiteColor clearfix">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-5">
                                <ul className="top-contact text-left">
                                    <li>24 hours emergency assistance call us 1-800-123-4560</li>
                                    <li><i className="ti ti-location-pin"/>&nbsp;&nbsp;Our locations</li>
                                </ul>
                            </div>
                            <div className="col-lg-7">
                                <div className="topbar-right text-right d-flex justify-content-end">
                                    <ul className="top-contact">
                                        <li><i className="far fa-clock" />&nbsp;&nbsp;Opening hours: 06:00AM to 20:00PM</li>
                                    </ul>
                                    <div className="ttm-social-links-wrapper list-inline">
                                        <ul className="social-icons">
                                            <li><a href="https://www.facebook.com/preyantechnosys19" className=" tooltip-bottom" data-tooltip="Facebook"><i className="fab fa-facebook-f" /></ a>
                                            </li>
                                            <li><a href="https://twitter.com/PreyanTechnosys" className=" tooltip-bottom" data-tooltip="Twitter"><i className="fab fa-twitter" /></ a>
                                            </li>
                                            <li><a href="https://www.instagram.com/preyan_technosys/" className=" tooltip-bottom" data-tooltip="Instagram"><i className="fab fa-instagram" /></ a>
                                            </li>
                                            <li><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/" className=" tooltip-bottom" data-tooltip=" aedin"><i className="fab fa-linkedin-in" /></ a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ttm-topbar-wrapper end */}
                {/* site-header-menu */}
                <div id="site-header-menu" className="site-header-menu bg-theme-WhiteColor">
                    <div className="site-header-menu-inner ttm-stickable-header">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* site-navigation */}
                                    <div className="site-navigation d-flex flex-row align-items-center justify-content-between">
                                        {/* site-branding */}
                                        <div className="site-branding">
                                            <Logo/>
                                        </div>
                                        {/* site-branding end */}
                                        <div className="border-box-block">
                                            <div className=" d-flex align-items-center">
                                                {/* menu */}
                                                <Menu/>
                                                <div className="mobile-menu"><Mobile_menu/></div>
                                                {/* menu end */}
                                                <div className="ttm-widget_header d-flex flex-row">
                                                    <div className="widget_info d-flex flex-row align-items-center justify-content-end pl-50">
                                                        <div className="widget_icon">
                                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md mb-0">
                                                                <i className="flaticon-phone-call"></i>
                                                            </div>
                                                        </div>
                                                        <div className="widget_content">
                                                            <span className="widget_title ttm-textcolor-skincolor">Call us now!</span>
                                                            <h3 className="widget_number"><a href="tel:+1822223555">+18 - 2222 - 3555</a></h3>
                                                        </div>
                                                        <div className="widget_icon">
                                                            <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-color-skincolor ttm-icon_element-size-md mb-0">
                                                                <i className="flaticon-bubble-chat"></i>
                                                            </div>
                                                        </div>
                                                        <div className="widget_content">
                                                            <span className="widget_title ttm-textcolor-skincolor">Talk to us</span>
                                                            <h3 className="widget_number"><a href="mailto:info@laundrolift.com">help@domain.com</a></h3>
                                                        </div>
                                                    </div>
                                                    <div className="header_btn">
                                                        <a href={'/Laundry_services'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark mt-5"> Schedule a Pickup </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* site-navigation end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* site-header-menu end */}
            </header> 
        )
    }
}

export default Header;