import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Slider from 'react-slick';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import ProgressBar from 'react-animated-progress-bar';
import CountUp from 'react-countup';


export class Steam_iron extends Component {

    constructor() {
        super();
        this.state = {
          name: "React"
        };
        this.onChangeValue = this.onChangeValue.bind(this);

    }
    
    onChangeValue(event) {
        console.log(event.target.value);
    }

    render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [{
      
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        return (

            <div className="site-main">
                <Header/>
            
                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Steam iron</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;/ Services</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Steam iron</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>

                <div className="sidebar clearfix">
                    <div className="container">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-4 widget-area sidebar-left border-right pr-45 pr-lg-15">
                                <aside className="widget widget-nav-menu">
                                    <h3 className="widget-title">Our services</h3>
                                    <ul>
                                        <li className="d-flex">Alterations & repairs</li>
                                        <li className="d-flex">Commercial laundry</li>
                                        <li className="d-flex">Dry cleaning</li>
                                        <li className="d-flex">Laundry services</li>
                                        <li className="d-flex">Leather cleaning</li>
                                        <li className="d-flex">Steam iron</li>
                                    </ul>
                                </aside>
                                <aside className="widget image-box">
                                    <div className="widget_img">
                                        <img src="./images/single-img-8-369x154.jpg" />
                                    </div>
                                    <div className="featured-imagebox featured-imagebox-service style2">
                                        <div className="featured-image-box-wrapper">
                                            <div className="featured-thumbnail">
                                                <img width="41" height="41" src="./images/whats-up.png" />
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <h2>Call us for all your laundry needs</h2>
                                    </div>
                                </aside>
                                <aside className="widget widget-download">
                                    <h3 className="widget-title">Download</h3>
                                    <ul className="download">
                                        <li>
                                            <a><h3>Repair</h3>
                                            <i className="far fa-file-pdf" /></a>
                                        </li>
                                        <li>
                                            <a><h3>Maintenance</h3>
                                            <i className="far fa-file-pdf" /></a>
                                        </li>
                                    </ul>
                                    <h3 className="widget-title">Share now</h3>
                                    <div className="ttm-social-links-wrapper mt-20">
                                        <ul className="social-icons">
                                            <li className="social-facebook">
                                                <a target="_blank" href="https://www.facebook.com/preyantechnosys19"><i className="fab fa-facebook-f" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-twitter">  
                                                <a target="_blank" href="https://twitter.com/PreyanTechnosys"><i className="fab fa-twitter" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-pinterest">
                                                <a target="_blank" href="https://dribbble.com/PreyanTechnosys"><i className="fab fa-dribbble" aria-hidden="true"></i></a>
                                            </li>
                                            <li className="social-instagram">
                                                <a target="_blank" href="https://www.instagram.com/preyan_technosys/"><i className="fab fa-linkedin-in" aria-hidden="true"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </aside>
                            </div>
                            <div className="col-lg-8 widget-area sidebar-right pl-45 pl-lg-15">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12">
                                        <div className="ttm-service-classic-content">
                                            <p>The best way to give your clothes a professionally pressed look and feel from the comfort of your home.
                                            The steam from the iron moistens the fibers of your clothes and makes them easier to press. Not only this,
                                            but the steam also helps keep the fibers in place for a longer duration.</p>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 mt-30">
                                                <img src="./images/single-img-17-403x401.png" />
                                            </div>
                                            <div className="col-lg-6 col-md-6 mt-30">
                                                <img src="./images/single-img-17-403x401.png" />
                                            </div>
                                        </div>
                                        <div className="ttm-service-classic-content">
                                            <h2>Why choose this service</h2>
                                            <p>Many traditional dry irons contain a steam option. Water is poured into
                                            a small reservoir and superheated until it becomes usable steam. A mechanical
                                            switch on the handle of the iron allows the user to select the steam setting.
                                            The steam itself should come out of small holes located near the tip of the
                                            pressing plate.</p>
                                        </div>
                                        <div className="separator">
                                            <div className="sep-line mt-4 mb-4"></div>
                                        </div>
                                        <ul className="ttm-list style1">
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <span className="ttm-list-li-content">24/7 Support</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <span className="ttm-list-li-content">Offers & cares</span>
                                            </li>
                                            <li>
                                                <i className="fa fa-check"></i>
                                                <span className="ttm-list-li-content">Expert teams</span>
                                            </li>
                                        </ul>
                                        <div className="separator">
                                            <div className="sep-line mt-4 mb-4"></div>
                                        </div>
                                        <div className="ttm-service-classic-content">
                                            <h2>Meet our team</h2>
                                            <p>Our professional dry cleaning team is well trained under hygiene & safety for the customer's clothes'</p>
                                        </div>
                                        <Slider className="row mt-20 slick_slider slick-dots-style1" {...slick_slider} slidesToShow={3}arrows={false}
                                        dots={false}autoplay={false} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 2,slidesToScroll: 2 }},
                                        {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] } >
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="featured-imagebox featured-imagebox-team style1">
                                                    <div className="featured-thumbnail">
                                                        <img src="./images/team/team-4-297x356.jpg" />
                                                    </div>
                                                    <div className="social-icon-wrapper">
                                                        <ul className="social-icons list-inline">
                                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-content-box-inner">
                                                            <p>manager</p>
                                                            <div className="featured-title">
                                                                <h3><a href={'/Team_details'}>Juliana SIlva</a></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="featured-imagebox featured-imagebox-team style1">
                                                    <div className="featured-thumbnail">
                                                        <img src="./images/team/team-4-297x356.jpg" />
                                                    </div>
                                                    <div className="social-icon-wrapper">
                                                        <ul className="social-icons list-inline">
                                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-content-box-inner">
                                                            <p>Team lead</p>
                                                            <div className="featured-title">
                                                                <h3><a href={'/Team_details'}>Joel Lou</a></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="featured-imagebox featured-imagebox-team style1">
                                                    <div className="featured-thumbnail">
                                                        <img src="./images/team/team-4-297x356.jpg" />
                                                    </div>
                                                    <div className="social-icon-wrapper">
                                                        <ul className="social-icons list-inline">
                                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-content-box-inner">
                                                            <p>Organizer</p>
                                                            <div className="featured-title">
                                                                <h3><a href={'/Team_details'}>Reshta Wann</a></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12">
                                                <div className="featured-imagebox featured-imagebox-team style1">
                                                    <div className="featured-thumbnail">
                                                        <img src="./images/team/team-4-297x356.jpg" />
                                                    </div>
                                                    <div className="social-icon-wrapper">
                                                        <ul className="social-icons list-inline">
                                                            <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                                            <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                                            <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                                            <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="featured-content">
                                                        <div className="featured-content-box-inner">
                                                            <p>Organizer</p>
                                                            <div className="featured-title">
                                                                <h3><a href={'/Team_details'}>Reshta Wann</a></h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>
                
            <Footer/>
                        
            </div>
          )
      }
   }

export default Steam_iron;