import React, { Component, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import api from './api';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './css/vendor/flaticon.css';
import './css/vendor/fontawesome.min.css';
import './css/vendor/themify-icons.css';

import Home from './pages/Home';
import Home2 from './pages/Home2';
import Home3 from './pages/Home3';

import About_us from './pages/About_us';
import Services from './pages/Services';
import Services_2 from './pages/Services_2';
import Contact_01 from './pages/Contact_01';
import Contact_02 from './pages/Contact_02';
import Our_team from './pages/Our_team';
import About_us_2 from './pages/About_us_2';
import Team_details from './pages/Team_details';
import Pricing from './pages/Pricing';
import TermsAndConditions from './pages/termses';
import Dry_cleaning from './services/Dry_cleaning';
import Alterations_repairs from './services/Alterations_repairs';
import Commercial_laundry from './services/Commercial_laundry';
import Laundry_services from './services/Laundry_services';
import Admin_Panel from './services/Admin_Panel'
import Leather_cleaning from './services/Leather_cleaning';
import Steam_iron from './services/Steam_iron';
import Our_case_study from './Case-study/Our_case_study';
import Case_study_details from './Case-study/Case_study_details';
import Blog from './Blog/Blog';
import Blog_grid_view from './Blog/Blog_grid_view';
import Blog_details from './Blog/Blog_details';


import ScrollToTop from './components/layout/Gotop';

// const stripePromise = loadStripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh');


function App() {

  return (
    <div className="page">
        <Router>

          <Route exact path={`${'/'}`} component={Home2} />
          <Route exact path={`${'/Home2'}`} component={Home} />
          <Route exact path={`${'/Home3'}`} component={Home3} />

          <Route exact path={`${'/About_us'}`} component={About_us} />
          <Route exact path={`${'/Services'}`} component={Services} />
          <Route exact path={`${'/Services_2'}`} component={Services_2} />
          <Route exact path={`${'/Contact_01'}`} component={Contact_01} />
          <Route exact path={`${'/Contact'}`} component={Contact_02} />
          <Route exact path={`${'/Our_team'}`} component={Our_team} />
          <Route exact path={`${'/About'}`} component={About_us_2} />
          <Route exact path={`${'/Team_details'}`} component={Team_details} />
          <Route exact path={`${'/Pricing'}`} component={Pricing} />
          <Route exact path={`${'/Dry_cleaning'}`} component={Dry_cleaning} />
          <Route exact path={`${'/Alterations_repairs'}`} component={Alterations_repairs} />
          <Route exact path={`${'/Commercial_laundry'}`} component={Commercial_laundry} />
          <Route exact path={`${'/Laundry_services'}`} component={Laundry_services} />
          <Route exact path={`${'/admin_panel'}`} component={Admin_Panel} />
          <Route exact path={`${'/House_cleaning'}`} component={Leather_cleaning} />
          <Route exact path={`${'/Steam_iron'}`} component={Steam_iron} />
          <Route exact path={`${'/Our_case_study'}`} component={Our_case_study} />
          <Route exact path={`${'/Case_study_details'}`} component={Case_study_details} />
          <Route exact path={`${'/Blog'}`} component={Blog} />
          <Route exact path={`${'/Blog_grid_view'}`} component={Blog_grid_view} />
          <Route exact path={`${'/Blog_details'}`} component={Blog_details} />
          <Route exact path={`${'/policy'}`} component={TermsAndConditions} />


          <ScrollToTop />
        </Router>
    </div>
  );
}

export default App;


