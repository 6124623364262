import React, { Component } from 'react';
import Header from '../components/layout/Header';
import Slider from 'react-slick';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PageHeader from "../components/layout/PageHeader";
import { Footer } from '../components/layout/Footer';
import ProgressBar from 'react-animated-progress-bar';
import CountUp from 'react-countup';


export class Blog extends Component {

    constructor() {
        super();
        this.state = {
          name: "React"
        };
        this.onChangeValue = this.onChangeValue.bind(this);

    }
    
    onChangeValue(event) {
        console.log(event.target.value);
    }

    render() {
        var slick_slider = {
          dots: false,
          arrow: false,
          autoplay: true,
          infinite: true,
          speed: 1000,
          slidesToShow: 4,
          slidesToScroll: 1,
          rows: 1,
  
          responsive: [{
      
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
        };
        var slick_slider_ltr = {
            infinite: true,
            arrows: false,
            dots: false,                   
            autoplay: true,
            centerMode : false,
            autoplaySpeed: 0,
            speed: 9000,
            cssEase: "linear",
            pauseOnHover: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            }
    
            var slick_slider_rtl = {
            infinite: true,
            arrows: false,
            dots: false,                   
            autoplay: true,
            centerMode : false,
            rtl: true,
            autoplaySpeed: 0,
            speed: 9000,
            cssEase: "linear",
            pauseOnHover: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            };
        return (

            <div className="site-main">
                <Header/>
            
                <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")'}}>
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                <h2 className="title">Blog</h2>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                <span>
                                    <a title="Homepage" href={'/'} >&nbsp;&nbsp;Home</a>
                                </span>
                                <span className="ttm-bread-sep">&nbsp; / &nbsp;</span>
                                <span>Blog</span>
                                </div>  
                            </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>

                <div className="sidebar clearfix">
                    <div className="container-xl">
                        {/* row */}
                        <div className="row">
                            <div className="col-lg-8 content-area border-right pr-45 pr-lg-15 pb-lg-30">
                                {/* ttm-service-single-content-are */}
                                <article className="post ttm-blog-classic clearfix">
                                    {/* post-featured-wrapper */}
                                    <div className="ttm-post-featured-wrapper ttm-featured-wrapper">
                                        <div className="ttm-post-featured">
                                        <img className="border-radius-8" src="./images/blog/blog-8-835x422.jpg" />
                                        </div>
                                    </div>{/* post-featured-wrapper end */}
                                    {/* ttm-blog-classic-content */}
                                    <div className="ttm-blog-classic-content">
                                        <div className="ttm-post-entry-header">
                                            <div className="post-meta">
                                                <span className="ttm-meta-line byline"><a href={'/Blog_details'}>Sanitizing</a></span>
                                                <span className="ttm-meta-line entry-date">December 3, 2022</span>
                                                <span className="ttm-meta-line tags-links">0 Comments</span>
                                            </div>  
                                        </div>
                                        <div className="entry-content">
                                            <header className="entry-header">
                                                <h2 className="entry-title"><a href={'/Blog_details'}>We wash shirts and pantsdrying with the best detergent</a></h2>
                                            </header>
                                            <div className="ttm-box-desc-text">
                                                <p>It's easy to see that you have a dirty surface that needs cleaning. Figuring out which cleanser to use can
                                                be difficult! Choosing the right product from the start will make your cleaning job easier, faster and more
                                                effic...</p>
                                            </div>
                                            <a href={'/Blog_details'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark mt-30">Read More</a>
                                        </div>
                                    </div>{/* ttm-blog-classic-content end */}
                                    {/* post-featured-wrapper */}
                                    <div className="ttm-post-featured-wrapper ttm-featured-wrapper">
                                        <div className="ttm-post-featured">
                                        <img className="border-radius-8" src="./images/blog/blog-8-835x422.jpg" />
                                        </div>
                                    </div>{/* post-featured-wrapper end */}
                                    {/* ttm-blog-classic-content */}
                                    <div className="ttm-blog-classic-content">
                                        <div className="ttm-post-entry-header">
                                            <div className="post-meta">
                                                <span className="ttm-meta-line byline"><a href={'/Blog_details'}>Sanitizing</a></span>
                                                <span className="ttm-meta-line entry-date">December 3, 2022</span>
                                                <span className="ttm-meta-line tags-links">0 Comments</span>
                                            </div>  
                                        </div>
                                        <div className="entry-content">
                                            <header className="entry-header">
                                                <h2 className="entry-title"><a href={'/Blog_details'}>We wash shirts and pantsdrying with the best detergent</a></h2>
                                            </header>
                                            <div className="ttm-box-desc-text">
                                                <p>It's easy to see that you have a dirty surface that needs cleaning. Figuring out which cleanser to use can
                                                be difficult! Choosing the right product from the start will make your cleaning job easier, faster and more
                                                effic...</p>
                                            </div>
                                            <a href={'/Blog_details'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark mt-30">Read More</a>
                                        </div>
                                    </div>{/* ttm-blog-classic-content end */}
                                    {/* post-featured-wrapper */}
                                    <div className="ttm-post-featured-wrapper ttm-featured-wrapper">
                                        <div className="ttm-post-featured">
                                        <img className="border-radius-8" src="./images/blog/blog-8-835x422.jpg" />
                                        </div>
                                    </div>{/* post-featured-wrapper end */}
                                    {/* ttm-blog-classic-content */}
                                    <div className="ttm-blog-classic-content pb-lg-0">
                                        <div className="ttm-post-entry-header">
                                            <div className="post-meta">
                                                <span className="ttm-meta-line byline"><a href={'/Blog_details'}>Sanitizing</a></span>
                                                <span className="ttm-meta-line entry-date">December 3, 2022</span>
                                                <span className="ttm-meta-line tags-links">0 Comments</span>
                                            </div>  
                                        </div>
                                        <div className="entry-content">
                                            <header className="entry-header">
                                                <h2 className="entry-title"><a href={'/Blog_details'}>We wash shirts and pantsdrying with the best detergent</a></h2>
                                            </header>
                                            <div className="ttm-box-desc-text">
                                                <p>It's easy to see that you have a dirty surface that needs cleaning. Figuring out which cleanser to use can
                                                be difficult! Choosing the right product from the start will make your cleaning job easier, faster and more
                                                effic...</p>
                                            </div>
                                            <a href={'/Blog_details'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark mt-30">Read More</a>
                                        </div>
                                    </div>{/* ttm-blog-classic-content end */}
                                </article>
                                {/* ttm-service-single-content-are end */}
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <div className="pagination-block">
                                            <span className="page-numbers current">1</span>
                                            <a className="page-numbers" href="#">2</a>
                                            <a className="next page-numbers" href="#">Next</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 widget-area sidebar-left pl-45 pl-lg-15">
                                <div className="sidebar-right">
                                    <aside className="widget widget-search">
                                        <h3 className="widget-title">Categories</h3>
                                        <form role="search" className="search-form">
                                            <label>
                                                <span className="screen-reader-text">Search for:</span>
                                                <input type="search" className="input-text" placeholder="Search...." value="" />
                                            </label>
                                            <button className="btn" type="submit"><i className="fa fa-search"></i></button>
                                        </form>
                                    </aside>
                                    <aside className="widget widget-nav-menu">
                                        <h3 className="widget-title">Categories</h3>
                                        <ul>
                                            <li className="d-flex">Cleaning (2)</li>
                                            <li className="d-flex">Sanitizing (3)</li>
                                            <li className="d-flex">Supplies (2)</li>
                                        </ul>
                                    </aside>
                                    <aside className="widget widget-recent-post with-title">
                                        <h3 className="widget-title">Recent Posts</h3>
                                        <ul>
                                            <li>
                                                <a to={'/blog_details'}>
                                                    <img width="180" height="80" src="./images/blog/blog-11-1200x800.jpg" />
                                                </a>    
                                                <div className="post-detail">
                                                    <span className="post-date">October 10, 2020</span>
                                                    <a to={'/Blog_details'}>We wash shirts and pantsdrying with the best detergent</a>       
                                                </div>
                                            </li>
                                            <li>
                                                <a to={'/Blog_details'}>
                                                    <img width="145" height="80" src="./images/blog/blog-9-835x422.jpg" />
                                                </a>    
                                                <div className="post-detail">
                                                <span className="post-date">October 10, 2020</span>
                                                    <a to={'/Blog_details'}>The definitive guide to natural laundry detergents</a>
                                                </div>
                                            </li>
                                            <li>
                                                <a to={'/Blog_details'}>
                                                    <img width="145" height="80" src="./images/blog/blog-10-835x422.jpg" />
                                                </a>    
                                                <div className="post-detail">
                                                <span className="post-date">October 10, 2020</span>
                                                    <a to={'/Blog_details'}>10 reasons to hire professional dry cleaner services</a>
                                                </div>
                                            </li>
                                        </ul>
                                    </aside>
                                    <aside className="widget tagcloud-widget with-title">
                                        <h3 className="widget-title">Popular Tags</h3>
                                        <div className="tagcloud">
                                            <a href={'/Blog'} className="tag-cloud-link">Cleaning</a>
                                            <a href={'/Blog'} className="tag-cloud-link">Dry cleaning</a>
                                            <a href={'/Blog'} className="tag-cloud-link">Laundry</a>
                                            <a href={'/Blog'} className="tag-cloud-link">Steam Ironing</a>
                                            <a href={'/Blog'} className="tag-cloud-link">Washing</a>
                                        </div>
                                    </aside>
                                </div>
                            </div>
                        </div>{/* row end */}
                    </div>
                </div>
                
            <Footer/>
                        
            </div>
          )
      }
   }

export default Blog;