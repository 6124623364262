import React from 'react';
import Header2 from '../components/layout/Header2';

const TermsAndConditions = () => {
  return (
    <div className="site-main">
      <Header2 />
      <div className="ttm-page-title-row bg-img1 bg-theme-GreyColor" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
        <div className="container-xl">

          <h2>Terms and Conditions</h2>

          <h4>1. Liability</h4>
          <p>We are not liable for any items lost or damaged due to machinery malfunction. Please ensure that you do not include any items that require special handling, as we cannot take responsibility for damage to such items.</p>
          <p>Customers are responsible for ensuring that all items included in their laundry loads are suitable for standard machine washing and drying. This includes checking garment care labels and removing any items that require special handling, such as delicate fabrics or items that are not machine washable.</p>

          <h4>2. Allergies</h4>
          <p>Customers are responsible for ensuring that items included in their laundry loads do not belong to individuals with known allergies or sensitivities to detergents or chemicals used in our processes. We are not responsible for any allergic reactions that may occur due to the use of these substances.</p>

          <h4>3. Right to Reject Loads</h4>
          <p>We reserve the right to reject any load if we deem it necessary. This may include, but is not limited to, loads that contain hazardous materials, heavily soiled items, or items that require special care beyond our capabilities.</p>

          <h4>4. Order Cancellation</h4>
          <p>Once an order has been picked up, we cannot guarantee its cancellation if the load's treatment has already begun.</p>
          <p>A $10 cancellation fee will be charged for any cancellations made after pickup.</p>

          <h4>5. Order Completion Time</h4>
          <p>While we strive to complete all orders promptly, some orders may take up to 36 hours to complete. Customers will be notified of any delays.</p>

          <h4>6. Payment Processing</h4>
          <p>All transactions are processed using Stripe services. By using our services, you agree to the processing of your payments through Stripe.</p>

          <h4>7. Data Collection and Use</h4>
          <p>We collect and save basic user information, including names, addresses, emails, and phone numbers for communication and security purposes.</p>
          <p>By using our services, you agree to receive calls, texts, and emails related to your orders and account. This includes order updates, promotional offers, and other service-related communications.</p>

          <h4>8. Service Limitations</h4>
          <p>Service availability may be limited geographically. We do not accept industrial or oversized items.</p>

          <h4>9. Changes to Terms</h4>
          <p>We reserve the right to update or modify these terms and conditions. Customers will be notified of changes via email or on our website.</p>

          <h4>10. Acceptance of Terms</h4>
          <p>By using our services, you acknowledge that you have read, understood, and agreed to these terms and conditions.</p>

        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
