import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <footer className="footer widget-footer bg-theme-DarkColor bg-img3 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-3.png")', color: 'black' }}>
                <div className="first-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                                <div className="widget widget_text clearfix">
                                <h3 className="widget-title" style={{color: 'black'}}>Information</h3>
                                    <p>to take laundry off your to-do list? Priority laundry services is a local laundry
                                    pickup and delivery service that you can order directly from your smart phone.</p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                                <div className="widget widget-timing clearfix">
                                    <h3 className="widget-title" style={{color: 'black'}}>Contact info</h3>
                                    <p>Upper Darby, PA <br></br> </p>
                                    <h3><a href="tel:9085296733">(908) 529-6733</a></h3>
                                    <p><a href="#">info@laundrolift.com</a></p>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-2 widget-area">
                                <div className="widget widget_nav_menu clearfix">
                                    <h3 className="widget-title" style={{color: 'black'}}>Services</h3>
                                    <ul className="menu">
                                        <li><a href="flyer-poster-printing.html">Dry Cleaning</a></li>
                                        <li><a href="about-us.html">Dust Removal</a></li>
                                        <li><a href="#">Damage Repair</a></li>
                                        <li><a href="flyer-poster-printing.html">Sanitize clothes</a></li>
                                        <li><a href="#">Carpet Rinse</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 widget-area">
                                <div className="widget-area">
                                    
                                        <div className="widget widget_social clearfix">
                                        <div className="widgrt-box-main">
                                            <h3 className="widget-title" style={{color: 'black'}}>Sign up for newsletter</h3>  
                                            <form id="subscribe-form" className="subscribe-form">
                                                <div className="mailchimp-inputbox clearfix" id="subscribe-content"> 
                                                    <input type="email" name="EMAIL" id="txtemail" placeholder="Enter Your Email Address..." required="" />
                                                    <button className="submit ttm-btn btn-inline ttm-btn-size-md ttm-btn-color-skincolor" type="submit">
                                                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                                                    </button>
                                                </div>
                                            </form>
                                            <p className="comment-cookies">
                                                <input id="comment-cookies-consent" name="comment-cookies-consent" type="checkbox" value="yes" />
                                                <label htmlFor="comment-cookies-consent">I agree to all terms and policies</label>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="second-footer bg-img1">
                    <div className="container">  
                        <div className="row footer-box bg-theme-SkinColor border-radius-8 g-0">
                            <div className="col-xl-3 col-lg-3 col-md-2">
                                <a className="home-link pl-35" href={'/'} rel="home">
                                    <img width="130" height="35" id="logo-img" className="img-fluid" alt="logo" src="images/logo-img.svg" />
                                </a>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-12 widget-area res-991-mt-20 pl-lg-30 pt-lg-20">
                                <div className="d-block d-sm-flex justify-content-start align-items-center">
                                    <div className="align-self-center mr-25 res-575-mb-20">
                                        <h3 className="widget-title  style={{color: 'black'}}mb-0 text-theme-WhiteColor">Social networking</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-12 col-md-12 align-self-center res-575-mb-20 pt-xl-20 pl-xl-30">
                                <div className="social-icons circle">
                                    <ul className="list-inline">
                                        <li className="social-facebook">
                                            <a className="tooltip-top" target="_blank" href="https://www.facebook.com/themetechMount18">
                                                <i className="ti ti-facebook"></i>Facebook
                                            </a>
                                        </li>
                                        <li className="social-twitter">
                                            <a className="tooltip-top" target="_blank" href="https://twitter.com/themetechmount">
                                                <i className="ti ti-twitter"></i>Twitter
                                            </a>
                                        </li>
                                        <li className="social-printerest">
                                            <a className="tooltip-top" target="_blank" href="https://in.pinterest.com/themetechmount/_created/">
                                                <i className="ti ti-pinterest"></i>Printerest
                                            </a>
                                        </li>
                                        <li className="social-twitter">
                                            <a className="tooltip-top" target="_blank" href="https://www.linkedin.com/company/themetech-mount/">
                                                <i className="ti ti-linkedin"></i>Linkedin
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer-text text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <ul className="footer-nav-menu">
                                    <li><a href={'/'}>About Us</a></li>
                                    <li><a href={'/'}>Services</a></li>
                                    <li><a href={'/'}>Privacy Policy</a></li>
                                </ul>
                                <span className="copyright">Copyright © 2023 <a href={'/'}>Laundrolift </a> By Preyan Technosys All rights reserved.				
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}


