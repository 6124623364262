import React, { Component } from 'react';
import Slider from 'react-slick';
import Header from '../components/layout/Header';
import { Banner } from '../components/banner/Home1_banner';
import { Footer } from '../components/layout/Footer';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CountUp from 'react-countup';
import Header2 from '../components/layout/Header2';
import Home3_banner from '../components/banner/Home3_banner';
import ProgressBar from 'react-animated-progress-bar';


export class Home3 extends Component {
  constructor(props) {
    super(props);
 
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
    render() {
      var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 4,
        slidesToScroll: 1,
        rows: 1,

        responsive: [ {

            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },{
    
          breakpoint: 991,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
      },
      {
          breakpoint: 575,
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
      }]
      };
      var slick_slider_ltr = {
        infinite: true,
        arrows: false,
        dots: false,                   
        autoplay: true,
        centerMode : false,
        autoplaySpeed: 0,
        speed: 9000,
        cssEase: "linear",
        pauseOnHover: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        }

        var slick_slider_rtl = {
        infinite: true,
        arrows: false,
        dots: false,                   
        autoplay: true,
        centerMode : false,
        rtl: true,
        autoplaySpeed: 0,
        speed: 9000,
        cssEase: "linear",
        pauseOnHover: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        };
        
        return (       
          <div className="site-main">

            <Header2/>
            
            {/* Banner */} 
            <Home3_banner/>
            {/* Banner end */} 

            <section className="ttm-row clearfix" data-aos="fade-up" data-aos-duration="1000">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title title-style-center_text mb-0">
                                <div className="title-header">
                                    <h3>Services that we provide</h3>
                                    <h2 className="title">Discover all our  <span className='webkit-content'>[Services]</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="featured-icon-box icon-align-before-content style7">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                        <i className="flaticon-blanket"></i>
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3><a href={'/Alterations_repairs'}>Dry wet cleaning</a></h3>
                                    </div>
                                </div>
                                <div className="featured-desc">
                                    <p>Our process is tough on stains but gentle on your clothes and the environment</p>
                                </div>
                                <div className="ttm-iconbox">
                                    <a href={'/Alterations_repairs'} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="featured-icon-box icon-align-before-content style7">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                        <i className="flaticon-drying"></i>
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3><a href={'/Dry_cleaning'}>Gown preservation</a></h3>
                                    </div>
                                </div>
                                <div className="featured-desc">
                                    <p>We keep your dress looking as beautiful as the day you wore it for years to come.</p>
                                </div>
                                <div className="ttm-iconbox">
                                <a href={'/Dry_cleaning'} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="featured-icon-box icon-align-before-content style7">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                        <i className="flaticon-steam-iron"></i>
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3><a href="chronic-pain-relief.html">Wash and fold</a></h3>
                                    </div>
                                </div>
                                <div className="featured-desc">
                                    <p>We use our sophisticated soaps and expertise to make your laundry clean.</p>
                                </div>
                                <div className="ttm-iconbox">
                                    <a href={'/Dry_cleaning'} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="featured-icon-box icon-align-before-content style7">
                                <div className="featured-icon">
                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-xl ttm-icon_element-color-darkcolor">
                                        <i className="flaticon-laundry"></i>
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">
                                        <h3><a href={'/Commercial_laundry'}>Laundered shirts</a></h3>
                                    </div>
                                </div>
                                <div className="featured-desc">
                                    <p>With fresh starch, free button change and expertly handcrafted shirt press.</p>
                                </div>
                                <div className="ttm-iconbox">
                                <a href={'/Commercial_laundry'} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center mt-5 res-991-mt-30">
                            <p className="mb-0 mt-70 text-theme-DarkColor">Dont hesitate, contact us for help and services 
                            <strong><u><a  className="ttm-textcolor-darkgrey"> View More Services</a></u></strong></p>
                        </div>
                    </div>
                </div>
            </section>
            {/* features-section end */}

            <section className="ttm-row padding_zero-section bg-layer bg-layer-equal-height mb-md-0 z-index-0 clearfix">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-6 col-md-12">
                            {/* col-img-img-three */}
                            <div className="col-bg-img-four bg-theme-GreyColor ttm-bg ttm-col-bgimage-yes ttm-left-span spacing-2 z-index-2 pl-xl-15">
                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: 'url("images/bg-image/col-bgimage-4.png")' }}></div>
                                <div className="layer-content">
                                    <div className="section-title mb-lg-0">
                                        <div className="title-header pb-lg-0">
                                            <h3>What we’re offering</h3>
                                            <h2 className="title">You want it clean we dry <span className="webkit-content">[Clean]</span></h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="pl-lg-15">
                                            <div className="featured-icon-box icon-align-before-content style8">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-sm ttm-icon_element-color-darkcolor">
                                                        <i className="flaticon-read"></i>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3><a href="chronic-pain-relief.html">100% happiness guaranteed</a></h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>The fastest easiest to use most reliable professional dry cleaning.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="featured-icon-box icon-align-before-content style8">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-sm ttm-icon_element-color-darkcolor">
                                                        <i className="flaticon-read"></i>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3><a href="chronic-pain-relief.html">Committed for making things easy for you</a></h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Managing your laundry, dry cleansing with inside the need to be easy.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="featured-icon-box icon-align-before-content style8">
                                                <div className="featured-icon">
                                                    <div className="ttm-icon ttm-icon_element-onlytxt ttm-icon_element-size-sm ttm-icon_element-color-darkcolor">
                                                        <i className="flaticon-read"></i>
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">
                                                        <h3><a href="chronic-pain-relief.html">We protect our entire entire environment</a></h3>
                                                    </div>
                                                    <div className="featured-desc">
                                                        <p>Social and environmental sustainability is on the coronary heart of what we do</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* col-img-bg-img-one end */}
                        </div>
                        <div className="col-lg-6">
                            {/* col-img-img-three */}
                            <div className="col-bg-img-two bg-theme-GreyColor ttm-bg ttm-col-bgimage-yes ttm-right-span z-index-2">
                            <div className="ttm-col-wrapper-bg-layer ttm-bg-layer"  style={{ backgroundImage: 'url("images/bg-image/col-bgimage-3.png")' }}></div>
                                <div className="layer-content">
                                </div>
                                <img className="img-fluid col-bg-img-res" src="images/bg-image/col-bgimage-2.png" alt="bgimage" />
                            </div>{/* col-img-bg-img-one end */}
                        </div>
                    </div>{/* row end */}
                </div>
            </section>

            <section className="ttm-row faq-section-1 position-relative z-index-0 mt-lg-0 mt_100 clearfix" data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="center-bottom">
                <div className="container">
                    <div className="row g-0">
                        <div className="col-lg-9 col-md-12">
                            <div className="row g-0 bg-theme-SkinColor border-radius-8">
                                <div className="col-lg-4">
                                    <div className="ttm-fid inside style3 text-theme-WhiteColor">
                                        <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                            <h4 className="ttm-fid-inner">
                                                <CountUp start={0} end={12} duration={5} />
                                                <span>k</span>
                                            </h4>
                                            <h3 className="ttm-fid-title">Dry cleaning</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="ttm-fid inside style3 text-theme-WhiteColor">
                                        <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                            <h4 className="ttm-fid-inner">
                                                <CountUp start={0} end={2618} duration={5} />
                                                <span>+</span>
                                            </h4>
                                            <h3 className="ttm-fid-title">Happy clients</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="ttm-fid inside style3 text-theme-WhiteColor">
                                        <div className="ttm-fid-contents text-left">{/*ttm-fid-contents*/}
                                            <h4 className="ttm-fid-inner">
                                                <CountUp start={0} end={20} duration={5} />
                                                <span>k</span>
                                            </h4>
                                            <h3 className="ttm-fid-title">Satisfied client</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row team-section clearfix">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                            <Slider className="row slick_slider slick-dots-style1" {...slick_slider} slidesToShow={2}arrows={false}
                            dots={false}autoplay={false} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 2,slidesToScroll: 2 }},
                            {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] } >
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="featured-imagebox featured-imagebox-team style1">
                                        <div className="featured-thumbnail">
                                            <img src="./images/team/team-1-307x368.jpg" />
                                        </div>
                                        <div className="social-icon-wrapper">
                                            <ul className="social-icons list-inline">
                                                <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                                <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                                <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                                <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-content-box-inner">
                                                <p>manager</p>
                                                <div className="featured-title">
                                                    <h3><a href={'/Team_details.js'}>Juliana SIlva</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="featured-imagebox featured-imagebox-team style1">
                                        <div className="featured-thumbnail">
                                            <img src="./images/team/team-1-307x368.jpg" />
                                        </div>
                                        <div className="social-icon-wrapper">
                                            <ul className="social-icons list-inline">
                                                <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                                <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                                <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                                <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-content-box-inner">
                                                <p>Team lead</p>
                                                <div className="featured-title">
                                                    <h3><a href={'/Team_details.js'}>Joel Lou</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="featured-imagebox featured-imagebox-team style1">
                                        <div className="featured-thumbnail">
                                            <img src="./images/team/team-1-307x368.jpg" />
                                        </div>
                                        <div className="social-icon-wrapper">
                                            <ul className="social-icons list-inline">
                                                <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                                <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                                <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                                <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-content-box-inner">
                                                <p>Organizer</p>
                                                <div className="featured-title">
                                                    <h3><a href={'/Team_details.js'}>Reshta Wann</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                        <div className="col-lg-6 col-md-12" data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration="1000">
                            <div className="pl-40 pt-40 pl-lg-10 pt-lg-20">
                                <div className="section-title">
                                    <div className="title-header">
                                        <h3>Experts in their field</h3>
                                        <h2 className="title">Awesome team <span className="webkit-content">[Member]</span></h2>
                                    </div>
                                    <div className="title-desc">
                                        <p>We're a global team of laundry, technology and logistics experts with a
                                        mission to free people from doing laundry so they can spend more time doing
                                        what they want.</p>
                                    </div>
                                </div>
                                <a className="ttm-btn ttm-btn-size-md ttm-btn-shape-rounded ttm-btn-style-fill ttm-btn-color-skincolor mb-30" href="#">Join our team</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row padding_top_zero-section position-relative z-index-0 clearfix pb-lg-20" data-aos="fade-up" data-aos-duration="3000">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-12">
                            <div className="ttm-col-bgcolor-yes bg-theme-GreyColor text-theme-WhitColor col-bg-img-five ttm-col-bgimage-yes ttm-bg">
                                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" style={{ backgroundImage: 'url("images/bg-image/col-bgimage-5.png")' }}>
                                    <div className="ttm-col-wrapper-bg-layer-inner"></div>
                                </div>
                                <div className="layer-content text-center">
                                    <div className="featured-imagebox featured-imagebox-service style3">
                                        <div className="featured-image-box-wrapper">
                                            <div className="featured-thumbnail">
                                                <img width="41" height="41" src="./images/whats-up.png" />
                                            </div>
                                            <div className="featured-content">
                                                <div className="featured-title">
                                                    <h3><a href="tel:+123457899">+18 - 2222 - 3555</a></h3>
                                                </div>
                                                <div className="ttm-mail">
                                                    <h3>Email: <a className="text-theme-SkinColor" href="#">info@laundrolift.com</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                        <h2>Call us for all your laundry needs</h2>
                                        <p>Don’t hesitate, contact us for update.</p>
                                        <div className="ttm-iconbox">
                                            <a className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark"> Contact Us </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-12">
                            <Slider className="row slick_slider slick-dots-style1" {...slick_slider} slidesToShow={3}arrows={false}
                            dots={false}autoplay={false} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 2,slidesToScroll: 2 }},
                            {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] } >
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="featured-imagebox featured-imagebox-team style1 mt-lg-45">
                                        <div className="featured-thumbnail">
                                            <img src="./images/team/team-4-297x356.jpg" />
                                        </div>
                                        <div className="social-icon-wrapper">
                                            <ul className="social-icons list-inline">
                                                <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                                <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                                <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                                <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-content-box-inner">
                                                <p>manager</p>
                                                <div className="featured-title">
                                                    <h3><a href={'/Team_details.js'}>Juliana SIlva</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="featured-imagebox featured-imagebox-team style1 mt-lg-45">
                                        <div className="featured-thumbnail">
                                            <img src="./images/team/team-4-297x356.jpg" />
                                        </div>
                                        <div className="social-icon-wrapper">
                                            <ul className="social-icons list-inline">
                                                <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                                <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                                <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                                <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-content-box-inner">
                                                <p>Team lead</p>
                                                <div className="featured-title">
                                                    <h3><a href={'/Team_details.js'}>Joel Lou</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="featured-imagebox featured-imagebox-team style1 mt-lg-45">
                                        <div className="featured-thumbnail">
                                            <img src="./images/team/team-4-297x356.jpg" />
                                        </div>
                                        <div className="social-icon-wrapper">
                                            <ul className="social-icons list-inline">
                                                <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                                <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                                <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                                <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-content-box-inner">
                                                <p>Organizer</p>
                                                <div className="featured-title">
                                                    <h3><a href={'/Team_details.js'}>Reshta Wann</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <div className="featured-imagebox featured-imagebox-team style1 mt-lg-45">
                                        <div className="featured-thumbnail">
                                            <img src="./images/team/team-4-297x356.jpg" />
                                        </div>
                                        <div className="social-icon-wrapper">
                                            <ul className="social-icons list-inline">
                                                <li className="social-facebook"><a href="https://www.facebook.com/preyantechnosys19"><i className="ti ti-facebook"></i></a></li>
                                                <li className="social-twitter"><a href="https://twitter.com/PreyanTechnosys"><i className="ti ti-twitter"></i></a></li>
                                                <li className="social-linkedin"><a href="https://www.linkedin.com/in/preyan-technosys-pvt-ltd/"><i className="ti ti-linkedin"></i></a></li>
                                                <li className="social-pinterest"><a href="https://in.pinterest.com/preyan_technosys/"><i className="ti ti-pinterest"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="featured-content">
                                            <div className="featured-content-box-inner">
                                                <p>Organizer</p>
                                                <div className="featured-title">
                                                    <h3><a href={'/Team_details.js'}>Reshta Wann</a></h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row marque-section bg-theme-DarkColor clearfix">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 ttm-marquebox-wrapper style1">
                            <ul role="list" className="marquebox-list">
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Dry cleaning</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Alterations & repairs</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Commercial laundry</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Laundry services</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Alterations & repairs</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Laundry services</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text">Commercial laundry</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="padding_zero-section bg-theme-DarkColor pb-70 pb-lg-50 clearfix">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 ttm-marquebox-wrapper style2">
                            <ul role="list" className="marquebox-list">
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text-1">Dry cleaning</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text-1">Alterations & repairs</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text-1">Commercial laundry</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text-1">Laundry services</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text-1">Alterations & repairs</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text-1">Laundry services</div>
                                </li>
                                <li className="marquebox-item">
                                    <div className="ttm-marque-text-1">Commercial laundry</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ttm-row blog-section clearfix">
                <div className="container">
                    <div className="row" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000">
                        <div className="col-md-12">
                            <div className="section-title title-style-center_text mb-0">
                                <div className="title-header">
                                    <h3>From the blog</h3>
                                    <h2 className="title">Our latest  <span className='webkit-content'>[blog]</span></h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" data-aos="fade-up" data-aos-anchor-placement="top-bottom" data-aos-duration="2000">
                        <div className="col-lg-6">
                            <Slider className="row slick_slider" {...slick_slider} slidesToShow={1}arrows={false}
                            dots={false}autoplay={false} responsive= { [{ breakpoint: 1024, settings: {slidesToShow: 2,slidesToScroll: 2 }},
                            {breakpoint: 575, settings: { slidesToShow: 1, slidesToScroll: 1 }}] } >
                                <div className="col-md-12">
                                    <div className="featured-imagebox featured-imagebox-blog style2">
                                        {/* featured-thumbnail */}
                                        <div className="featured-thumbnail">
                                            <img src="./images/blog/blog-7-650x433.jpg" />
                                        </div>{/* featured-thumbnail end */}
                                        <div className="featured-content">
                                            <div className="post-meta">
                                                <span className="ttm-meta-line byline"><a href={'/Blog'}>Cleaning, supplies</a></span>
                                                <span className="ttm-meta-line category-link">02 Dec 2022</span>
                                            </div>
                                            <div className="featured-title">
                                                <h3><a href={'/Blog_details'}>The definitive guide to natural laundry detergents</a></h3>
                                            </div>
                                        </div>
                                        <div className="ttm-iconbox">
                                            <a href={'/Blog_details'}></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-imagebox featured-imagebox-blog style2">
                                        {/* featured-thumbnail */}
                                        <div className="featured-thumbnail">
                                            <img src="./images/blog/blog-7-650x433.jpg" />
                                        </div>{/* featured-thumbnail end */}
                                        <div className="featured-content">
                                            <div className="post-meta">
                                                <span className="ttm-meta-line byline"><a href={'/Blog'}>Sanitizing</a></span>
                                                <span className="ttm-meta-line category-link">09 Dec 2022</span>
                                            </div>
                                            <div className="featured-title">
                                                <h3><a href={'/Blog_details'}>10 reasons to hire professional dry cleaner services</a></h3>
                                            </div>
                                        </div>
                                        <div className="ttm-iconbox">
                                            <a href={'/Blog_details'}></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="featured-imagebox featured-imagebox-blog style2">
                                        {/* featured-thumbnail */}
                                        <div className="featured-thumbnail">
                                            <img src="./images/blog/blog-7-650x433.jpg" />
                                        </div>{/* featured-thumbnail end */}
                                        <div className="featured-content">
                                            <div className="post-meta">
                                                <span className="ttm-meta-line byline"><a href={'/Blog'}>Sanitizing</a></span>
                                                <span className="ttm-meta-line category-link">03 Dec 2022</span>
                                            </div>
                                            <div className="featured-title">
                                                <h3><a href={'/Blog_details'}>We wash shirts and pantsdrying with the best detergent</a></h3>
                                            </div>
                                        </div>
                                        <div className="ttm-iconbox">
                                        <a href={'/Blog_details'}></a>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                        <div className="col-lg-6">
                            <div className="pl-45 pl-lg-5">
                                <div className="featured-imagebox featured-imagebox-blog style2">
                                    <div className="featured-content pt-0">
                                        <div className="post-meta">
                                            <span className="ttm-meta-line byline"><a href={'/Blog'}>Sanitizing</a></span>
                                            <span className="ttm-meta-line category-link">03 Dec 2022</span>
                                        </div>
                                        <div className="featured-title">
                                            <h3><a href={'/Blog_details'}>We wash shirts and pantsdrying with the best detergent</a></h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Blog_details'}></a>
                                    </div>
                                </div>
                                <div className="separator">
                                    <div className="sep-line mt-4 mb-4"></div>
                                </div>
                                <div className="featured-imagebox featured-imagebox-blog style2">
                                    <div className="featured-content pt-0">
                                        <div className="post-meta">
                                            <span className="ttm-meta-line byline"><a href={'/Blog'}>Cleaning, supplies</a></span>
                                            <span className="ttm-meta-line category-link">02 Dec 2022</span>
                                        </div>
                                        <div className="featured-title">
                                            <h3><a href={'/Blog_details'}>The definitive guide to natural laundry detergents</a></h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Blog_details'}></a>
                                    </div>
                                </div>
                                <div className="separator">
                                    <div className="sep-line mt-4 mb-4"></div>
                                </div>
                                <div className="featured-imagebox featured-imagebox-blog style2">
                                    <div className="featured-content pt-0">
                                        <div className="post-meta">
                                            <span className="ttm-meta-line byline"><a href={'/Blog'}>Sanitizing</a></span>
                                            <span className="ttm-meta-line category-link">09 Nov 2022</span>
                                        </div>
                                        <div className="featured-title">
                                            <h3><a href={'/Blog_details'}>10 reasons to hire professional dry cleaner services</a></h3>
                                        </div>
                                    </div>
                                    <div className="ttm-iconbox">
                                        <a href={'/Blog_details'}></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                     
            <section className="ttm-row services-section-2 bg-theme-GreyColor bg-img1 clearfix" style={{ backgroundImage: 'url("images/bg-image/row-bgimage-1.png")' }}>
                <div className="container">
                    {/* row */}
                    <div className="row" data-aos="fade-up" data-aos-duration="1000">
                        <div className="col-xl-4 col-md-6">
                            {/* section title */}
                            <div className="section-title style1 pt-lg-50">
                                <div className="title-header">
                                    <h3>Cleaning Excellence!</h3>
                                    <h2 className="title">Offering expanded disinfection services</h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                        <div className="col-xl-2 col-md-6">
                            <div className="ttm_single_image-wrapper">
                                <img src="./images/single-img-189x185.png" />
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 pt-50 pl-30 pt-lg-40 pb-lg-30">
                            <p>Providing the best possible approach to garment for care and superior service by using 
                               the latest dry cleaning and drying technology.</p>
                        </div>
                        <div className="col-xl-2 col-md-6">
                            <div className="text-center">
                                <a href={'/About_us'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-dark mt-65 mt-lg-20"> Explore offers </a>
                            </div>
                        </div>
                    </div>{/* row end */}
                </div>
            </section>                    


            <Footer/>
                        
         </div>
       )
   }
}


export default Home3;
